import { useEffect } from 'react'

export const useClearToast = (
  isToastOpen: boolean,
  setIsToastOpen: (val: boolean) => void
) => {
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined

    if (isToastOpen) {
      timeout = setTimeout(() => {
        setIsToastOpen(false)
      }, 6000)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [isToastOpen])
}
