import { LocaleContextValue, LocaleString } from '@concerns/i18n/types'

export const LocaleStringDisplayNames = {
  [LocaleString.German]: 'Deutsch (Deutschland)',
  [LocaleString.English]: 'English (United States)',
  [LocaleString.French]: 'Français (France)',
  [LocaleString.Japanese]: '日本語 (日本)',
  [LocaleString.Korean]: '한국어 (한국)'
}

export const SupportedLocales: Array<LocaleString> = [
  LocaleString.English,
  LocaleString.Japanese,
  LocaleString.German,
  LocaleString.Korean,
  LocaleString.French
]

export const SupportedBaseLocales: Array<string> = SupportedLocales.map(
  supportedLocale => supportedLocale.split('-')[0]
)

export const DefaultApplicationLocale = LocaleString.English

export const DefaultLocaleContextValue: LocaleContextValue = {
  activeLocale: LocaleString.English,
  localeMessages: undefined,
  changeApplicationLocale: () => {}
}

export const LocalesRequiringSpecialFonts: Partial<
  Record<LocaleString, { fontId: string }>
> = {
  [LocaleString.Japanese]: {
    //  'SourceHanSansJP-Regular'
    fontId: 'TkD-23597-7167a1122341c51b47601c1a20d1c1a5c3c0ec0e'
  },
  [LocaleString.Korean]: {
    // 'SourceHanSansK-Regular'
    fontId: 'TkD-41312-3ac9a062e7f00f0ad5f9888c2367ae6a3b8951aa'
  }
}
