import React from 'react'
import { useAuth } from '@hooks/useAuth'
import { useSync } from '@hooks/useSync'

import { PresenceUserDataFields } from '@store/slices/syncSlice'
import { User } from '@store/graphql/__generated__/schema'
import { ExtendedTuple } from 'types/helper'
import Avatar from '@components/avatar/Avatar'
import AvatarGroup from '@components/avatar/AvatarGroup'
import { ActionButton } from 'ui'
import styles from '@styles/components/ConnectedAvatars.module.scss'
import { useTranslations } from 'use-intl'

const rgbColor = (r: number, g: number, b: number) => `rgb(${r}, ${g}, ${b})`

const ConnectedAvatars = () => {
  const t = useTranslations()
  const { localUser } = useAuth()
  const {
    localUserFollowingRemoteUser,
    setLocalUserFollowingRemoteUser,
    filteredRemoteUsers
  } = useSync()

  function handleAvatarClick(user: User | PresenceUserDataFields | null) {
    if (localUser?.uuid === user?.uuid) return

    const isFollowingUser = localUserFollowingRemoteUser?.uuid === user?.uuid

    if (isFollowingUser) return setLocalUserFollowingRemoteUser(null)
    setLocalUserFollowingRemoteUser(user as PresenceUserDataFields)
  }

  if (!filteredRemoteUsers.length) return null
  return (
    <div className={styles['container']}>
      <AvatarGroup<User | PresenceUserDataFields>
        avatars={filteredRemoteUsers.map(({ user }) => ({
          key: user.uuid,
          src: user.adobe_user_avatar_url,
          name: user.adobe_user_display_name,
          tooltip:
            localUserFollowingRemoteUser?.uuid === user.uuid
              ? t('studio:collaboration:stopFollowingUser', {
                  username: user.adobe_user_display_name
                })
              : t('studio:collaboration:startFollowingUser', {
                  username: user.adobe_user_display_name
                }),
          data: user,
          borderColor:
            user.preferences.color && rgbColor(...user.preferences.color)
        }))}
        maxVisible={5}
        size="S"
        onAvatarClick={handleAvatarClick}
        hiddenAvatarsDialogContent={(hiddenAvatars, closeDialog) => (
          <>
            <h3 className={styles['header']}>
              {t('studio:collaboration:participants')}
            </h3>
            <div className="flex flex-col gap-xs">
              {hiddenAvatars.map(avatar => {
                const isFollowingUser =
                  localUserFollowingRemoteUser?.uuid === avatar.data.uuid
                return (
                  <div className={styles['avatar-container']}>
                    <div className={styles['avatar-container__avatar']}>
                      <Avatar
                        {...avatar}
                        src={avatar.src}
                        alt={avatar.name}
                        size="S"
                        withBorder
                        borderColor={
                          avatar?.data?.preferences?.color
                            ? rgbColor(
                                ...(avatar.data.preferences
                                  .color as ExtendedTuple<number, 3>)
                              )
                            : undefined
                        }
                      />

                      <p className={styles['avatar-container__avatar-name']}>
                        {/* can't pass title attribute to Text component  */}
                        <span title={avatar.name}>{avatar.name}</span>
                      </p>
                    </div>
                    {isFollowingUser ? (
                      <ActionButton
                        onClick={() => {
                          closeDialog()
                          setLocalUserFollowingRemoteUser(null)
                        }}>
                        {t('studio:collaboration:stopFollowing')}
                      </ActionButton>
                    ) : (
                      <ActionButton
                        onClick={() => {
                          closeDialog()
                          setLocalUserFollowingRemoteUser(
                            avatar.data as PresenceUserDataFields
                          )
                        }}>
                        {t('studio:collaboration:follow')}
                      </ActionButton>
                    )}
                  </div>
                )
              })}
            </div>
          </>
        )}
      />
    </div>
  )
}

export default ConnectedAvatars
