import { call, put, select, take, takeEvery } from 'redux-saga/effects'
import { userLoaded } from '@store/slices/authSlice'
import {
  Color,
  ColorKey,
  detectStudioEnvironment,
  loadProject,
  projectLoaded,
  resetState,
  saveProject,
  setColorPreference,
  setEnvironment,
  setIsPublic,
  setOpenedPanel,
  setShowFireflyPopover,
  setSizePreference,
  StudioPanel,
  toggleProjectPublicStatus
} from '@store/slices/projectSlice'
import { PayloadType, RootState } from '@store/store'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  EngineState,
  setEngineState,
  setPropertyState
} from '@store/slices/sceneSlice'
import ApolloClient from '@store/graphql/client'
import {
  UpdateProjectDocument,
  UpdateProjectMutationResult
} from '@store/graphql/__generated__/schema'
import {
  handleDocumentSnapshotUpdate,
  isDocumentUpdatable
} from '../document/documentSaga'
import { LocalStorageKey } from 'constants/localStorage'
import {
  SceneToImageStudioEnvironment,
  StudioEnvironmentQueryParam
} from '@constants/appConstants'
import Context from '../context'

function* handleLoadProject({
  payload: { requireAuth }
}: PayloadAction<PayloadType<typeof loadProject>>) {
  if (requireAuth) {
    yield call(ensureUserLoaded)
  }

  yield put(projectLoaded())
}

function* ensureUserLoaded() {
  // Sometimes, the user loads before loadProject() gets called
  const { localUser } = yield select((state: RootState) => state.auth)
  if (!localUser) yield take(userLoaded.type)
}

function* handleSetEngineState({
  payload
}: PayloadAction<PayloadType<typeof setEngineState>>) {
  if (payload !== 'UNMOUNTED') return

  yield put(resetState())
}

function* handleSaveProject() {
  const { auth, project }: RootState = yield select((state: RootState) => state)

  const { localUser } = auth
  const { isFeatured, ownerUserUuid } = project

  if (isDocumentUpdatable(localUser, isFeatured, ownerUserUuid)) {
    yield handleDocumentSnapshotUpdate()
  }
}

function* handleSetColorPreference({
  payload
}: PayloadAction<PayloadType<typeof setColorPreference>>) {
  if (payload.saveToLocalStorage) {
    yield localStorage.setItem(
      LocalStorageKey.appPreferenceColor,
      payload.color
    )
  }
}

function* handleSetSizePreference({
  payload
}: PayloadAction<PayloadType<typeof setSizePreference>>) {
  yield localStorage.setItem(LocalStorageKey.appPreferenceSize, payload)
}

function* handleToggleProjectPublicStatus() {
  const { isPublic, projectUuid }: RootState['project'] = yield select(
    (state: RootState) => state.project
  )
  if (!projectUuid) return

  const newPublicStatus = !isPublic

  yield put(setIsPublic(newPublicStatus))
  yield call(setProjectPublicStatus, projectUuid, newPublicStatus)
}

async function setProjectPublicStatus(uuid: string, isPublic: boolean) {
  return await ApolloClient.mutate<UpdateProjectMutationResult['data']>({
    mutation: UpdateProjectDocument,
    variables: {
      uuid,
      public: isPublic
    }
  })
}

function* handleSetDefaultEnvironment() {
  yield put(setOpenedPanel(StudioPanel.Properties))
  yield call(setThemePreferenceDefault)
}

function* handleSetSceneToImageEnvironment() {
  yield put(setShowFireflyPopover(true))
  yield put(setOpenedPanel(null))
  const projectUuid: string = yield select(
    (state: RootState) => state.project.projectUuid
  )

  yield call(setThemePreferenceSceneToImage)

  window.history.replaceState(
    {},
    '',
    `${process.env.NEXT_PUBLIC_CLIENT_WEB_HOST}/studio/${projectUuid}?${StudioEnvironmentQueryParam}=${SceneToImageStudioEnvironment}`
  )

  const engineState: EngineState = yield select(
    (state: RootState) => state.scene.engineState
  )
  if (engineState !== 'INITIALIZED_AND_DOCUMENT_LOADED') {
    yield take(
      (action: any) =>
        action.type === setEngineState.type &&
        action.payload === 'INITIALIZED_AND_DOCUMENT_LOADED'
    )
  }

  yield put(setPropertyState({ key: 'frameEnabled', value: false }))
}

function* handleSetEnvironment({
  payload
}: PayloadAction<PayloadType<typeof setEnvironment>>) {
  if (payload === 'default') yield handleSetDefaultEnvironment()
  else if (payload === 'scene-to-image')
    yield handleSetSceneToImageEnvironment()
}

function* setThemePreferenceSceneToImage() {
  const colorPreference = new URLSearchParams(window.location.search).get(
    'theme'
  ) as RootState['project']['colorPreference']
  if (colorPreference) {
    yield put(setColorPreference({ color: colorPreference }))
  } else {
    yield put(
      setColorPreference({
        color: getThemeSystemPreference()
      })
    )
  }
}

function* setThemePreferenceDefault() {
  const localStoragePreference = localStorage.getItem(
    LocalStorageKey.appPreferenceColor
  )
  const hasPreference = Object.entries(Color).some(
    ([_, val]) => val === localStoragePreference
  )
  if (hasPreference) {
    yield put(setColorPreference({ color: localStoragePreference as ColorKey }))
  } else {
    const systemPreference: ColorKey = yield call(getThemeSystemPreference)
    yield put(setColorPreference({ color: systemPreference }))
  }
}

export function getThemeSystemPreference() {
  const { matches: prefersDark } = window.matchMedia(
    '(prefers-color-scheme: dark)'
  )

  return prefersDark ? 'dark' : 'light'
}

function* handleDetectStudioEnvironment() {
  const studioEnvironmentParam = new URLSearchParams(
    window.location.search
  ).get(StudioEnvironmentQueryParam)

  const allowMultipleStudioEnvironments = Context.LDClient?.variation(
    'base-pf-allow-multiple-studio-environments'
  )

  if (
    studioEnvironmentParam === SceneToImageStudioEnvironment &&
    allowMultipleStudioEnvironments
  ) {
    yield put(setEnvironment('scene-to-image'))
  } else {
    yield put(setEnvironment('default'))
  }
}

export default function* projectSaga() {
  yield takeEvery(loadProject.type, handleLoadProject)
  yield takeEvery(setEngineState.type, handleSetEngineState)
  yield takeEvery(saveProject.type, handleSaveProject)
  yield takeEvery(setColorPreference.type, handleSetColorPreference)
  yield takeEvery(setSizePreference.type, handleSetSizePreference)
  yield takeEvery(
    toggleProjectPublicStatus.type,
    handleToggleProjectPublicStatus
  ),
    yield takeEvery(setEnvironment.type, handleSetEnvironment)
  yield takeEvery(detectStudioEnvironment.type, handleDetectStudioEnvironment)
}
