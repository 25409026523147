import { createContext, PropsWithChildren, useContext, useState } from 'react'

type FavIconContextType = {
  setFavIconUrl: (url: string) => void
}

const FavIconContext = createContext<FavIconContextType | null>(null)

export function FavIconProvider({
  children,
  setFavIconUrl
}: PropsWithChildren<FavIconContextType>) {
  return (
    <FavIconContext.Provider value={{ setFavIconUrl }}>
      {children}
    </FavIconContext.Provider>
  )
}

export function useFavIcon() {
  const context = useContext(FavIconContext) as FavIconContextType

  if (typeof context === 'undefined')
    throw new Error('useFavIcon must be used within FavIconProvider')

  return context
}
