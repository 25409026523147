import {
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  ActionButton,
  ActionGroup,
  Button,
  ButtonGroup,
  Dialog,
  DialogBase,
  Overlay,
  Radio,
  RadioGroup,
  Textfield,
  Toast
} from 'ui'
import styles from '@styles/components/ReportForm.module.scss'
import {
  useProjectCardActions,
  useProjectCardState
} from '@hooks/useProjectCard'
import { useAuth } from '@hooks/useAuth'
import { LeiaProductId } from '@constants/appConstants'
import { captureException } from '@sentry/nextjs'
import { useTranslations } from 'use-intl'

type ReportCategoryMap = {
  Suspicious: {
    Spam: string
    Fraud: string
    Malicious: string
  }
  Mature: {
    Nudity: string
    Violence: string
    Profanity: string
  }
  Harmful: {
    Harassment: string
    Hate: string
    Illegal: string
    Extremist: string
    Child: string
  }
  Selfharm: string
  Infringement: {
    Copyright: string
    Trademark: string
    Private: string
  }
  Misleading: {
    Impersonation: string
    Misinformation: string
  }
  Other: string
}

type ReportCategory = keyof ReportCategoryMap

type Values2ndDepth<
  T extends object,
  K extends keyof T = keyof T
> = K extends keyof T ? (T[K] extends object ? keyof T[K] : never) : never

type ReportSubcategory = Values2ndDepth<ReportCategoryMap>

const reportCategoryMap: ReportCategoryMap = {
  Suspicious: {
    Spam: 'spam',
    Fraud: 'fraud_scam',
    Malicious: 'fraud_scam'
  },
  Mature: {
    Nudity: 'adult_nudity_sexual_activity',
    Violence: 'adult_violence_or_gore',
    Profanity: 'adult_profanity'
  },
  Harmful: {
    Harassment: 'harmful_harassment',
    Hate: 'harmful_hate_speech',
    Illegal: 'harmful_illegal_goods_services',
    Extremist: 'harmful_promotes_terrorism',
    Child: 'harmful_behavior_with_minors'
  },
  Selfharm: 'self_harm',
  Infringement: {
    Copyright: 'infringement_copyright',
    Trademark: 'infringement_trademark',
    Private: 'infringement_privacy'
  },
  Misleading: {
    Impersonation: 'impersonation_and_fake_accounts',
    Misinformation: 'misinformation'
  },
  Other: 'other'
}

const categories: ReportCategory[] = [
  'Suspicious',
  'Mature',
  'Harmful',
  'Selfharm',
  'Infringement',
  'Misleading',
  'Other'
] as const

function hasSubcategories(category: ReportCategory) {
  return typeof reportCategoryMap[category] === 'object'
}

function getSubcategories(category: ReportCategory) {
  if (hasSubcategories(category)) {
    return reportCategoryMap[category]
  }

  return undefined
}

function getReportCategoryValue(
  category: ReportCategory,
  subcategory: ReportSubcategory | null
) {
  if (hasSubcategories(category)) {
    return reportCategoryMap[category][subcategory]
  }

  return reportCategoryMap[category]
}

export const ReportForm: FC = () => {
  const t = useTranslations()
  const { localUser, promptLogin } = useAuth()

  const [showReportToast, setShowReportToast] = useState<boolean>(false)
  const [otherCategoryInput, setOtherCategoryInput] = useState<string>('')
  const reportFormVisible = useProjectCardState('reportFormVisible')

  const [
    showOtherCategoryDescriptionError,
    setShowOtherCategoryDescriptionError
  ] = useState<boolean>(false)

  const projectUuid = useProjectCardState('projectUuidToReport')
  const ownerAdobeUserId = useProjectCardState('ownerAdobeUserId')

  const {
    closeReportForm,
    setProjectUuidToReport,
    setOwnerAdobeUserIdToReport
  } = useProjectCardActions()
  const [selectedCategory, setSelectedCategory] =
    useState<ReportCategory>('Suspicious')

  const [selectedSubcategory, setSelectedSubcategory] =
    useState<ReportSubcategory | null>(null)

  const showOtherTextField = selectedCategory === 'Other'

  // Clear text field validation error if category is changed from Other
  if (selectedCategory !== 'Other' && showOtherCategoryDescriptionError) {
    setShowOtherCategoryDescriptionError(false)
  }

  const handleOnRadioChange = (e: any) => {
    const reportCategory = e.target?.selected as ReportCategory
    const subcategories = getSubcategories(reportCategory)

    if (typeof subcategories === 'undefined') {
      setSelectedSubcategory(null)
    } else {
      setSelectedSubcategory(
        Object.entries(subcategories)[0][0] as ReportSubcategory
      )
    }

    setSelectedCategory(reportCategory)
  }

  const handleOnTextfieldInput = (e: FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value

    // Clear the validation error if it was previously displayed
    if (newValue.length > 0) {
      setShowOtherCategoryDescriptionError(false)
    }

    setOtherCategoryInput(newValue)
  }

  const handleOnClose = () => {
    resetState()
  }

  const handleSubmitError = (err: Error) => {
    captureException(err)
    console.error(err)
    closeReportForm()
  }

  const validateForm = () => {
    // Check if min length constraint is satisfied on text field
    // when category = Other
    if (selectedCategory === 'Other' && otherCategoryInput.length === 0) {
      setShowOtherCategoryDescriptionError(true)
      return false
    }

    return true
  }

  // This is necessary because Spectrum will NOT unmount this form once
  // it is initially mounted, so the state will be persisted
  const resetState = () => {
    setSelectedCategory('Suspicious')
    setSelectedSubcategory(null)
    setOtherCategoryInput('')
    setProjectUuidToReport(null)
    setOwnerAdobeUserIdToReport(null)
    setShowOtherCategoryDescriptionError(false)
  }

  const submitReport = async () => {
    try {
      if (!localUser || !validateForm()) return

      if (
        selectedSubcategory === 'Copyright' ||
        selectedSubcategory === 'Trademark'
      ) {
        window.open(t('link:infringingOnRights'), '_blank')
      }

      const reportCategoryValue = getReportCategoryValue(
        selectedCategory,
        selectedSubcategory
      )

      setShowReportToast(true)
      setTimeout(() => setShowReportToast(false), 2500)

      fetch(process.env.NEXT_PUBLIC_LEIA_REPORT_ABUSE_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID!
        },
        body: JSON.stringify({
          productId: LeiaProductId,
          category: reportCategoryValue,
          additionalInfo:
            selectedCategory === 'Other'
              ? otherCategoryInput
              : selectedSubcategory ?? selectedCategory,
          reporter: {
            name: localUser?.adobeUserDisplayName,
            email: localUser?.adobeUserEmail
          },
          reportDate: new Date().toISOString(),
          content: [
            {
              serviceName: 'Other',
              URL: `${process.env.NEXT_PUBLIC_SERVICE_CORE_STORAGE}/studio/${projectUuid}`,
              contentCreator: ownerAdobeUserId,
              contentCreatorType: 'ims'
            }
          ]
        })
      }).catch(err => handleSubmitError(err))

      resetState()
      closeReportForm()
    } catch (err) {
      handleSubmitError(err as Error)
    }
  }

  return (
    <>
      <DialogBase
        underlay
        open={reportFormVisible}
        close={handleOnClose}
        className={styles['container']}>
        <Dialog size="s" noDivider className={styles['content']}>
          <h2 slot="heading">{t('dashboard:reportProject')}</h2>
          <p>
            {t.rich('dashboard:reportProject:communityGuidelines:text', {
              hyperlinked: chunks => (
                <a
                  className={styles['community_guidelines']}
                  href={t('dashboard:reportProject:communityGuidelines:url')}
                  target="_blank"
                  rel="noopener noreferrer">
                  {chunks}
                </a>
              )
            })}
          </p>
          <RadioGroup
            vertical
            selected={selectedCategory}
            change={handleOnRadioChange}>
            {categories.map(category => (
              <Category
                key={category}
                category={category}
                isSelected={selectedCategory === category}
                selectedSubcategory={selectedSubcategory}
                setSelectedSubcategory={setSelectedSubcategory}
              />
            ))}
          </RadioGroup>
          <ButtonGroup slot="button">
            <Button
              variant="secondary"
              treatment="outline"
              onClick={() => closeReportForm()}>
              {t('dashboard:reportProject:cancel')}
            </Button>
            <Button variant="primary" treatment="fill" onClick={submitReport}>
              {t('dashboard:reportProject:submit')}
            </Button>
          </ButtonGroup>
          {showOtherTextField && (
            <Textfield
              className={styles['textfield']}
              placeholder={t('dashboard:reportProject:additionalDetails')}
              onInput={handleOnTextfieldInput}
              value={otherCategoryInput}
              maxlength={1000}
            />
          )}
          {showOtherCategoryDescriptionError && (
            <p className={styles['error']}>
              {t('dashboard:reportProject:descriptionError')}
            </p>
          )}
          <p>
            {t.rich('dashboard:reportProject:illegalContent:text', {
              hyperlinked: chunks => (
                <a
                  href={t('dashboard:reportProject:illegalContent:url')}
                  target="_blank"
                  style={{ textDecoration: 'underline' }}>
                  {chunks}
                </a>
              )
            })}
          </p>
        </Dialog>
      </DialogBase>
      <Overlay open={showReportToast} type="manual">
        <Toast variant="positive" className={styles['toast']}>
          {t('dashboard:reportProject:confirmation')}
        </Toast>
      </Overlay>
    </>
  )
}

type CategoryProps = {
  category: ReportCategory
  isSelected: boolean
  selectedSubcategory: string | null
  setSelectedSubcategory: Dispatch<SetStateAction<ReportSubcategory | null>>
}

const Category: FC<CategoryProps> = ({
  category,
  isSelected,
  selectedSubcategory,
  setSelectedSubcategory
}) => {
  const t = useTranslations()
  const subcategories = useRef(getSubcategories(category)).current

  return (
    <>
      <Radio className={styles['radio-btn']} value={category}>
        {t(`dashboard:reportProject:${category}`)}
      </Radio>
      {isSelected && subcategories && (
        <Subcategories
          subcategories={subcategories}
          selectedSubcategory={selectedSubcategory}
          setSelectedSubcategory={setSelectedSubcategory}
        />
      )}
    </>
  )
}

type SubcategoriesProps = Pick<
  CategoryProps,
  'selectedSubcategory' | 'setSelectedSubcategory'
> & {
  subcategories: any
}

const Subcategories: FC<SubcategoriesProps> = ({
  subcategories,
  selectedSubcategory,
  setSelectedSubcategory
}) => {
  const t = useTranslations()
  const [selectFirst, setSelectFirst] = useState<boolean>(false)
  const visible = useProjectCardState('reportFormVisible')

  const handleOnClick = (value: string) => {
    setSelectedSubcategory(value as ReportSubcategory)
    setSelectFirst(false)
  }

  useEffect(() => {
    if (visible) {
      setSelectFirst(true)
      setSelectedSubcategory(Object.keys(subcategories)[0] as ReportSubcategory)
    }
  }, [visible])

  return (
    <ActionGroup>
      {Object.keys(subcategories).map((k, index) => (
        <ActionButton
          key={k}
          value={k}
          selected={selectedSubcategory === k || (index === 0 && selectFirst)}
          onClick={() => handleOnClick(k)}>
          {t(`dashboard:reportProject:${k}`)}
        </ActionButton>
      ))}
    </ActionGroup>
  )
}
