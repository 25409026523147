import { useProjectActions, useProjectState } from '@hooks/useProject'
import { PropsWithChildren, useEffect } from 'react'

export type BrowserCompatibilityStatus =
  | 'Compatible'
  | 'UnsupportedBrowser'
  | 'WebGlUnsupported'
  | 'IncorrectAngleBackend'

function checkBrowserCompatibility(): BrowserCompatibilityStatus {
  if (typeof navigator === 'undefined') return 'UnsupportedBrowser'

  const isMac =
    navigator.platform.toUpperCase().includes('MAC') ||
    navigator.userAgent.includes('Mac OS')

  if (!isMac) return 'Compatible'

  const isSafari = /^((?!chrome).)*safari/i.test(navigator.userAgent)
  if (isSafari) {
    // Mac running Safari: run everywhere
    return 'Compatible'
  }

  // Mac not running safari:

  const canvas = document.createElement('canvas')
  const gl = canvas.getContext('webgl2')
  if (!gl) {
    canvas.remove()
    return 'WebGlUnsupported'
  }

  // If it's an ANGLE backend, it should be metal based
  const debugInfo = gl.getExtension('WEBGL_debug_renderer_info')
  if (!debugInfo) {
    canvas.remove()
    return 'UnsupportedBrowser'
  }

  const debugRenderer = gl
    .getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
    .toLowerCase()
  console.log('debugRenderer=' + debugRenderer)
  canvas.remove()

  const isAngle = debugRenderer.includes('angle')
  if (!isAngle) {
    return 'UnsupportedBrowser'
  }

  const isMetal = debugRenderer.includes('metal')
  if (!isMetal) {
    return 'IncorrectAngleBackend'
  }

  return 'Compatible'
}

export function BrowserCompatibilityChecker({ children }: PropsWithChildren) {
  const { setBrowserCompatibility } = useProjectActions()
  const browserCompatibility = useProjectState('browserCompatibility')

  // Put in useEffect to ensure window is defined
  useEffect(() => {
    const compatibility = checkBrowserCompatibility()
    setBrowserCompatibility(compatibility)
  }, [])

  if (!browserCompatibility) return null
  return <>{children}</>
}
