import { useContext, useEffect, useState } from 'react'
import { DialogBase, ProgressCircle } from 'ui'
import styles from '@styles/components/UnifiedPaywallDialog.module.scss'
import { useAccessProfileState } from '@hooks/useAccessProfile'
import { useFirefly } from '@hooks/useFirefly'
import { useAuthState } from '@hooks/useAuth'
import { LocaleContext } from '@concerns/i18n/components/LocaleProvider'
import AdobeIMS from '@services/auth/IMS'

function UnifiedPaywallDialog() {
  const authStatus = useAuthState('status')
  const fireflyFulfillableItemCreditModel = useAccessProfileState(
    'fireflyFulfillableItemCreditModel'
  )

  const { activeLocale } = useContext(LocaleContext)

  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true)
  const [paywallURL, setPaywallURL] = useState<string | null>(null)

  const { showPaywall, setShowPaywall, setShowPaywallToast, setOrderStatus } =
    useFirefly()

  const shouldShowPaywall =
    !fireflyFulfillableItemCreditModel ||
    fireflyFulfillableItemCreditModel === 'UNAVAILABLE' ||
    !showPaywall

  useEffect(() => {
    const handlePostMessage = (event: any) => {
      if (
        event.origin ===
          process.env.NEXT_PUBLIC_CLIENT_UNIFIED_PAYWALL_ORIGIN ||
        event.origin ===
          process.env.NEXT_PUBLIC_CLIENT_UNIFIED_PAYWALL_PLAN_ORIGIN
      ) {
        // T_T sometimes a string, sometimes an object, always a pain
        const message =
          typeof event.data === 'string' ? JSON.parse(event.data) : event
        // set order status to incomplete since paywall has been triggered
        setOrderStatus('ORDER_INCOMPLETE')
        // hide spinner if app is loaded
        if (message?.type === 'System' && message?.subType === 'AppLoaded') {
          setShowLoadingSpinner(false)
          FULLSTORY('trackEvent', {
            name: 'Paywall App Loaded',
            properties: {
              firefly_credit_model: fireflyFulfillableItemCreditModel
            }
          })
        }
        // handle events from the UnifiedPaywallMiniApp app (first page)
        if (
          message?.type === 'System' &&
          message?.app === 'UnifiedPaywallMiniApp'
        ) {
          // for some reason closed event is different than ucv3 / ManageApp
          if (
            message?.subType === 'AppClosed' &&
            message?.status === 'PAYWALL_CLOSED'
          ) {
            setShowPaywall(false)
            setShowPaywallToast(true)
          }
        }

        // handle the events from ucv3 / ManageApp
        if (message?.type === 'System') {
          // order is complete (it is it's own event)
          if (message?.subType === 'OrderCompleted') {
            setShowPaywallToast(false)
            setOrderStatus('ORDER_COMPLETE')
            FULLSTORY('trackEvent', {
              name: 'Order Complete',
              properties: {
                firefly_credit_model: fireflyFulfillableItemCreditModel
              }
            })
          }

          // close event has been fired set order status in case other message was missed
          if (
            message?.subType === 'Close' ||
            message?.subType === 'AppClosed'
          ) {
            if (message?.data?.status === 'ORDER_INCOMPLETE') {
              setShowPaywall(false)
              // don't show the paywall toast if the user has an all apps account
              fireflyFulfillableItemCreditModel === 'PAID-UNEXHAUSTABLE'
                ? setShowPaywallToast(false)
                : setShowPaywallToast(true)
              setOrderStatus('ORDER_INCOMPLETE')
              FULLSTORY('trackEvent', {
                name: 'Order Incomplete',
                properties: {
                  firefly_credit_model: fireflyFulfillableItemCreditModel
                }
              })
            } else if (message?.data?.status === 'ORDER_COMPLETE') {
              setShowPaywall(false)
              setShowPaywallToast(false)
              setOrderStatus('ORDER_COMPLETE')
            } else {
              // closed model on the first page
              setShowPaywall(false)
              fireflyFulfillableItemCreditModel === 'PAID-UNEXHAUSTABLE'
                ? setShowPaywallToast(false)
                : setShowPaywallToast(true)
              FULLSTORY('trackEvent', {
                name: 'Order Incomplete',
                properties: {
                  firefly_credit_model: fireflyFulfillableItemCreditModel
                }
              })
            }
          }
        }

        // open URLs such as terms and conditions
        if (message?.type === 'OpenURL') {
          if (message?.subType === 'EXTERNAL') {
            window.open(message?.data?.externalUrl, '_blank')
          } else if (message?.subType === 'INTERNAL') {
            window.open(message?.data?.externalUrl, '_self')
          } else {
            // subType 'SWITCH'
            window.open(message?.data?.externalUrl, '_self')
          }
        }
      }
    }

    window.addEventListener('message', handlePostMessage)
    return () => {
      window.removeEventListener('message', handlePostMessage)
    }
  }, [])

  useEffect(() => {
    if (authStatus !== 'AUTHENTICATED') return

    buildPaywallURL().then(url => {
      if (url) setPaywallURL(url)
    })
  }, [authStatus])

  const buildPaywallURL = async () => {
    const URL =
      `${process.env.NEXT_PUBLIC_CLIENT_UNIFIED_PAYWALL_ORIGIN}` +
      `${process.env.NEXT_PUBLIC_CLIENT_UNIFIED_PAYWALL_ENDPOINT}`
    const ctxRtUrl = window.location.href
    const [lang] = activeLocale.split('-')
    const { countryCode: co } = await AdobeIMS.getProfile()

    const commonParams = `of=FIREFLY&co=${co}&lang=${lang}&cli=neo&ctx=if&svar=firefly_launch&lo=sl&ctxRtUrl=${ctxRtUrl}`
    switch (fireflyFulfillableItemCreditModel) {
      case 'FREE-EXHAUSTABLE':
        return `${URL}?pcid=461&${commonParams}`
      case 'PAID-EXHAUSTABLE':
        return `${URL}?pcid=458&${commonParams}`
      case 'PAID-UNEXHAUSTABLE':
        return `${URL}?pcid=461&${commonParams}`
      default:
        return ''
    }
  }

  if (shouldShowPaywall) return null
  return (
    <DialogBase underlay open={showPaywall} className={styles['dialog-base']}>
      <div className={styles['dialog']}>
        {paywallURL && (
          <iframe
            title="Unified Paywall"
            data-tracking-event="studio:fireflyPanel:unifiedPaywall"
            allow="payment"
            src={paywallURL}></iframe>
        )}

        {showLoadingSpinner && (
          <div className={styles['progress-circle-container']}>
            <ProgressCircle size="l" indeterminate />
          </div>
        )}
      </div>
    </DialogBase>
  )
}

export default UnifiedPaywallDialog
