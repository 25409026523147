import React, { FC, useContext, useEffect } from 'react'
import PanelAccordion from '@components/panel/PanelAccordion'
import { useProjectState, useProjectActions } from '@hooks/useProject'
import { FieldLabel, Icon, OverlayTrigger, Textfield, Tooltip } from 'ui'
import LineHeightIcon from '/public/s2_icon_line_height.svg'
import TextSizeIcon from '/public/s2_icon_text_size.svg'
import TextSpacingIcon from '/public/s2_icon_text_tracking.svg'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { FontSizeField } from './FontSizeField'
import { TextSpacing } from './TextSpacing'
import FontPicker from '@components/fontPicker/FontPicker'
import { Section } from './ObjectProperties'
import { useTranslations } from 'use-intl'
import { LocalesRequiringSpecialFonts } from '@concerns/i18n/constants'
import { LocaleContext } from '@concerns/i18n/components/LocaleProvider'
import { useFlags } from 'launchdarkly-react-client-sdk'

const lineHeightPercentages: number[] = [
  -100, -75, -50, -25, -10, 0, 5, 10, 25, 50, 75, 100, 200
]

const textSpacingPercentages: number[] = [
  -100, -75, -50, -25, -10, 0, 5, 10, 25, 50, 75, 100, 200
]

const Text: FC = () => {
  const t = useTranslations()
  const { activeLocale } = useContext(LocaleContext)
  const threeDTextCollapsed = useProjectState('threeDTextCollapsed')
  const size = useProjectState('sizePreference')
  const textCopy = useSceneState('textCopy')
  const selectedFontPostScriptName = useSceneState('selectedFontPostScriptName')
  const { setPanelAccordionItem } = useProjectActions()
  const { setPropertyState, changeFont } = useSceneActions()
  const flags = useFlags()

  const specialFontIsRequired = activeLocale in LocalesRequiringSpecialFonts
  const defaultFontIsSelected = selectedFontPostScriptName === ''

  useEffect(() => {
    if (specialFontIsRequired && defaultFontIsSelected) {
      changeFont(LocalesRequiringSpecialFonts[activeLocale]!)
      setPropertyState({
        key: 'textLeadingType',
        value: 1 // see neoElement.h/FontLeadingType for values
      })
    }
  }, [activeLocale, specialFontIsRequired, defaultFontIsSelected])

  return (
    <PanelAccordion
      id={Section.text}
      label={t('object:properties:text')}
      collapsed={threeDTextCollapsed}
      onChange={collapsed =>
        setPanelAccordionItem({ key: 'threeDTextCollapsed', collapsed })
      }>
      <div>
        <FieldLabel for="three-d-text-input">
          {t('object:properties:text:input')}
        </FieldLabel>
        <Textfield
          disabled={specialFontIsRequired && defaultFontIsSelected}
          style={{ width: '100%' }}
          id="three-d-text-input"
          placeholder={t('object:properties:text:input:placeholder')}
          value={textCopy}
          multiLine
          size={size}
          onInput={e => {
            // Add in an end of line character where line breaks are
            const text = e.target.value.split('\n').join('\n')

            setPropertyState({
              key: 'textCopy',
              value: text
            })
          }}
        />
      </div>

      <FontPicker
        previewText={textCopy}
        disabled={specialFontIsRequired && defaultFontIsSelected}
        size={size}
        fontSizeSlot={
          <div>
            <OverlayTrigger placement="top" offset={0}>
              <div className="flex align-center gap-xs" slot="trigger">
                <Icon
                  size={size}
                  className="icon-color-content-neutral-default">
                  <TextSizeIcon />
                </Icon>
                <FontSizeField />
              </div>
              <Tooltip slot="hover-content">
                {t('object:properties:text:size')}
              </Tooltip>
            </OverlayTrigger>
          </div>
        }
      />

      <div className="flex align-center justify-between">
        {flags['base-tf-text-line-height'] && (
          <OverlayTrigger placement="top" offset={0}>
            <div className="flex align-center gap-xs" slot="trigger">
              <Icon size={size} className="icon-color-content-neutral-default">
                <LineHeightIcon />
              </Icon>
              <TextSpacing
                spacingType="text-line-height"
                percentanges={lineHeightPercentages}
              />
            </div>

            <Tooltip slot="hover-content">
              {t('object:properties:text:lineHeight')}
            </Tooltip>
          </OverlayTrigger>
        )}

        {flags['base-tf-text-spacing'] && (
          <OverlayTrigger placement="top" offset={0}>
            <div className="flex align-center gap-xs" slot="trigger">
              <Icon size={size} className="icon-color-content-neutral-default">
                <TextSpacingIcon />
              </Icon>
              <TextSpacing
                spacingType="text-spacing"
                percentanges={textSpacingPercentages}
              />
            </div>
            <Tooltip slot="hover-content">
              {t('object:properties:text:spacing')}
            </Tooltip>
          </OverlayTrigger>
        )}
      </div>
    </PanelAccordion>
  )
}

export default Text
