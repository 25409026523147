import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FireflyFulfillableItemCreditModel } from '@services/accessProfile/types'

type AccessProfileState = {
  fireflyFulfillableItemCreditModel: FireflyFulfillableItemCreditModel | null
}

const initialState: AccessProfileState = {
  fireflyFulfillableItemCreditModel: null
}

export const accessProfile = createSlice({
  name: 'accessProfile',
  initialState,
  reducers: {
    setFireflyFulfillableItemCreditModel: (
      state,
      {
        payload
      }: PayloadAction<AccessProfileState['fireflyFulfillableItemCreditModel']>
    ) => {
      state.fireflyFulfillableItemCreditModel = payload
    }
  }
})

export const { setFireflyFulfillableItemCreditModel } = accessProfile.actions
export default accessProfile.reducer
