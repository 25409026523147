import React from 'react'

import { useProjectActions, useProjectState } from '@hooks/useProject'
import { ActionButton, DialogBase, Icon } from 'ui'
import styles from '@styles/components/ToNeoDialog.module.scss'
import CloseIcon from 'public/s2_icon_close.svg'
import cn from 'classnames'
import { ToNeoProperButton } from '@components/toNeo/ToNeoProperButton'
import { useTranslations } from 'use-intl'
import { useFavIcon } from '@contexts/FavIconContext'
import { FavIconUrl } from '@constants/appConstants'

export function ToNeoDialog() {
  const t = useTranslations()
  const showDiscoverNeoDialog = useProjectState('showDiscoverNeoDialog')
  const { setShowDiscoverNeoDialog } = useProjectActions()

  return (
    <DialogBase underlay open={showDiscoverNeoDialog}>
      <div className={styles['dialog']}>
        <ActionButton
          className={styles['close-button']}
          onClick={() => setShowDiscoverNeoDialog(false)}>
          <Icon slot="icon">
            <CloseIcon />
          </Icon>
        </ActionButton>
        <Card
          title={t('studio:neoHoverCard')}
          body={t('studio:neoHoverCard:body')}
          imgSrc="/firefly/module/hover-card-image/hover-card-image.png"
        />
      </div>
    </DialogBase>
  )
}

type CardProps = {
  body: string
  imgSrc: string
  title: string
}

function Card({ imgSrc, title, body }: CardProps) {
  return (
    <CardContainer>
      <CardImage imgSrc={imgSrc} />
      <CardText title={title} body={body} />
    </CardContainer>
  )
}

function CardContainer({ children }: React.PropsWithChildren) {
  return <div className={styles['card-container']}>{children}</div>
}

function CardImage({ imgSrc }: Pick<CardProps, 'imgSrc'>) {
  return <img draggable={false} src={imgSrc} className={styles['card-image']} />
}

function CardText({ title, body }: Pick<CardProps, 'body' | 'title'>) {
  const { setShowDiscoverNeoDialog, setDirectToNeoProperStatus } =
    useProjectActions()
  const t = useTranslations()
  const { setFavIconUrl } = useFavIcon()

  const handleOnClick = () => {
    setShowDiscoverNeoDialog(false)
    setDirectToNeoProperStatus('DIRECTING')
    setFavIconUrl(FavIconUrl)
  }

  return (
    <div className={styles['card-text-container']}>
      <p className={cn(styles['card-text'], styles['card-title'])}>{title}</p>
      <p className={cn(styles['card-text'], styles['card-body'])}>{body}</p>
      <div className={styles['to-neo-button']}>
        <ToNeoProperButton
          data-tracking-event="studio:toNeoDialog:tryNeo"
          onClick={handleOnClick}>
          {t('studio:neoDialog:button')}
        </ToNeoProperButton>
      </div>
    </div>
  )
}


