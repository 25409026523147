import { RootState } from '@store/store'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { history } from '@store/slices/historySlice'

export function useHistoryState<T extends keyof RootState['history']>(
  key: T
): RootState['history'][T] {
  const state = useSelector((state: RootState) => state.history[key])
  return state
}

export const useHistoryActions = () => {
  const dispatch = useDispatch()
  const boundedActions = bindActionCreators(history.actions, dispatch)
  return boundedActions
}

export type HistoryStateProperties = ReturnType<typeof useHistoryActions> &
  RootState['history']
