import Engine from '@services/engine/engine'
import { Socket, Channel } from 'phoenix'
import LDClient, {
  LDClient as LDClientInstance
} from 'launchdarkly-js-client-sdk'
import {
  getDeviceID,
  getDeviceName,
  getOSName,
  getOSVersion
} from '@services/device'
import * as AbsintheSocketType from '@absinthe/socket'
import { DunamisClient } from '@concerns/monitoring/service/dunamis/dunamis'
import AdobeAnalyticsWrapper from '@concerns/monitoring/service/adobeAnalyticsWrapper'
import { SocketName } from './socket/socketSaga'
import { UniversalNav } from '@services/universalNav/UniversalNav'
import TypeQuestWeb from '@services/font/typeQuestWeb'

type Context = {
  Engine?: Engine
  AbsintheSocket?: AbsintheSocketType
  LDClient?: LDClientInstance
  Dunamis?: DunamisClient
  Analytics?: typeof AdobeAnalyticsWrapper
  UniversalNav: UniversalNav
  TypeQuestWeb: TypeQuestWeb
} & Record<SocketName, { socket?: Socket; channel?: Channel }>

const Context: Context = {
  Dunamis: new DunamisClient({
    project: 'neo-web-service',
    apiKey: 'neo-web-service'
  }),
  Analytics: AdobeAnalyticsWrapper,
  document: {},
  sync: {},
  chime: {},
  UniversalNav: new UniversalNav(),
  TypeQuestWeb: new TypeQuestWeb()
}

export function initializeLDClient(): LDClientInstance {
  if (!Context.LDClient) {
    Context.LDClient = LDClient.initialize(
      process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID!,
      {
        anonymous: true
      }
    )
  }

  return Context.LDClient
}

export async function sendDunamisStartEvent(
  usedId: string,
  adobeUserId: string
) {
  return Context.Dunamis!.recordEvent({
    'event.service_user_guid': usedId,
    'event.user_guid': adobeUserId,
    'event.type': 'start',
    'event.subcategory': 'lifecycle',
    'event.guid': crypto.randomUUID(),
    'event.subtype': 'init',
    'event.build': process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT!,
    'event.device_guid': getDeviceID(),
    'source.platform': getOSName(),
    'source.os_version': getOSVersion(),
    'source.device': getDeviceName(),
    'event.user_agent': navigator.userAgent
  })
}

export default Context
