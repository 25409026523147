import { useProjectActions, useProjectState } from '@hooks/useProject'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { captureException } from '@sentry/nextjs'
import { useGetProjectByUuidQuery } from '@store/graphql/__generated__/schema'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect } from 'react'
import styles from '@styles/components/Studio.module.scss'
import cn from 'classnames'
import { CANVAS_CONTAINER_ID } from '@services/engine/engine'
import { SceneToImageStudioPathname } from '@constants/appConstants'
import OrbitControls from '@components/propertiesPanel/OrbitControls'
import { useFlags } from 'launchdarkly-react-client-sdk'
import FloatingEditShapeToggle from '@components/propertiesPanel/FloatingEditShapeToggle'
import { useIsExportingVideo } from './useIsExportingVideo'
import { useStudioLoadingSpinner } from '@hooks/useStudioLoadingSpinner'

type Props = {
  mainSectionItemStyle: React.CSSProperties
  uuid: string
}

export const CanvasContainer = ({
  mainSectionItemStyle,
  uuid
}: PropsWithChildren<Props>) => {
  const showFireflyPopover = useProjectState('showFireflyPopover')
  const engineState = useSceneState('engineState')
  const browserCompabitility = useProjectState('browserCompatibility')
  const { push } = useRouter()
  const flags = useFlags()
  const isExportingVideo = useIsExportingVideo()

  const { refetch: fetchProject } = useGetProjectByUuidQuery({
    variables: { uuid: uuid || '' },
    skip: true,
    onCompleted({ project }) {
      if (!project) return
      setProjectUuid(project.uuid!)
      setIsFeatured(project.featured!)
      setIsPublic(project.public!)
      setName(project.name!)
      setOwnerUserUuid(project.ownerUserUuid || null)
      setEngineState('INITIALIZE')
      loadProject({ requireAuth: true })
    },
    onError(error) {
      captureException(error.message)
      console.error(error)
      push('/discover')
    }
  })

  const {
    setProjectUuid,
    setIsFeatured,
    setName,
    setOwnerUserUuid,
    setIsPublic,
    loadProject
  } = useProjectActions()

  const { setEngineState } = useSceneActions()
  const { showStudioLoadingSpinner } = useStudioLoadingSpinner()

  useEffect(() => {
    // Check for browser compatibility before fetching project so that the engine is not initialized in an unsupported browser. Attempting to do so could cause an unrecoverable error.
    const shouldFetchProject =
      window.location.pathname !== SceneToImageStudioPathname &&
      browserCompabitility === 'Compatible'

    if (shouldFetchProject) {
      fetchProject({
        uuid
      })
    }

    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [uuid, browserCompabitility])

  return (
    <div
      className={cn(styles['canvas-container'], {
        [styles['max-size']]: !showFireflyPopover,
        ['position-relative']: showFireflyPopover,
        [styles['border-radius']]: showFireflyPopover
      })}
      id={CANVAS_CONTAINER_ID}
      style={mainSectionItemStyle}>
      {flags['base-pf-ui-floating-edit-shape-toggle'] &&
        !isExportingVideo &&
        !showStudioLoadingSpinner && <FloatingEditShapeToggle />}
      {flags['base-tf-ui-camera-orbit'] &&
        showFireflyPopover &&
        engineState === 'INITIALIZED_AND_DOCUMENT_LOADED' && <OrbitControls />}
    </div>
  )
}
