/**
 * Utility used for asynchronous communication of values.
 *
 * The class is expected to be used from two endpoints.
 *
 * - One endpoint waits for an operation to complete and uses the `value` property that contains the `Promise` for the
 * asynchronous operation and the result of it.
 * - Another endpoint marks the operation completed and assigns a result value by calling `resolve(value)`.
 *
 * To reset the `Deferred` call reset().
 */
export class Deferred<T> {
  private _promise!: Promise<T>
  private _resolvePromise!: (value: T) => void
  private _rejectPromise!: (error: unknown) => void
  private _isFulfilled = false
  private _isRejected = false
  private _hasAdoptedOnce = false
  private _promiseInUse = false

  /**
   * Initializes this `Deferred`.
   */
  constructor() {
    this.reset()
  }

  /**
   * Returns the associated promise.
   */
  get promise(): Promise<T> {
    this._promiseInUse = true
    return this._promise
  }

  /**
   * Indicates if the associated promise has been either rejected or fulfilled.
   */
  get isSettled(): boolean {
    return this._isFulfilled || this._isRejected
  }

  /**
   * Indicates if the associated promise has been fulfilled.
   */
  get isFulfilled(): boolean {
    return this._isFulfilled
  }

  /**
   * Indicates if the associate promise has been rejected.
   */
  get isRejected(): boolean {
    return this._isRejected
  }

  /**
   * Adopts a promise to drive this Deferred's lifecyle. That is, when the passed-in promise is resolved, this
   * deferred's promise will also be resolved. Same for rejection.
   * @param promise - The promise to adopt.
   * @returns - This deferred's promise. (Not the adopted promise! Although, they have the effectively the same
   *      lifecycle now.)
   */
  adopt(promise: Promise<T>): Promise<T> {
    this._promiseInUse = true
    promise.then(this._resolvePromise).catch(this._rejectPromise)
    return this.promise
  }

  /**
   * Adopts a promise to drive this Deferred's lifecycle, similar to `adopt`. The adopted promise is created by
   * running the provided `promiseFactory` method. If `adoptOnce` is called multiple times, only the `promiseFactory`
   * from the first call will be run, and only that promise will be adopted.
   * @param promiseFactory - A function that returns a promise when executed. This Deferred adoptes the returned
   *      promise.
   * @returns - This deferred's promise. (Not the adopted promise! Although, they have the effectively the same
   *      lifecycle now.)
   */
  adoptOnce(promiseFactory: () => Promise<T>): Promise<T> {
    this._promiseInUse = true
    if (this._hasAdoptedOnce) {
      return this.promise
    }

    void this.adopt(promiseFactory())
    this._hasAdoptedOnce = true
    return this.promise
  }

  /**
   * Fulfills the associated promise with the specified value.
   *
   * @param value - used to execute the resolve method of the associated promise with the value specified
   */
  resolve(value: T): void {
    this._resolvePromise(value)
  }

  /**
   * Rejects the associated promise with the given error.
   *
   * @param reason - reason for the rejection
   */
  reject(reason: unknown): void {
    this._rejectPromise(reason)
  }

  /**
   * Creates and configures new promise. If the current promise is in use and not yet fulfilled it will be rejected.
   */
  reset(): void {
    if (!this._isFulfilled && !this._isRejected && this._promiseInUse) {
      this._rejectPromise(
        new Error('ABORTED: Deferred was reset before result arrived')
      )
    }
    this._isFulfilled = false
    this._isRejected = false
    this._hasAdoptedOnce = false
    this._promiseInUse = false

    this._promise = new Promise((resolve, reject) => {
      this._resolvePromise = value => {
        this._isFulfilled = true
        this._isRejected = false
        resolve(value)
      }

      this._rejectPromise = reason => {
        this._isRejected = true
        this._isFulfilled = false
        reject(reason)
      }
    })
  }
}
