import { LocaleContext } from '@concerns/i18n/components/LocaleProvider'
import { PresetCategory } from '@store/slices/fireflySlice'
import { useContext, useEffect, useState } from 'react'
import { useTranslations } from 'use-intl'

interface GetLocalizedStylePresetProps {
  key: string
  fallback?: string
  isCategory?: boolean
}

const formatFallbackString = (fallbackString: string) => {
  if (fallbackString === PresetCategory.CAMERA_ANGLE) return 'Camera angle'
  if (fallbackString === PresetCategory.COLOR_AND_TONE) return 'Color and tone'
  return `${fallbackString.charAt(0).toUpperCase()}${fallbackString.slice(1)}`
}

export const useLocalizedStylePresets = () => {
  const t = useTranslations()
  const { activeLocale } = useContext(LocaleContext)
  const [localizedStylePresets, setLocalizedStylePresets] = useState<
    Record<string, string>
  >({})
  const [localizedStylePresetsLoaded, setLocalizedStylePresetsLoaded] =
    useState(false)

  useEffect(() => {
    getLocalizedStylePresets()
  }, [activeLocale])

  async function getLocalizedStylePresets() {
    try {
      const localizedEntries = await fetch(
        `${
          process.env.NEXT_PUBLIC_CLIENT_FIREFLY_ASSET_ENDPOINT
        }/image-styles/v7/locales/${activeLocale || 'en-US'}.json`
      ).then(r => r.json())

      setLocalizedStylePresets(localizedEntries)
      setLocalizedStylePresetsLoaded(true)
    } catch {
      setLocalizedStylePresetsLoaded(false)
    }
  }

  function getLocalizedStylePresetString({
    key,
    fallback = key,
    isCategory = false
  }: GetLocalizedStylePresetProps) {
    if (key === PresetCategory.ALL) {
      return t('studio:firefly:preset:all')
    }

    if (key === PresetCategory.POPULAR) {
      return t('studio:firefly:preset:popular')
    }

    if (!localizedStylePresetsLoaded) {
      return formatFallbackString(fallback)
    }

    return isCategory
      ? localizedStylePresets[`image-styles.group.${key}.title`]
      : localizedStylePresets[`image-styles.${key}.title`]
  }

  return {
    getLocalizedStylePresetString
  }
}
