import React, { FC } from 'react'
import { useTranslations } from 'use-intl'
import ShareExportFooter from './ShareExportFooter'
import { useSceneActions } from '@hooks/useScene'

const MeshExportForm: FC = () => {
  const t = useTranslations()
  const { exportCapture } = useSceneActions()

  return (
    <ShareExportFooter
      onExportClick={() =>
        exportCapture({ format: 'obj', workflow: 'download' })
      }
      buttonText={t('studio:appBar:downloadButton')}
    />
  )
}

export default MeshExportForm
