import { FC } from 'react'
import styles from '@styles/components/ToNeoLoadingScreen.module.scss'
import cn from 'classnames'
import { useTranslations } from 'use-intl'
import { useProjectActions, useProjectState } from '@hooks/useProject'

export const ToNeoLoadingScreen: FC = () => {
  const t = useTranslations()

  const directToNeoProperStatus = useProjectState('directToNeoProperStatus')
  const { setDirectToNeoProperStatus, setEnvironment } = useProjectActions()
  const projectUuid = useProjectState('projectUuid')

  function handleOnAnimationEnd(animationName: string) {
    if (animationName.includes('fade-in')) {
      setDirectToNeoProperStatus('COMPLETE')
      setEnvironment('default')

      window.history.replaceState(
        {},
        '',
        `${process.env.NEXT_PUBLIC_CLIENT_WEB_HOST}/studio/${projectUuid}`
      )
    } else if (animationName.includes('fade-out')) {
      setDirectToNeoProperStatus('IDLE')
    }
  }

  if (directToNeoProperStatus === 'IDLE') return <></>

  return (
    <>
      <div
        onAnimationEnd={e => handleOnAnimationEnd(e.animationName)}
        className={cn(styles['container'], {
          [styles['container--fade-in']]:
            directToNeoProperStatus === 'DIRECTING',
          [styles['container--fade-out']]:
            directToNeoProperStatus === 'COMPLETE'
        })}>
        <img
          className={styles['loading-image']}
          src="/firefly/module/to-neo-loading-image/to-neo-loading-image.png"
        />
        <h2 className={cn(styles['text'], styles['heading'])}>
          {t('studio:toNeoLoadingScreen:heading')}
        </h2>
        <p className={cn(styles['text'], styles['body-text'])}>
          {t('studio:toNeoLoadingScreen:body')}
        </p>
      </div>
    </>
  )
}
