import React, { useState } from 'react'
import { DialogBase, Dialog, Button, Checkbox, FieldGroup } from 'ui'
import { useProject } from '@hooks/useProject'
import {
  LocalStorageBooleanValue,
  LocalStorageKey
} from 'constants/localStorage'
import styles from '@styles/components/WindowsGPUConfigInstructionDialog.module.scss'
import { useTranslations } from 'use-intl'

const WindowsGPUConfigInstructionDialog = () => {
  const t = useTranslations()
  const {
    showWindowsGPUConfigInstructionDialog,
    setShowWindowsGPUConfigInstructionDialog
  } = useProject()

  const [checked, setChecked] = useState(false)

  function handleClose() {
    setShowWindowsGPUConfigInstructionDialog(false)
    localStorage.setItem(
      LocalStorageKey.engineHideWindowsGPUConfigInstructionsDialog,
      checked ? LocalStorageBooleanValue.TRUE : LocalStorageBooleanValue.FALSE
    )
  }

  return (
    <DialogBase underlay open={showWindowsGPUConfigInstructionDialog}>
      <Dialog size="l">
        <h2 slot="heading">{t('studio:windowsConfig:oops')}</h2>
        <h4>{t('studio:windowsConfig:instructions')}</h4>
        <ul className={styles['list']}>
          <li>
            {t.rich('studio:windowsConfig:instructions:1', {
              bolded: chunks => <strong>{chunks}</strong>
            })}
          </li>
          <li>
            {t.rich('studio:windowsConfig:instructions:2', {
              bolded: chunks => <strong>{chunks}</strong>
            })}
          </li>
          <li>{t('studio:windowsConfig:instructions:3')}</li>
        </ul>
        <FieldGroup>
          <Checkbox
            size="m"
            checked={checked}
            change={() => setChecked(!checked)}>
            {t('studio:windowsConfig:ignore')}
          </Checkbox>
        </FieldGroup>
        <Button slot="button" variant="primary" onClick={handleClose}>
          {t('studio:windowsConfig:close')}
        </Button>
      </Dialog>
    </DialogBase>
  )
}

export default WindowsGPUConfigInstructionDialog
