import { useProjectState } from '@hooks/useProject'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { FC } from 'react'
import { NumberField } from 'ui'

export const FontSizeField: FC = () => {
  const textSize = useSceneState('textSize')
  const { setPropertyState } = useSceneActions()
  const size = useProjectState('sizePreference')

  return (
    <NumberField
      id="text-size"
      aria-label="text-size"
      positiveOnly
      className="object-properties-text-number-field-width"
      step={0.001}
      value={textSize}
      onInput={e =>
        setPropertyState({
          key: 'textSize',
          value: e.target.value
        })
      }
      hideStepper
      size={size}
    />
  )
}
