import { useEffect, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

type MaterialsQueryResult = {
  data: {
    assets: {
      hasMore: boolean
      items: {
        id: string
        title: string
        thumbnail: {
          url: string
        }
      }[]
      total: number
    }
  }
}

type MenuQueryResult = {
  data: {
    materialCategories: { name: string }[]
  }
}

type Material = {
  category: string
  items: MaterialsQueryResult['data']['assets']['items']
}

const queryMaterials = async (
  category: string
): Promise<MaterialsQueryResult> => {
  return await fetch(
    process.env.NEXT_PUBLIC_CLIENT_SUBSTANCE_3D_GRAPHQL_ENDPOINT!,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        operationName: 'Assets',
        query: `
            query Assets($page: Int, $limit: Int = 20, $search: String, $filters: AssetFilters, $sortDir: SortDir = desc, $sort: AssetSort = byPublicationDate) {
              assets(search: $search, filters: $filters, sort: $sort, sortDir: $sortDir, page: $page, limit: $limit  ) {
                total
                hasMore
                items {
                  id
                  thumbnail {
                    url
                  }
                }
              }
            }
          `,
        variables: {
          limit: 5,
          sortDir: 'desc',
          sort: 'byPublicationDate',
          page: 0,
          filters: {
            category,
            status: ['published', 'draft'],
            assetType: ['substanceMaterial'],
            extraData: []
          }
        }
      })
    }
  ).then(r => r.json())
}

const queryCategories = async (): Promise<MenuQueryResult> => {
  return await fetch(
    process.env.NEXT_PUBLIC_CLIENT_SUBSTANCE_3D_GRAPHQL_ENDPOINT!,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        operationName: 'Menu',
        query: `
            query Menu($status: [Status!] = [published, draft]) {
              materialCategories: categories(filters: {assetType: substanceMaterial, status: $status}) {
                name
              }

            }
          `,
        variables: {
          status: ['published', 'draft']
        }
      })
    }
  ).then(r => r.json())
}

export const useSubstance3DMaterials = () => {
  const flags = useFlags()
  const [materials, setMaterials] = useState<Material[] | null>(null)

  useEffect(() => {
    if (flags['base-pf-ui-substance-materials']) getMaterials()
  }, [flags['base-pf-ui-substance-materials']])

  async function getMaterials() {
    if (
      materials ||
      !process.env.NEXT_PUBLIC_CLIENT_SUBSTANCE_3D_GRAPHQL_ENDPOINT
    ) {
      return
    }

    const categories = (await queryCategories()).data.materialCategories

    const materialsQueryRes = await Promise.all(
      categories.map(async category => {
        const { items } = (await queryMaterials(category.name)).data.assets

        return {
          category: category.name,
          items
        } as Material
      })
    )

    setMaterials(materialsQueryRes)
  }

  return materials
}
