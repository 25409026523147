import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { RootState } from '@store/store'
import { projectCard } from '@store/slices/projectCardSlice'

export const useProjectCardActions = () => {
  const dispatch = useDispatch()
  const boundedActions = bindActionCreators(projectCard.actions, dispatch)
  return boundedActions
}

export function useProjectCardState<T extends keyof RootState['projectCard']>(
  key: T
): RootState['projectCard'][T] {
  const state = useSelector((state: RootState) => state.projectCard[key])
  return state
}
