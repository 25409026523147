import React, { ComponentProps, FC } from 'react'
import { Button } from 'ui'
import { useAuth } from '@hooks/useAuth'
import { SpIconAdd } from 'workflow-icons'
import { createNewProjectName, useCreateProject } from '@hooks/useCreateProject'
import { useRouter } from 'next/router'
import { useTranslations } from 'use-intl'
import { TrackingEvent } from '@constants/analytics'
import { useMonitoringActions } from '@hooks/useMonitoring'

interface Props {
  className?: string
  'data-tracking-event'?: TrackingEvent
  hideIcon?: boolean
  label?: string
  variant?: ComponentProps<typeof Button>['variant']
}

const CreateProjectButton: FC<Props> = ({
  className,
  'data-tracking-event': trackingEvent,
  hideIcon,
  label,
  variant
}) => {
  const { localUser } = useAuth()
  const { push } = useRouter()
  const t = useTranslations()
  const { sendEvent } = useMonitoringActions()

  const [createProject, { loading }] = useCreateProject({
    update: (_, mutationResult) => {
      const uuid = mutationResult.data?.createProject?.uuid
      if (uuid) push(`/studio/${uuid}`)
    },
    variables: {
      name: createNewProjectName(),
      tags: ['lifestyle', 'tech']
    }
  })

  function handleCreateClick() {
    if (trackingEvent) sendEvent(trackingEvent)
    createProject()
  }

  return (
    <Button
      className={className}
      variant={variant || 'accent'}
      size={variant ? 'l' : 'm'}
      pending={!localUser || loading}
      onClick={handleCreateClick}
      style={{
        whiteSpace: 'nowrap'
      }}>
      {!hideIcon && <SpIconAdd slot="icon" />}
      {label || t('dashboard:createProjectShort')}
    </Button>
  )
}

export default CreateProjectButton
