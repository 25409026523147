export const UNAV_ELEMENT_ID = 'universal-nav-target'
export const PREV_UNAV_VERSION = '1.1'
export const UNAV_VERSION = '1.4'

export const APP_VERSION = '1.0'

const UNAV_NAME = 'UniversalNav'

export const uNavConfig = {
  baseUrl:
    process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT === 'prd'
      ? 'https://prod.adobeccstatic.com'
      : 'https://stage.adobeccstatic.com',
  name: UNAV_NAME
}

export const UNAV_URL = {
  js: (uNavVersion: string) => {
    return `${uNavConfig.baseUrl}/unav/${uNavVersion}/${uNavConfig.name}.js`
  },
  css: (uNavVersion: string) => {
    return `${uNavConfig.baseUrl}/unav/${uNavVersion}/${uNavConfig.name}.css`
  }
}

export const UNAV_ACCOUNT_MENU_TEST_ID = 'user-avatar-account-icon'
