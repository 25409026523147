import { useProjectState } from './useProject'

export const useStudioEnvironment = () => {
  const environment = useProjectState('environment')

  return {
    environment: environment,
    isDefaultEnvironment: environment === 'default',
    isSceneToImageEnvironment: environment === 'scene-to-image'
  }
}
