import React from 'react'
import { Button, Popover } from 'ui'
import styles from '@styles/components/FireflyPaidExhaustableNotification.module.scss'
import { useFirefly } from '@hooks/useFirefly'
import { useSceneState } from '@hooks/useScene'
import { useTranslations } from 'use-intl'

export function FireflyPaidExhaustableNotification() {
  const t = useTranslations()

  const engineState = useSceneState('engineState')

  const {
    showGenerateButtonFirstUseNotification,
    setShowGenerateButtonFirstUseNotification
  } = useFirefly()

  function onClick() {
    setShowGenerateButtonFirstUseNotification(false)
  }

  if (
    !showGenerateButtonFirstUseNotification ||
    engineState !== 'INITIALIZED_AND_DOCUMENT_LOADED'
  ) {
    return null
  }
  return (
    <Popover className={styles['popover']} open>
      <h6>{t('studio:firefly:notification:paid-exhaustable-user:header')}</h6>
      <p>{t('studio:firefly:notification:paid-exhaustable-user:body')}</p>
      <Button treatment="outline" variant="primary" onClick={onClick}>
        {t('studio:firefly:notification:paid-exhaustable-user:cta')}
      </Button>
    </Popover>
  )
}
