import { PayloadAction } from '@reduxjs/toolkit'
import Context from '@store/middleware/context'
import {
  setEngineState,
  SceneState,
  engineLoaded,
  setCaptureStatus
} from '@store/slices/sceneSlice'
import {
  put,
  fork,
  take,
  all,
  call,
  takeEvery,
  select,
  putResolve
} from 'redux-saga/effects'
import createEventChannel from '../createEventChannel'
import Engine from '@services/engine/engine'
import { RootState } from '@store/store'
import {
  setIsLoading,
  setShowTouchDeviceWarningDialog,
  setShowWindowsGPUConfigInstructionDialog
} from '@store/slices/projectSlice'
import {
  DeviceStatusEventPayload,
  ModelLoadedStatusEventPayload
} from '@services/engine/types'
import {
  cancelWatchSocket,
  destroySocket
} from '@store/middleware/socket/socketSaga'
import { setRemoteUsers } from '@store/slices/syncSlice'
import {
  resetState as resetFireflyState,
  stopImagGeneration
} from '@store/slices/fireflySlice'
import { BaseTfStudioFinePointerCompatibility } from 'types/flag'
import {
  LocalStorageBooleanValue,
  LocalStorageKey
} from 'constants/localStorage'
import { initializeFontService } from '../fontSaga/fontSaga'

function* initializeEngine() {
  if (Context.Engine) {
    Context.Engine?.updateFeatures(getFeatures())
    yield Context.Engine.mount()
    return
  }
  Context.Engine = new Engine()
  const features = getFeatures()
  yield fork(Context.Engine.initialize, features)
  Context.LDClient?.on('change', () =>
    Context.Engine?.updateFeatures(getFeatures())
  )
}

function getFeatures() {
  var featureMap = {}

  const featureVariables = [
    'base-tf-fx-snapshots',
    'base-tf-fx-camera-orbit',
    'base-tf-fx-camera-autofocus',
    'base-tf-fx-snapping',
    'base-tf-fx-alignment',
    'base-tf-fx-zoom-in-out-selection',
    'base-tf-fx-un-group',
    'base-pf-fx-rendering-technique',
    'base-tf-ui-camera-snapping',
    'base-tf-ui-infinite-click-and-rescale-add-primitive',
    'base-tf-ui-export-video'
  ]

  featureVariables.forEach(variable => {
    featureMap[variable] = Context.LDClient.variation(variable)
  })

  return JSON.stringify(featureMap)
}

function* handleSetEngineState({
  payload
}: PayloadAction<SceneState['engineState']>) {
  switch (payload) {
    case 'INITIALIZE':
      yield put(initializeFontService())
      yield call(clearEngineCache)
      yield call(initializeEngine)
      yield put(setEngineState('INITIALIZED'))
      break
    case 'INITIALIZED':
      yield handleInitializedEngine()
      break
    case 'UNMOUNT':
      yield unmountEngine()
      break

    default:
      break
  }
}

async function clearEngineCache() {
  if (!caches) return

  const keyList = await caches.keys()

  const EngineCacheKeys = ['engine/build/neo.js', 'engine/build/neo.wasm']

  await Promise.all(
    keyList.map(async key => {
      const cache = await caches.open(key)
      const cacheKeys = await cache.keys()

      return Promise.all(
        cacheKeys
          .filter(entry => EngineCacheKeys.some(key => entry.url.includes(key)))
          .map(entry => cache.delete(entry))
      )
    })
  )
}

function* handleInitializedEngine() {
  yield put(engineLoaded())
  yield fork(handleStatusChannel)
  yield fork(handleDebugChannel)
  yield fork(handleDeviceStatusChannel)
}

function* unmountEngine() {
  if (Context.Engine) {
    yield clearEngineRemoteUsers()
    yield Context.Engine.unmount()
    yield put(resetFireflyState())
    yield put(stopImagGeneration())
    yield putResolve(setCaptureStatus({ status: 'not-ready', format: null }))
    yield [
      Context.Engine.EngineStatusChannel,
      Context.Engine.EngineDataChannel,
      Context.Engine.EngineDebugChannel,
      Context.Engine.EngineFrameDataChannel
    ].forEach(channel => channel.removeAllListeners())
  }

  yield put(destroySocket('document'))
  yield put(cancelWatchSocket('document'))
  yield put(destroySocket('sync'))
  yield put(cancelWatchSocket('sync'))
  yield put(setEngineState('UNMOUNTED'))
}

function* clearEngineRemoteUsers() {
  yield put(setRemoteUsers([]))

  const remoteUsers: RootState['sync']['remoteUsers'] = yield select(
    (state: RootState) =>
      state.sync.remoteUsers.filter(
        ({ user }) => user.uuid !== state.auth.localUser?.uuid
      )
  )

  remoteUsers.forEach(({ user }) =>
    Context.Engine?.setRemoteUserStatus({
      remote_user_uuid: user.uuid,
      status: 'away'
    })
  )
}

function* handleStatusChannel() {
  const statusChannel = yield call(
    createEventChannel,
    Context.Engine?.EngineStatusChannel,
    'status'
  )
  while (true) {
    try {
      const statusEvent: ModelLoadedStatusEventPayload = yield take(
        statusChannel
      )

      const modelLoaded = statusEvent?.status === 1
      if (modelLoaded) {
        yield put(setEngineState('INITIALIZED_AND_DOCUMENT_LOADED'))
        yield put(setIsLoading(false))

        const showTouchDeviceWarningDialog =
          localStorage.getItem(
            LocalStorageKey.engineHideTouchDeviceWarningDialog
          ) !== LocalStorageBooleanValue.TRUE &&
          Context.LDClient?.variation(
            'base-tf-studio-fine-pointer-compatibility'
          ) === BaseTfStudioFinePointerCompatibility.NOT_FULLY_SUPPORTED &&
          !window?.matchMedia('(pointer: fine)').matches

        if (showTouchDeviceWarningDialog) {
          yield put(setShowTouchDeviceWarningDialog(true))
        }
      }
    } catch (err) {
      console.error(err)
    }
  }
}

function* handleDebugChannel() {
  // const debugChannel = yield call(
  //   createEventChannel,
  //   engine.EngineDebugChannel,
  //   'debug'
  // )
}

function* handleDeviceStatusChannel() {
  const channel = yield call(
    createEventChannel,
    Context.Engine?.EngineStatusChannel,
    'device-status'
  )
  while (true) {
    try {
      const deviceStatus: DeviceStatusEventPayload = yield take(channel)

      if (deviceStatus === DeviceStatusEventPayload.SLOW_INTEL_GPU) {
        yield handleSlowIntelGPUDeviceStatus()
      }
    } catch (err) {
      console.error(err)
    }
  }
}

function* handleSlowIntelGPUDeviceStatus() {
  if (typeof navigator === 'undefined') return

  const isWindows =
    navigator.platform.toUpperCase().includes('WINDOWS') ||
    navigator.userAgent.toUpperCase().includes('WINDOWS')

  const show =
    localStorage.getItem(
      LocalStorageKey.engineHideWindowsGPUConfigInstructionsDialog
    ) !== LocalStorageBooleanValue.TRUE

  if (isWindows && show) {
    yield put(setShowWindowsGPUConfigInstructionDialog(true))
  }
}

export default function* engineSaga() {
  yield all([takeEvery(setEngineState.type, handleSetEngineState)])
}
