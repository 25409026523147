import { useProjectState } from '@hooks/useProject'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { FC } from 'react'
import { MenuItem, Picker } from 'ui'

type Props = {
  spacingType: 'text-spacing' | 'text-line-height'
  percentanges: number[]
}

export const TextSpacing: FC<Props> = ({ spacingType, percentanges }) => {
  const size = useProjectState('sizePreference')
  const textLetterSpacing = useSceneState('textLetterSpacing')
  const textLineHeight = useSceneState('textLineHeight')
  const { setPropertyState } = useSceneActions()

  const spacingValue =
    spacingType == 'text-spacing' ? textLetterSpacing : textLineHeight

  const handleOnChange = (e: Event) => {
    const newValue = (e.target as any).value

    spacingType === 'text-spacing'
      ? setPropertyState({ key: 'textLetterSpacing', value: Number(newValue) })
      : setPropertyState({ key: 'textLineHeight', value: Number(newValue) })

    document.getElementById('canvas')?.focus()
  }

  return (
    <Picker
      aria-label={spacingType}
      id={spacingType}
      size={size}
      className="object-properties-text-number-field-width"
      value={spacingValue.toString()}
      onchange={handleOnChange}>
      {percentanges.map(percentage => (
        <MenuItem key={percentage} value={percentage.toString()}>
          {`${percentage}%`}
        </MenuItem>
      ))}
    </Picker>
  )
}
