import { Slider } from 'ui'
import { useMaxMediumSizePreference } from '@hooks/useProject'
import { useTranslations } from 'use-intl'
import { useFirefly } from '@hooks/useFirefly'
import { useState } from 'react'
import { closedIntervalOntoClosedInterval } from '@components/slider/BoundedPropertySlider'

export function GuideStrengthSlider() {
  const minDecimalPercentage = 0
  const maxDecimalPercentage = 1
  const minGuideStrength = 0
  const maxGuideStrength = 33
  const [currentPercentage, setCurrentPercentage] =
    useState<number>(minDecimalPercentage)
  const maxMediumSize = useMaxMediumSizePreference()
  const t = useTranslations()
  const { setPropertyState } = useFirefly()

  function handleOnInput(e: React.FormEvent) {
    const percentage = (e as unknown as { target: { value: number } }).target
      .value

    setCurrentPercentage(percentage)

    const value = closedIntervalOntoClosedInterval(
      minDecimalPercentage,
      maxDecimalPercentage,
      minGuideStrength,
      maxGuideStrength,
      percentage
    )

    const updatedGuideStrength = Math.round(value)

    setPropertyState({
      key: 'guideStrength',
      value: updatedGuideStrength
    })
  }

  return (
    <Slider
      size={maxMediumSize}
      min={minDecimalPercentage}
      max={maxDecimalPercentage}
      value={currentPercentage}
      step={0.01}
      variant="filled"
      label={t('studio:firefly:guideStrength')}
      aria-label={t('studio:firefly:guideStrength')}
      onInput={handleOnInput}
      format-options={`{
      "style": "percent"
      }`}
    />
  )
}
