import React, { useEffect } from 'react'
import { Button, DialogBase, ProgressBar } from 'ui'
import styles from '@styles/components/VideoExportDialog.module.scss'
import { useProject } from '@hooks/useProject'
import { useVideoRecordingProgress } from '@services/engine/MediaIO'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { useTranslations } from 'use-intl'

function VideoExportDialog() {
  const t = useTranslations()
  const videoRecordingProgress = useVideoRecordingProgress()
  const showVideoExportDialog = useSceneState('showVideoExportDialog')

  const { cancelVideoRecording, videoRecordingComplete } = useSceneActions()

  const { name } = useProject()

  useEffect(() => {
    if (videoRecordingProgress === 1) {
      videoRecordingComplete()
    }
  }, [videoRecordingProgress])

  return (
    <DialogBase className={styles.root} underlay open={showVideoExportDialog}>
      <div className={styles.dialog}>
        <div className={styles.progress}>
          <ProgressBar
            className={styles.fullwidth}
            size="l"
            label={t('studio:downloadMenu:mp4:downloading', {
              name: `${name}.mp4`
            })}
            progress={videoRecordingProgress * 100}
          />
        </div>

        <div className={styles.button}>
          <Button slot="button" onClick={() => cancelVideoRecording()}>
            {t('studio:downloadMenu:mp4:cancel')}
          </Button>
        </div>
      </div>
    </DialogBase>
  )
}

export default VideoExportDialog
