import React, { FC } from 'react'
import { useSceneActions } from '@hooks/useScene'
import { ActionButton, Icon, OverlayTrigger, Tooltip } from 'ui'
import TransferMaterialIcon from '/public/temp-icon-material-transfer.svg'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import { useTranslations } from 'use-intl'

const TransferMaterial: FC = () => {
  const t = useTranslations()
  const size = useMediumMinimumSizePreference()

  const { transferMaterials } = useSceneActions()

  return (
    <OverlayTrigger placement="top" offset={0}>
      <ActionButton
        size={size}
        quiet
        slot="trigger"
        onClick={() => {
          transferMaterials()
          document.getElementById('canvas')?.focus()
        }}>
        <Icon slot="icon">
          <TransferMaterialIcon />
        </Icon>
      </ActionButton>
      <Tooltip slot="hover-content">
        {t('studio:tooltips:transferMaterial')}
      </Tooltip>
    </OverlayTrigger>
  )
}

export default TransferMaterial
