import React, { FC, useEffect, useState } from 'react'
import cn from 'classnames'
import { Button, Radio, RadioGroup, Popover, OverlayTrigger, Toast } from 'ui'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import DialogFormSectionHeader from '@components/base/Dialog/DialogFormSectionHeader'
import styles from '@styles/components/DownloadExportDialog.module.scss'
import JSONExportForm from './JSONExportForm'
import SVGExportForm from './SVGExportForm'
import VideoExportForm from './VideoExportForm'
import MeshExportForm from './MeshExportForm'
import { useFlags } from 'launchdarkly-react-client-sdk'

import ImageExportForm from './ImageExportForm'
import ImageExportFormLegacyAPI from './ImageExportFormLegacyAPI'
import {
  useIsProjectViewOnly,
  useMediumMinimumSizePreference,
  useProjectActions,
  useProjectState
} from '@hooks/useProject'
import { MediaIO } from '@services/engine/MediaIO'
import { HTMLElementIdsForTracking } from '@constants/htmlElementIdsForTracking'
import { useTranslations } from 'use-intl'

type Format = 'png' | 'jpg' | 'bmp' | 'svg' | 'json' | 'mp4' | 'obj'

const TriggerButtonId = HTMLElementIdsForTracking.studioAppBarDownloadButton

const frameEnabledRequired = (format: Format) => {
  return (['png', 'jpg', 'mp4', 'svg'] as Format[]).some(f => f === format)
}

const DownloadExportDialog: FC = () => {
  const t = useTranslations()
  const flags = useFlags()
  const frameEnabled = useSceneState('frameEnabled')

  const captureStatus = useSceneState('captureStatus')
  const canvasAnimationStartedForRecording = useSceneState(
    'canvasAnimationStartedForRecording'
  )

  const { setCaptureStatus, setPropertyState: _setPropertyState } =
    useSceneActions()

  const projectName = useProjectState('name')
  const size = useMediumMinimumSizePreference()
  const isProjectViewOnly = useIsProjectViewOnly()
  const { setIsDownloadExportDialogOpen } = useProjectActions()

  const setPropertyState = React.useCallback(_setPropertyState, [])

  const [format, setFormat] = useState<Format>('png')

  const [splitFaceEnabled, setSplitFaceEnabled] = useState(false)

  const bmpEnabled = flags['base-tf-ui-export-big']
  const imgExportLegacyEnabled = flags['base-tf-ui-export-img-legacy-api']

  const { canRecordVideos } = MediaIO

  const videoExportEnabled =
    flags['base-tf-ui-export-video'] && canRecordVideos('avc')

  function handleFormatChange(e: any) {
    const selected = e.target?.selected
    if (!selected) return

    setFormat(selected)

    const isFrameEnabledRequired = frameEnabledRequired(selected)

    if (isFrameEnabledRequired && !frameEnabled) {
      setPropertyState({
        key: 'frameEnabled',
        value: true
      })
      return
    }

    // Reset frameEnabled state to original value before the picker is shown for formats that doesn't require it to be on
    if (
      !isFrameEnabledRequired &&
      frameEnabled !== frameEnabledValOnDisplayed
    ) {
      setPropertyState({
        key: 'frameEnabled',
        value: frameEnabledValOnDisplayed
      })
      return
    }
  }

  const [frameEnabledValOnDisplayed, setFrameEnabledValOnDisplayed] =
    useState(frameEnabled)

  const { status, format: captureFormat } = captureStatus

  useEffect(() => {
    if (status === 'completed') {
      setTimeout(() => {
        setCaptureStatus({ status: 'idle', format: captureFormat })
      }, 1500)
    }
  }, [captureStatus])

  function onPopoverOpened(e: any) {
    setIsDownloadExportDialogOpen(true)

    // Check if close was emitted from an element inside the popover
    if (e.target.id !== TriggerButtonId) return

    setCaptureStatus({ status: 'idle', format: captureFormat })
    setFrameEnabledValOnDisplayed(frameEnabled)

    if (frameEnabledRequired(format) && !frameEnabled) {
      setPropertyState({
        key: 'frameEnabled',
        value: true
      })
    }
  }

  function onPopoverClosed(e: any) {
    setIsDownloadExportDialogOpen(false)

    // Check if close was emitted from an element inside the popover
    if (e.target.id !== TriggerButtonId) return

    if (frameEnabled !== frameEnabledValOnDisplayed) {
      setPropertyState({
        key: 'frameEnabled',
        value: frameEnabledValOnDisplayed
      })
    }
  }

  return (
    <>
      <OverlayTrigger
        placement="bottom-end"
        offset={10}
        spOpened={onPopoverOpened}
        spClosed={onPopoverClosed}>
        <Button
          disabled={isProjectViewOnly}
          slot="trigger"
          variant="secondary"
          id={TriggerButtonId}
          size={size}>
          {t('studio:appBar:downloadButton')}
        </Button>
        <Popover
          className={cn(
            canvasAnimationStartedForRecording
              ? styles.hidden
              : styles['popover'],
            { [styles['popover-large']]: size === 'l' }
          )}
          slot="click-content"
          placement="bottom">
          <DialogFormSectionHeader>
            {t('studio:downloadMenu:header')}
          </DialogFormSectionHeader>
          <RadioGroup horizontal selected={format} change={handleFormatChange}>
            {flags['base-tf-ui-export-dialog-image'] && (
              <>
                <Radio size={size} value="png">
                  {t('studio:downloadMenu:png')}
                </Radio>
                <Radio size={size} value="jpg">
                  {t('studio:downloadMenu:jpg')}
                </Radio>
              </>
            )}
            {imgExportLegacyEnabled && (
              <>
                {bmpEnabled && (
                  <Radio size={size} value="bmp">
                    {t('studio:downloadMenu:bmp')}
                  </Radio>
                )}
              </>
            )}

            {flags['base-tf-ui-export-dialog-vector'] && (
              <Radio size={size} value="svg">
                {t('studio:downloadMenu:svg')}
              </Radio>
            )}
            {videoExportEnabled && (
              <Radio size={size} value="mp4">
                {t('studio:downloadMenu:mp4')}
              </Radio>
            )}
            {flags['base-tf-ui-export-dialog-json'] && (
              <Radio size={size} value="json">
                {t('studio:downloadMenu:json')}
              </Radio>
            )}
            {flags['base-pf-ui-export-dialog-mesh-object'] && (
              <Radio size={size} value="obj">
                {t('studio:downloadMenu:meshObject')}
              </Radio>
            )}
          </RadioGroup>

          {format === 'mp4' && (
            <VideoExportForm projectName={projectName as string} />
          )}

          {imgExportLegacyEnabled ? (
            <>
              {format === 'jpg' || format === 'png' || format === 'bmp' ? (
                <ImageExportFormLegacyAPI format={format} />
              ) : null}
            </>
          ) : (
            <>
              {format === 'jpg' || format === 'png' ? (
                <ImageExportForm format={format} />
              ) : null}
            </>
          )}

          {format === 'svg' && (
            <SVGExportForm
              splitFaceEnabled={splitFaceEnabled}
              setSplitFaceEnabled={setSplitFaceEnabled}
            />
          )}

          {format === 'json' && flags['base-tf-ui-export-dialog-json'] && (
            <JSONExportForm />
          )}

          {format === 'obj' &&
            flags['base-pf-ui-export-dialog-mesh-object'] && <MeshExportForm />}
        </Popover>
      </OverlayTrigger>

      <Toast
        className={styles['toast']}
        open={
          !(captureFormat === 'reference' || captureFormat === 'screenshot') &&
          status === 'completed'
        }
        variant="info">
        {captureFormat ? captureFormat.toUpperCase() : ''}{' '}
        {t('studio:downloadMenu:toastMessage')}
      </Toast>
    </>
  )
}

export default DownloadExportDialog
