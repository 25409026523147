import React, { useEffect, useRef } from 'react'
import { UNAV_ELEMENT_ID } from '@services/universalNav/config'
import { useAuthState } from '@hooks/useAuth'
import { useDispatch } from 'react-redux'
import { loadUniversalNav } from '@store/middleware/universalNav/UniversalNavSaga'
import { UniversalNavContext } from '@services/universalNav/types'

interface UniversalNavContainerProps {
  context: UniversalNavContext
}

const UniversalNavContainer = ({ context }: UniversalNavContainerProps) => {
  const authStatus = useAuthState('status')
  const localUser = useAuthState('localUser')
  const dispatch = useDispatch()
  const unavTarget = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (authStatus === 'AUTHENTICATED' && localUser && unavTarget?.current) {
      dispatch(loadUniversalNav(context))
    }
  }, [authStatus, localUser])

  return <div ref={unavTarget} id={UNAV_ELEMENT_ID} />
}

export default UniversalNavContainer
