import React, { FC } from 'react'
import { Icon, OverlayTrigger, Tooltip } from 'ui'
import { ElementSize } from '@store/slices/projectSlice'
import Badge from '@components/badge/Badge'
import VisibilityIcon from '/public/s2_icon_visibility.svg'
import { useTranslations } from 'use-intl'

interface Props {
  size?: ElementSize
}

const ViewOnlyBadge: FC<Props> = ({ size = 'm' }) => {
  const t = useTranslations()
  return (
    <OverlayTrigger offset={0} placement="bottom">
      <Badge slot="trigger" size={size} fill="subtle" variant="notice">
        <Icon size={size}>
          <VisibilityIcon />
        </Icon>
        {t('studio:viewOnly')}
      </Badge>

      <Tooltip slot="hover-content">{t('studio:viewOnly:tooltip')}</Tooltip>
    </OverlayTrigger>
  )
}

export default ViewOnlyBadge
