import { EventEmitter } from 'events'
import {
  APP_VERSION,
  UNAV_ACCOUNT_MENU_TEST_ID,
  UNAV_ELEMENT_ID,
  UNAV_URL,
  UNAV_VERSION
} from './config'
import {
  UniversalNavConfig,
  UniversalNavChild,
  EventPayload,
  MenuMessageEventDetail,
  UniversalNavUserConfig,
  UniversalNavPromise,
  UniversalNavContext
} from './types'
import { ColorKey } from '@store/slices/projectSlice'
import { LocaleString } from '@concerns/i18n/types'

/**
 *
 * Service to load Universal Nav component
 * https://wiki.corp.adobe.com/display/CCHome/Universal+Nav
 *
 */

export class UniversalNav {
  public EventChannel: EventEmitter = new EventEmitter()
  private currentNavPromise: UniversalNavPromise = null
  private context: UniversalNavContext = 'studio'
  private userConfig!: UniversalNavUserConfig
  private initialAccountMenuAttributes = {
    'enable-buy-credits': true,
    'enable-credits-request-access': true
  }

  public configureUser(userConfig: UniversalNavUserConfig): void {
    this.userConfig = userConfig
  }

  private async handleUnavPromise(unavPromise: Promise<any>): Promise<void> {
    this.currentNavPromise = unavPromise
    await unavPromise
    this.currentNavPromise = null
  }

  public async initialize(context: UniversalNavContext) {
    this.context = context

    const config = this.createUniversalNavConfig()

    if (window.UniversalNav) {
      await this.handleUnavPromise(window.UniversalNav.reload(config))
      return
    }

    await Promise.all([this.fetchJS(), this.fetchCss()]).then(async () => {
      if (window.UniversalNav) {
        await this.handleUnavPromise(window.UniversalNav(config))
      }
    })
  }

  public async changeTheme(theme: ColorKey) {
    if (!window.UniversalNav) return
    if (this.currentNavPromise)
      await this.handleUnavPromise(this.currentNavPromise)

    this.userConfig.themeColor = theme
    window.UniversalNav.changeTheme(this.getThemeColor())
  }

  public async changeLocale(newLocale: LocaleString) {
    if (!window.UniversalNav) return
    if (this.currentNavPromise)
      await this.handleUnavPromise(this.currentNavPromise)

    this.userConfig.userLocale = newLocale
    const newConfig = this.createUniversalNavConfig()
    await this.handleUnavPromise(window.UniversalNav.reload(newConfig))
  }

  private getThemeColor() {
    return this.context === 'community' ? 'light' : this.userConfig.themeColor
  }

  private createUniversalNavConfig(): UniversalNavConfig {
    const targetEl = document.getElementById(UNAV_ELEMENT_ID)

    if (!targetEl) {
      throw new Error(`Element with id ${UNAV_ELEMENT_ID} not found`)
    }

    if (
      !this.userConfig?.accessToken ||
      !this.userConfig?.userProfile?.userId
    ) {
      throw new Error(
        `Missing user configuration. Did you forget to call configureUser()?`
      )
    }

    const { accessToken, userProfile, userLocale } = this.userConfig

    return {
      accountType: userProfile.accountType,
      target: targetEl,
      env:
        process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT === 'prd'
          ? 'prod'
          : 'stage',
      theme: this.getThemeColor(),
      locale: userLocale.replace('-', '_'),
      children: this.createUniversalNavItems(),
      accessToken: accessToken,
      imsClientId: process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID!,
      userId: userProfile.userId,
      authId: userProfile.userId,
      email: userProfile.email,
      isSectionDividerRequired: false,
      analyticsContext: {
        consumer: {
          name: 'ProjectNeo',
          version: APP_VERSION,
          platform: 'Web',
          device: 'macOS',
          os_version: 'Catalina'
        },
        event: {
          visitor_guid: ''
        }
      }
    }
  }

  private createUniversalNavItems(): Array<UniversalNavChild> {
    const { accessToken, userProfile } = this.userConfig
    return [
      {
        name: 'app-switcher',
        attributes: {
          crossOrigin: 'anonymous'
        }
      },
      {
        name: 'profile',
        attributes: {
          crossOrigin: 'anonymous',
          accountMenuTriggerAttributes: {
            'data-testid': UNAV_ACCOUNT_MENU_TEST_ID,
            ...this.initialAccountMenuAttributes
          },
          callbacks: {
            onSignIn: () => undefined
          },
          isSignUpRequired: false,
          componentLoaderConfig: {
            config: {
              preloadAccountMenu: true,
              getAccessToken: () => Promise.resolve(accessToken),
              getProfile: () => Promise.resolve(userProfile),
              imsClientId: process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID,
              enableLocalSection: true,
              enableProfileSwitcher: false,
              userId: userProfile.userId,
              miniAppContext: {
                colorScheme: this.getThemeColor(),
                logger: {
                  trace: () => null,
                  debug: () => null,
                  info: () => null,
                  warn: () => null,
                  error: () => null
                }
              }
            }
          },
          messageEventListener: async (event: {
            detail: MenuMessageEventDetail
          }) => {
            const { payload } = event.detail
            if (!payload.data) return

            if (payload.data?.key && payload.data.value?.eventName) {
              this.EventChannel.emit('event', {
                type: 'account-menu',
                payload: { event: payload.data.value.eventName }
              } as EventPayload)
            }

            if (payload.data['react-mini-app-credits-counter-widget']) {
              this.EventChannel.emit('event', {
                type: 'account-menu',
                payload: {
                  event:
                    payload.data['react-mini-app-credits-counter-widget'].action
                }
              } as EventPayload)
            }
          }
        }
      }
    ]
  }

  private fetchJS() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.id = 'neo_unav_js'
      script.src = UNAV_URL.js(UNAV_VERSION)
      script.async = true
      script.onload = resolve
      script.onerror = reject
      document.body.appendChild(script)
    })
  }

  private fetchCss() {
    return new Promise((resolve, reject) => {
      const css = document.createElement('link')
      css.id = 'neo_unav_css'
      css.rel = 'stylesheet'
      css.type = 'text/css'
      css.href = UNAV_URL.css(UNAV_VERSION)
      // @ts-expect-error - async exists
      css.async = true
      css.onload = resolve
      css.onerror = reject
      const head = document.getElementsByTagName('head')[0]
      head.appendChild(css)
    })
  }
}
