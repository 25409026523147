import React, { FC } from 'react'
import Avatar from '@components/avatar/Avatar'
import styles from '@styles/components/ChatPanel.module.scss'
import cn from 'classnames'
import { useFormatter } from 'use-intl'

interface ChatProps {
  userAvatar: string
  userColor: [number, number, number]
  userName: string
  message: string
  timestamp: number
  isLocalUser?: boolean
}

const rgbColor = (r: number, g: number, b: number) => `rgb(${r}, ${g}, ${b})`

const Chat: FC<ChatProps> = ({
  userAvatar,
  userColor,
  userName,
  message,
  timestamp,
  isLocalUser
}) => {
  const formatter = useFormatter()
  const messageDate = formatter.relativeTime(timestamp)
  const messageClass = isLocalUser
    ? [styles['chat__message'], styles['chat__message--local-user']]
    : [styles['chat__message'], styles['chat__message--remote-user']]

  return (
    <div className={styles['chat']}>
      <div className={styles['chat__message-container']}>
        <div className={styles['chat__header']}>
          <Avatar
            src={userAvatar}
            alt={userName}
            size="S"
            borderColor={rgbColor(...userColor)}
          />
          <p>{userName}</p>
        </div>
        <div className={cn(messageClass)}>
          <p>{message}</p>
        </div>
        <p className={styles['chat__timestamp']}>{messageDate}</p>
      </div>
    </div>
  )
}

export default Chat
