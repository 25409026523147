import { useProjectState } from '@hooks/useProject'
import { usePropertyBounds } from '@hooks/usePropertyBounds'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { EngineCommitChange, PropertyPanelPath } from '@services/engine/types'
import { PropertyPayload, SceneState } from '@store/slices/sceneSlice'
import { FC, useState } from 'react'
import { NumberField, Slider } from 'ui'
import styles from '@styles/components/BoundedPropertySlider.module.scss'

// linear bijection from a closed interval [a, b] onto another closed interval [c, d]
export function closedIntervalOntoClosedInterval(
  a: number,
  b: number,
  c: number,
  d: number,
  x: number
) {
  return b === a ? b : ((d - c) / (b - a)) * x + (c * b - a * d) / (b - a)
}

type UnitFormatProps = {
  disabled?: boolean
  ariaLabel?: string
  label: string
  valueKey: keyof SceneState
  propertyPath: PropertyPanelPath
  format: 'unit'
  unitType?: 'degree'
  minPercentage?: undefined
  maxPercentage?: undefined
  step?: number
}

type PercentageFormatProps = {
  disabled?: boolean
  ariaLabel?: string
  label: string
  valueKey: keyof SceneState
  propertyPath: PropertyPanelPath
  format: 'percentage'
  unitType?: undefined
  minPercentage: number
  maxPercentage: number
  step?: number
}

type CommonProps = {
  disabled?: boolean
  ariaLabel?: string
  label: string
  valueKey: keyof SceneState
  propertyPath: PropertyPanelPath
  step?: number
  enableUpperboundOverride?: boolean
}

type Props = CommonProps & (UnitFormatProps | PercentageFormatProps)

export const BoundedPropertySlider: FC<Props> = ({
  disabled,
  ariaLabel,
  label,
  minPercentage,
  maxPercentage,
  valueKey,
  propertyPath,
  format = 'unit',
  unitType,
  step = 0.001,
  enableUpperboundOverride = false
}) => {
  const sizePreference = useProjectState('sizePreference')
  const { setPropertyState } = useSceneActions()
  const propertyValue = useSceneState(valueKey) as number
  const { softMin, softMax, hardMin, hardMax } = usePropertyBounds(
    propertyPath
  ) as { softMin: number; softMax: number; hardMin: number; hardMax: number }

  const [prevSoftMax, setPrevSoftMax] = useState<number>(softMax)
  const [adjustedUpperBound, setAdjustedUpperBound] = useState<number>(softMax)
  const [adjustedMaxPercentage, setAdjustedMaxPercentage] = useState<number>(
    maxPercentage as number
  )

  // handle the scenario where the soft max changes
  if (areBoundsDefined() && prevSoftMax !== softMax) {
    handleOnSoftMaxChange(softMax)
  }

  let currentPercentage = 0

  if (
    format === 'percentage' &&
    areBoundsDefined() &&
    typeof adjustedUpperBound !== 'undefined'
  ) {
    currentPercentage = closedIntervalOntoClosedInterval(
      softMin,
      adjustedUpperBound,
      minPercentage!,
      adjustedMaxPercentage,
      propertyValue
    )
  }

  function handleOnSoftMaxChange(newSoftMax: number) {
    // This equals softMax if enableOverride is false
    const max = Math.max(propertyValue, Math.min(newSoftMax, hardMax))
    setAdjustedUpperBound(max)
    setPrevSoftMax(newSoftMax)
  }

  function areBoundsDefined() {
    return (
      typeof softMin !== 'undefined' &&
      typeof softMax !== 'undefined' &&
      typeof hardMin !== 'undefined' &&
      typeof hardMax !== 'undefined'
    )
  }

  function handleSliderInteractionForPercentage(
    e: any,
    commit?: EngineCommitChange
  ) {
    const lowerBound = Math.max(softMin, hardMin)

    let value = Math.max(
      (e as unknown as { target: { value: number } }).target.value * 100,
      minPercentage as number
    )

    if (!areBoundsDefined()) return

    currentPercentage = value
    // Converts percentage to value for engine
    value = closedIntervalOntoClosedInterval(
      minPercentage!,
      adjustedMaxPercentage,
      lowerBound,
      adjustedUpperBound,
      value
    )

    setPropertyState({
      key: valueKey,
      value,
      commit
    } as PropertyPayload)
  }

  function handleSliderInteractionForUnit(e: any, commit?: EngineCommitChange) {
    let value = (e as unknown as { target: { value: number } }).target.value
    const lowerBound = Math.max(softMin, hardMin)

    if (!areBoundsDefined()) return
    value = Math.max(value, lowerBound)
    value = Math.min(value, adjustedUpperBound)

    setPropertyState({
      key: valueKey,
      value,
      commit
    } as PropertyPayload)
  }

  // When enableUpperboundOverride is true, we disable the numberfield that comes with the spectrum slider and we instead use our own numberfield. This is so that we have more control over the values in the number field. When we do use our own numberfield, we need to add styling to it so that it looks the same as the numberfield that comes with the spectrum slider.
  function getCSSStyles(cssClassname: string) {
    return enableUpperboundOverride ? styles[cssClassname] : undefined
  }

  function validateInput(v: any) {
    return !Number.isNaN(v)
  }

  function handleOnNumberFieldChangeForPercentage(e: any) {
    let value = (e as unknown as { target: { value: number } }).target.value
    if (!validateInput(value)) return

    const percentage = Math.max(value * 100, minPercentage as number)

    const valueForEngine =
      (adjustedUpperBound * (percentage * maxPercentage!)) /
      (100 * adjustedMaxPercentage)

    if (percentage > adjustedMaxPercentage) {
      setAdjustedMaxPercentage(percentage)
      setAdjustedUpperBound(valueForEngine)
    }

    setPropertyState({
      key: valueKey,
      value: valueForEngine
    } as PropertyPayload)
  }

  function handleOnNumberFieldChangeForUnit(e: any) {
    let value = (e as unknown as { target: { value: number } }).target.value
    if (!validateInput(value)) return

    const lowerBound = Math.max(softMin, hardMin)

    value = Math.max(
      (e as unknown as { target: { value: number } }).target.value,
      lowerBound
    )

    if (value > adjustedUpperBound) {
      setAdjustedUpperBound(value)
    } else {
      value = Math.min(value, adjustedUpperBound)
    }

    setPropertyState({
      key: valueKey,
      value
    } as PropertyPayload)
  }

  function handleOnDoubleClick() {
    if (adjustedUpperBound > softMax) {
      setPropertyState({
        key: valueKey,
        value: softMax
      } as PropertyPayload)

      setAdjustedUpperBound(softMax)
      if (format === 'percentage') setAdjustedMaxPercentage(maxPercentage!)
    }
  }

  const intlFormatOptionsForPercentage = `{
            "style": "percent",
            "maximumFractionDigits": "3",
            "trailingZeroDisplay": "stripIfInteger"
        }`

  const intlFormatOptionsForUnit = `{
          "style": "unit",
          "unit": "${unitType ?? ''}",
          "unitDisplay": "narrow",
          "maximumFractionDigits": "3",
          "trailingZeroDisplay": "stripIfInteger"
      }`

  if (format === 'unit') {
    return (
      <div className={getCSSStyles('container')}>
        <Slider
          className={getCSSStyles('slider')}
          disabled={disabled}
          size={sizePreference}
          label={label}
          aria-label={ariaLabel}
          variant="filled"
          value={propertyValue}
          min={softMin}
          max={adjustedUpperBound}
          step={step}
          editable={!enableUpperboundOverride}
          onInput={e => handleSliderInteractionForUnit(e)}
          onMouseDown={e =>
            handleSliderInteractionForUnit(e, EngineCommitChange.BEGIN_COMMIT)
          }
          onMouseUp={e =>
            handleSliderInteractionForUnit(e, EngineCommitChange.END_COMMIT)
          }
          onDoubleClick={handleOnDoubleClick}
          hideStepper
          labelVisibility="text"
          format-options={intlFormatOptionsForUnit}
        />
        {enableUpperboundOverride && (
          <NumberField
            className={getCSSStyles('number-field')}
            hideStepper
            size={sizePreference}
            min={0}
            step={step}
            value={propertyValue}
            aria-label={ariaLabel}
            onChange={handleOnNumberFieldChangeForUnit}
            format-options={intlFormatOptionsForUnit}
          />
        )}
      </div>
    )
  } else if (format === 'percentage') {
    return (
      <div className={getCSSStyles('container')}>
        <Slider
          className={getCSSStyles('slider')}
          disabled={disabled}
          size={sizePreference}
          label={label}
          aria-label={ariaLabel}
          variant="filled"
          value={currentPercentage! / 100}
          min={minPercentage! / 100}
          max={adjustedMaxPercentage / 100}
          step={step}
          editable={!enableUpperboundOverride}
          onInput={e => handleSliderInteractionForPercentage(e)}
          onMouseDown={e =>
            handleSliderInteractionForPercentage(
              e,
              EngineCommitChange.BEGIN_COMMIT
            )
          }
          onMouseUp={e =>
            handleSliderInteractionForPercentage(
              e,
              EngineCommitChange.END_COMMIT
            )
          }
          onDoubleClick={handleOnDoubleClick}
          hideStepper
          labelVisibility="text"
          format-options={intlFormatOptionsForPercentage}
        />
        {enableUpperboundOverride && (
          <NumberField
            disabled={disabled}
            className={getCSSStyles('number-field')}
            hideStepper
            size={sizePreference}
            min={0}
            step={step}
            value={currentPercentage! / 100}
            aria-label={ariaLabel}
            onChange={handleOnNumberFieldChangeForPercentage}
            format-options={intlFormatOptionsForPercentage}
          />
        )}
      </div>
    )
  }

  return <></>
}
