import { bindActionCreators } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { accessProfile } from '@store/slices/accessProfileSlice'
import { RootState } from '@store/store'

export function useAccessProfileActions() {
  const dispatch = useDispatch()
  const boundedActions = bindActionCreators(accessProfile.actions, dispatch)
  return boundedActions
}

export function useAccessProfileState<
  T extends keyof RootState['accessProfile']
>(key: T): RootState['accessProfile'][T] {
  const state = useSelector((state: RootState) => state.accessProfile[key])
  return state
}
