import {
  EngineData,
  MaterialType,
  ScenePrimType,
  PrimitiveDimensionType,
  PrimitiveSymmetryChecked,
  EngineBackground,
  EngineCamera,
  EngineSelectedSceneNode,
  EngineRepeatType,
  EngineMode,
  MaterialProperties,
  ScenePrimByDimensionType,
  SceneParamGeneric
} from '@services/engine/types'
import { setPropertiesState } from '@store/slices/sceneSlice'
import { put, select } from 'redux-saga/effects'
import Context from '../context'

export default function* setSceneState(dataEvent: EngineData) {
  if (dataEvent.status === EngineSelectedSceneNode.NONE) {
    yield put(
      setPropertiesState({
        primitiveType: null,
        selectedSceneNode: EngineSelectedSceneNode.NONE
      })
    )
  }
  if (
    dataEvent.status === EngineSelectedSceneNode.SHAPE &&
    typeof dataEvent.sceneData !== 'string'
  ) {
    const { sceneData } = dataEvent
    const { address, blend, pos, rot, sca, prim, material, symmetry, repeat } =
      sceneData
    const addressPath = address.split('/')
    const [repeatType] = repeat

    yield put(
      setPropertiesState({
        address,
        blendType: blend[0],
        blendAmount: blend[1],
        materialType: material[1],
        ...(material[1] === MaterialType.P && {
          materialColor: Context.Engine?.rGBToHex(
            material[2],
            material[3],
            material[4]
          ),
          materialRoughness: material[5],
          materialMetalness: material[6],
          materialReflective: material[7] as unknown as boolean
        }),
        ...((material[1] === MaterialType.E ||
          material[1] === MaterialType.PIXEL) && {
          materialEColorTop: Context.Engine?.rGBToHex(
            material[2],
            material[3],
            material[4]
          ),
          materialEColorLig: Context.Engine?.rGBToHex(
            material[5],
            material[6],
            material[7]
          ),
          materialEColorSha: Context.Engine?.rGBToHex(
            material[8],
            material[9],
            material[10]
          ),
          materialESpecularSize: material[11],
          materialESpecularIntensity: material[12]
        }),
        ...(material[1] === MaterialType.I && {
          materialIColor: Context.Engine?.rGBToHex(
            material[2],
            material[3],
            material[4]
          ),
          materialIEmissiveColor: Context.Engine?.rGBToHex(
            material[5],
            material[6],
            material[7]
          ),
          materialIStrokeSize: material[8],
          materialIHighlightIntensity: material[9],
          materialIStrokeIntensity: material[10],
          materialIColorVarIntensity: material[11],
          materialIScaleX: material[12],
          materialIScaleY: material[13],
          materialIAngle: material[14]
        }),
        repeatType,
        ...(repeatType === EngineRepeatType.XYZ && {
          repeatX: repeat[1],
          repeatY: repeat[2],
          repeatZ: repeat[3],
          repeatDistanceX: repeat[4],
          repeatDistanceY: repeat[5],
          repeatDistanceZ: repeat[6]
        }),
        ...(repeatType === EngineRepeatType.ANGLE && {
          repeatAngle: repeat[2],
          repeatAngleDirection: repeat[1],
          repeatDistance: repeat[3]
        }),
        primitiveType: dataEvent.sceneData.prim.type,
        elementName: addressPath[addressPath.length - 1],
        selectedSceneNode: EngineSelectedSceneNode.SHAPE,
        ...(prim.type === ScenePrimType.SOFT_BOX && {
          primitiveRadial0: prim.params[0],
          primitiveRadial1: prim.params[1],
          primitiveRadial2: prim.params[2],
          primitiveRadial3: prim.params[3],
          primitiveScaleCorners: prim.settings.scaleCorners
        }),
        ...(prim.type === ScenePrimType.STAR && {
          primitiveStarPoints: prim.params[0],
          primitiveStarAngle: prim.params[1],
          primitiveStarCorners: prim.params[2]
        }),
        ...(prim.type === ScenePrimType.HORSESHOE && {
          primitiveHorseshoeAngle: prim.params[0],
          primitiveHorseshoeRadius: prim.params[1],
          primitiveHorseshoeWidth: prim.params[2],
          primitiveHorseshoeLength: prim.params[3],
          primitiveHorseshoeThickness: prim.params[4],
          primitiveHorseshoeCorner: prim.params[5]
        }),
        ...(prim.type === ScenePrimType.TRIANGLE && {
          primitiveTriangleVertex: prim.params[0]
        }),
        primitiveHole: prim.common[0],
        primitiveShell: prim.common[1],
        primitiveInflate: prim.common[2],
        primitiveDimensionType: prim.dimension[0],
        ...(prim.dimension[0] === PrimitiveDimensionType.EXTRUDE && {
          primitiveCornerOne: prim.dimension[2],
          primitiveCornerTwo: prim.dimension[3]
        }),
        ...(prim.dimension[0] === PrimitiveDimensionType.REVOLVE && {
          primitiveDistance: prim.dimension[1],
          primitiveRotation: prim.dimension[2]
        }),
        symmetryX: symmetry[0]
          ? PrimitiveSymmetryChecked.CHECKED
          : PrimitiveSymmetryChecked.UNCHECKED,
        symmetryY: symmetry[1]
          ? PrimitiveSymmetryChecked.CHECKED
          : PrimitiveSymmetryChecked.UNCHECKED,
        symmetryZ: symmetry[2]
          ? PrimitiveSymmetryChecked.CHECKED
          : PrimitiveSymmetryChecked.UNCHECKED,
        transformPositionX: pos[0],
        transformPositionY: pos[1],
        transformPositionZ: pos[2],
        transformRotationX: rot[0],
        transformRotationY: rot[1],
        transformRotationZ: rot[2],
        ...(prim.type === ScenePrimType.HORSESHOE && {
          transformScaleX: sca[0],
          transformScaleY: sca[1],
          transformScaleZ: sca[2]
        })
      })
    )
  }
  if (
    dataEvent.status === EngineSelectedSceneNode.GROUP &&
    typeof dataEvent.sceneData !== 'string'
  ) {
    const { sceneData } = dataEvent
    const { address, blend, pos, rot, sca, symmetry } = sceneData
    const addressPath = address.split('/')
    yield put(
      setPropertiesState({
        address,
        blendType: blend[0],
        blendAmount: blend[1],
        repeatType: dataEvent.sceneData.repeat[0],
        elementName: addressPath[addressPath.length - 1],
        primitiveType: null,
        selectedSceneNode: EngineSelectedSceneNode.GROUP,
        symmetryX: symmetry[0]
          ? PrimitiveSymmetryChecked.CHECKED
          : PrimitiveSymmetryChecked.UNCHECKED,
        symmetryY: symmetry[1]
          ? PrimitiveSymmetryChecked.CHECKED
          : PrimitiveSymmetryChecked.UNCHECKED,
        symmetryZ: symmetry[2]
          ? PrimitiveSymmetryChecked.CHECKED
          : PrimitiveSymmetryChecked.UNCHECKED,
        transformPositionX: pos[0],
        transformPositionY: pos[1],
        transformPositionZ: pos[2],
        transformRotationX: rot[0],
        transformRotationY: rot[1],
        transformRotationZ: rot[2],
        transformScaleX: sca[0],
        transformScaleY: sca[1],
        transformScaleZ: sca[2]
      })
    )
  }
  if (dataEvent.status === EngineSelectedSceneNode.PROJECT) {
    const { background, camera, floor, frame, lighting, style } =
      dataEvent.sceneData
    const [backgroundType, r1, g1, b1, r2, g2, b2] = background
    const [
      cameraType,
      cameraValue,
      cameraFocalPlane,
      cameraAperture,
      cameraDistortion
    ] = camera
    const { occlusion, lights } = lighting
    const [frameEnabled, frameType, w, h] = frame
    yield put(
      setPropertiesState({
        backgroundType,
        backgroundColorA: Context.Engine?.rGBToHex(r1, g1, b1),
        ...(backgroundType !== EngineBackground.SOLID && {
          backgroundColorB: Context.Engine?.rGBToHex(r2, g2, b2)
        }),
        cameraType,
        ...(cameraType === EngineCamera.PERSPECTIVE && {
          cameraFocalLength: cameraValue
        }),
        ...(cameraType === EngineCamera.PERSPECTIVE && {
          cameraFocalPlane: cameraValue
        }),
        cameraFocalPlane,
        cameraAperture,
        cameraDistortion,
        floorEnabled: floor.enabled,
        floorHeight: floor.height,
        frameEnabled,
        frameType,
        frameSize: { w, h },
        lightAngle1: lights[0].position,
        lightAngle2: lights[0].altitude,
        lightOcclusionDistance: occlusion.distance,
        mode: style.id,
        ...(style.id === EngineMode.PIXEL && {
          modePixelSize: style.pixelsize,
          pixelFilterEnabled: !!style.filter,
          pixelOutlineEnabled: style.outline
        }),
        ...(style.id === EngineMode.OUTLINE && {
          modeExpressiveOutline: style.outline
        }),
        ...((style.id === EngineMode.OUTLINE ||
          style.id === EngineMode.PIXEL) && {
          modeOutlineColor: Context.Engine?.rGBToHex(...style.color)
        }),
        primitiveType: null,
        selectedSceneNode: EngineSelectedSceneNode.PROJECT
      })
    )
  }
  if (dataEvent.status === EngineSelectedSceneNode.MULTI_SELECTED) {
    yield put(
      setPropertiesState({
        primitiveType: null,
        selectedSceneNode: EngineSelectedSceneNode.MULTI_SELECTED
      })
    )
  }
}
