import { NextRouter } from 'next/router'
import { Button, ProgressCircle, Tab, TabPanel, Tabs } from 'ui'
import { CustomLink } from './CustomLink'
import { CommunityAppBarProps } from './CommunityAppBar'
import { useAuth } from '@hooks/useAuth'
import styles from '@styles/components/CommunityAppBar.module.scss'
import { useTranslations } from 'use-intl'
import UniversalNavContainer from '@components/universalNav/UniversalNavContainer'

export const CommunityAppBarUserControls = () => {
  const { promptLogin, isAuthenticationStateLoaded, status } = useAuth()
  const t = useTranslations()

  const loading = !isAuthenticationStateLoaded || status === 'CHECKING_STATUS'
  const authenticated =
    isAuthenticationStateLoaded && status === 'AUTHENTICATED'

  return (
    <>
      {loading && (
        <ProgressCircle style={{ marginRight: 8 }} indeterminate size="s" />
      )}
      {!authenticated && !loading && (
        <Button variant="primary" onClick={() => promptLogin({})}>
          {t('dashboard:appBar:user:login')}
        </Button>
      )}
      {authenticated && <UniversalNavContainer context="community" />}
    </>
  )
}

export const CommunityAppBarRouteSwitcher = ({
  isAuthenticated,
  router,
  vertical
}: Pick<CommunityAppBarProps, 'isAuthenticated'> & {
  router: NextRouter
  vertical: boolean
}) => {
  const t = useTranslations()

  const routeTabs: Array<{ name: string; href: string }> = [
    {
      name: t('dashboard:appBar:navigation:community'),
      href: '/discover'
    }
  ].concat(
    isAuthenticated
      ? {
          name: t('dashboard:appBar:navigation:myProjects'),
          href: '/my-projects'
        }
      : []
  )

  function handleTabChange(event: any) {
    const selectedTabValue = event.target?.selected
    const isValidRouteTab = routeTabs.some(tab => tab.href === selectedTabValue)
    if (isValidRouteTab) {
      router.push(selectedTabValue)
    }
  }

  return (
    <Tabs
      direction={vertical ? 'vertical' : 'horizontal'}
      selected={router.route}
      quiet
      aria-label="Links to community page and your project dashboard"
      change={handleTabChange}>
      {routeTabs.map(item => (
        <Tab
          role="tab"
          key={'route-tab-' + item.href}
          value={item.href}
          className={styles['tab']}>
          <CustomLink key={`community-links-${item.href}`} href={item.href}>
            {item.name}
          </CustomLink>
        </Tab>
      ))}
      {routeTabs.map(item => (
        <TabPanel key={'tab-panel-' + item.href} value={item.href} />
      ))}
    </Tabs>
  )
}
