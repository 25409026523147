import { useEffect } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useProjectActions } from '@hooks/useProject'
import { Size, ElementSize } from '@store/slices/projectSlice'
import { LocalStorageKey } from '@constants/localStorage'

const FifteenInchViewportWidth = 1500

export const useInitializeUIPreference = () => {
  const flags = useFlags()
  const sizeFlag = flags['base-pf-ui-size-preference']
  const { setSizePreference } = useProjectActions()

  useEffect(() => {
    setSizePreferencePreference()
  }, [sizeFlag])

  function setSizePreferencePreference() {
    if (!sizeFlag) {
      return setSizePreference('s')
    }

    const localStoragePreference = localStorage.getItem(
      LocalStorageKey.appPreferenceSize
    )
    const hasPreference = Object.entries(Size).some(
      ([_, val]) => val === localStoragePreference
    )

    if (hasPreference) {
      return setSizePreference(localStoragePreference as ElementSize)
    }

    const isBigScreen = window.innerWidth > FifteenInchViewportWidth
    setSizePreference(isBigScreen ? Size.medium : Size.small)
  }
}
