import { AbstractIntlMessages, useTranslations } from 'use-intl'

export type LocaleMessages = AbstractIntlMessages

export type UseTranslationsHookResult = ReturnType<typeof useTranslations>

export enum LocaleString {
  English = 'en-US',
  Japanese = 'ja-JP',
  German = 'de-DE',
  Korean = 'ko-KR',
  French = 'fr-FR'
}

export interface LocaleContextValue {
  activeLocale: LocaleString
  localeMessages: LocaleMessages | undefined
  changeApplicationLocale: (locale: LocaleString) => void
}
