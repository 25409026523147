import React from 'react'
import { Button, ButtonGroup, Dialog, DialogBase, Link } from 'ui'
import { useRouter } from 'next/router'
import { useHistoryState } from '@hooks/useHistory'
import { useSceneState } from '@hooks/useScene'
import { useTranslations } from 'use-intl'
import { useFlags } from 'launchdarkly-react-client-sdk'

type ErrorDialogProps = {
  isStudioPage?: boolean
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({ isStudioPage = false }) => {
  const t = useTranslations()
  const engineState = isStudioPage
    ? useSceneState('engineState')
    : useHistoryState('engineState')
  const { push, reload } = useRouter()
  const flags = useFlags()

  const showDiscordLink = flags['base-tf-ui-discord-link']

  const errorBody = showDiscordLink
    ? t.rich('studio:crashError:body', {
        hyperlinked: chunks => (
          <Link href={t('link:discord')} target="_blank">
            {chunks}
          </Link>
        )
      })
    : t('studio:crashError:bodyNoLink')

  if (engineState !== 'ERROR') {
    return null
  }

  return (
    <DialogBase underlay open>
      <Dialog size="s">
        <h2 slot="heading">{t('studio:crashError:header')}</h2>
        <p>{errorBody}</p>
        <ButtonGroup slot="button">
          {isStudioPage && (
            <Button onClick={() => push('/my-projects')} variant="secondary">
              {t('studio:crashError:back')}
            </Button>
          )}
          <Button onClick={() => reload()} variant="primary">
            {t('studio:crashError:refresh')}
          </Button>
        </ButtonGroup>
      </Dialog>
    </DialogBase>
  )
}

export default ErrorDialog
