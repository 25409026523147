import { createAction } from '@reduxjs/toolkit'
import { call, select, takeEvery } from 'redux-saga/effects'
import { RootState } from '@store/store'
import AdobeIMS from '@services/auth/IMS'
import TypeQuestWeb from '@services/font/typeQuestWeb'
import Context from '../context'

export const initializeFontService = createAction('Font/initializeFontService')

function* handleInitializeFontService() {
  if (
    !process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID ||
    Context.TypeQuestWeb.isLoaded
  ) {
    return
  }

  const { localUser }: RootState['auth'] = yield select(
    (state: RootState) => state.auth
  )
  if (!localUser?.adobeUserId) return

  const tokenInfo = AdobeIMS.getAccessToken()
  if (!tokenInfo?.token) return

  yield call(
    Context.TypeQuestWeb.load,
    localUser.adobeUserId,
    tokenInfo.token,
    process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID
  )
}

export default function* fontSaga() {
  yield takeEvery(initializeFontService.type, handleInitializeFontService)
}
