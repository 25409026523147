import { LocalStorageKey } from '@constants/localStorage'
import { LocaleMessages, LocaleString } from '@concerns/i18n/types'
import {
  DefaultApplicationLocale,
  SupportedBaseLocales,
  SupportedLocales
} from '@concerns/i18n/constants'

export const getUserLocalePreference = (): LocaleString => {
  return (
    (localStorage.getItem(LocalStorageKey.userLocale) as LocaleString) ||
    getBrowserLocalePreference() ||
    DefaultApplicationLocale
  )
}

export const persistUserLocalePreference = (
  userSelectedLocale: LocaleString
): void => {
  return localStorage.setItem(LocalStorageKey.userLocale, userSelectedLocale)
}

const getBrowserLocalePreference = (): LocaleString | undefined => {
  const browserPreferredLocales = navigator.languages

  for (let i = 0; i < browserPreferredLocales.length; i++) {
    const preferredLocale = browserPreferredLocales[i]

    // ex: Browser preference is 'ja-JP' or 'de-DE'
    if (SupportedLocales.includes(preferredLocale as LocaleString)) {
      return preferredLocale as LocaleString
    }

    // ex: Browser preference is 'fr' or 'de'
    const matchingBaseLocaleIndex = SupportedBaseLocales.findIndex(
      baseLocale => baseLocale === preferredLocale
    )

    if (matchingBaseLocaleIndex !== -1) {
      return SupportedLocales[matchingBaseLocaleIndex]
    }
  }

  return undefined
}

export const importLocaleMessagesFromFile = async (
  locale: LocaleString
): Promise<LocaleMessages> => {
  const json = await import(`./messages/${locale}.json`)
  return json
}

export const setHtmlLang = (locale: LocaleString) => {
  document.documentElement.setAttribute('lang', locale.split('-')[0])
}
