import { LocaleContext } from '@concerns/i18n/components/LocaleProvider'
import { useContext, useEffect, useState } from 'react'
import { Style } from './useStyleReferences'

export const useLocalizedStyleReferenceCategories = () => {
  const { activeLocale } = useContext(LocaleContext)
  const [localizedCategories, setLocalizedCategories] = useState<
    Record<Style['title']['id'], string>
  >({})

  useEffect(() => {
    getLocalizedCategories()
  }, [activeLocale])

  async function getLocalizedCategories() {
    const localizedCategories: any = await fetch(
      `${
        process.env.NEXT_PUBLIC_CLIENT_FIREFLY_ASSET_ENDPOINT
      }/image-style-zeros/v3/locales/${activeLocale || 'en-US'}.json`,
      {
        method: 'GET'
      }
    ).then(r => r.json())

    setLocalizedCategories(localizedCategories)
  }

  return localizedCategories
}
