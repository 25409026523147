import React, { FC } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  ActionButton,
  Button,
  ButtonGroup,
  Icon,
  ProgressCircle,
  Toast
} from 'ui'
import { SpIconClose } from 'workflow-icons'
import cn from 'classnames'
import styles from '@styles/components/FireflyContextualMenu.module.scss'
import {
  useIsEmptyScene,
  useSceneActions,
  useSceneState
} from '@hooks/useScene'
import {
  useMediumMinimumSizePreference,
  useProject,
  useProjectState
} from '@hooks/useProject'
import { useFirefly } from '@hooks/useFirefly'
import FireflyImage from '@components/fireflyPanel/FireflyImage'
import FireflyImageSkeleton from './FireflyImageSkeleton'
import { GeneratedImage } from '@store/slices/fireflySlice'
import AiGenerateIcon from '/public/s2_icon_ai_generate.svg'
import {
  LocalStorageBooleanValue,
  LocalStorageKey
} from 'constants/localStorage'
import { StudioPanel } from '@store/slices/projectSlice'
import { EngineSelectedSceneNode } from '@services/engine/types'
import { FireflyPanelToggleButton } from './FireflyPanelToggleButton'
import { useStudioEnvironment } from '@hooks/useStudioEnvironment'
import { useTranslations } from 'use-intl'
import { FireflyPaidExhaustableNotification } from './FireflyPaidExhaustableNotification'
import { useAccessProfileState } from '@hooks/useAccessProfile'

const FireflyContextualMenu: FC = () => {
  const flags = useFlags()
  const { isDefaultEnvironment, isSceneToImageEnvironment } =
    useStudioEnvironment()
  const isEmptyScene = useIsEmptyScene()
  const selectedSceneNode = useSceneState('selectedSceneNode')
  const elements = useSceneState('elements')
  const { unselectElement } = useSceneActions()
  const size = useMediumMinimumSizePreference()
  const openedPanel = useProjectState('openedPanel')
  const { showFireflyPopover, setShowFireflyPopover, setOpenedPanel } =
    useProject()

  const fireflyFulfillableItemCreditModel = useAccessProfileState(
    'fireflyFulfillableItemCreditModel'
  )

  const {
    prompt,
    setPrompt,
    generatedImages,
    imageGenerationStatus,
    stopImagGeneration,
    generateImages,
    selectGeneratedImage,
    visibleToast,
    setVisibleToast,
    showToSPopup,
    setShowToSPopup,
    setShowEmptySceneDialog
  } = useFirefly()

  const hasGeneratedAnImg = generatedImages.some(({ url }) => url)

  function isGenerateButtonDisabled() {
    if (
      flags['base-tf-firefly-commercialization-flow'] &&
      fireflyFulfillableItemCreditModel === 'UNAVAILABLE'
    ) {
      return true
    }

    return !prompt.length || showToSPopup
  }

  function handleCancelOnClick() {
    setShowFireflyPopover(false)
    setOpenedPanel(StudioPanel.Properties)
    stopImagGeneration()
  }

  function renderPreviewContent({ selected, url }: GeneratedImage) {
    const showThumbnail =
      url &&
      (imageGenerationStatus === 'idle' ||
        (imageGenerationStatus === 'generating-similar-images' && selected))

    return (
      <div
        className={cn(styles['image-wrapper'], {
          [styles['selected-image']]: selected && showThumbnail
        })}>
        {showThumbnail ? (
          <FireflyImage
            alt={t('studio:firefly:image:altText')}
            imageUrl={url}
            onClick={() => selectGeneratedImage({ url })}
          />
        ) : (
          <FireflyImageSkeleton />
        )}
      </div>
    )
  }

  function handleGenerateImages() {
    if (isEmptyScene) {
      setShowEmptySceneDialog(true)
      setShowFireflyPopover(false)
      setOpenedPanel(StudioPanel.Properties)
      return
    }

    if (
      selectedSceneNode === EngineSelectedSceneNode.SHAPE ||
      selectedSceneNode === EngineSelectedSceneNode.GROUP ||
      selectedSceneNode === EngineSelectedSceneNode.MULTI_SELECTED
    ) {
      for (const e of elements) {
        if (e.selected) unselectElement({ uuid: e.uuid })
      }
    }

    localStorage.getItem(LocalStorageKey.fireflyAgreedToToS) ===
    LocalStorageBooleanValue.TRUE
      ? generateImages()
      : setShowToSPopup(true)
  }

  function handleAgreeToS() {
    setShowToSPopup(false)
    localStorage.setItem(
      LocalStorageKey.fireflyAgreedToToS,
      LocalStorageBooleanValue.TRUE
    )
    generateImages()
  }

  const t = useTranslations()
  return (
    <>
      {isSceneToImageEnvironment && <FireflyPanelToggleButton />}
      {!flags['base-pf-ui-firefly-tos-dialog'] && (
        <div
          className={cn(styles['terms-of-service'], {
            [styles['terms-of-service-visible']]: showToSPopup
          })}>
          <img src="/firefly_cai_banner.jpg" />
          <h3>{t('studio:firefly:terms')}</h3>
          <div className={styles['terms-of-service-main']}>
            <p>
              {t.rich('studio:firefly:terms:text', {
                hyperlinked: chunks => (
                  <a href={t('studio:firefly:terms:url')} target="_blank">
                    {chunks}
                  </a>
                )
              })}
            </p>
            <ButtonGroup size={size}>
              <Button
                variant="secondary"
                treatment="outline"
                onClick={() => setShowToSPopup(false)}>
                {t('studio:firefly:terms:cancel')}
              </Button>
              <Button variant="accent" onClick={handleAgreeToS}>
                {t('studio:firefly:terms:agree')}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      )}

      <div
        className={cn(styles['prompt-contextual-menu'], {
          [styles['prompt-contextual-menu-visible']]: showFireflyPopover
        })}>
        {isDefaultEnvironment && (
          <ActionButton
            quiet
            className={styles['cancel-btn']}
            onClick={handleCancelOnClick}>
            <SpIconClose slot="icon" />
          </ActionButton>
        )}
        <div className={styles['input-wrapper']}>
          <label htmlFor="firefly-prompt">{t('studio:firefly:prompt')}</label>
          <input
            id="firefly-prompt"
            type="text"
            className={styles['textfield']}
            readOnly={imageGenerationStatus !== 'idle'}
            value={prompt}
            onKeyDown={e => e.key === 'Enter' && generateImages()}
            onInput={e => setPrompt(e.currentTarget.value)}
            placeholder={t('studio:firefly:prompt:placeholder')}
          />
        </div>
        <div className={styles['content']}>
          <div className={styles['image-container']}>
            {(hasGeneratedAnImg || imageGenerationStatus !== 'idle') &&
              generatedImages.map((output, index) => (
                <React.Fragment key={index}>
                  {renderPreviewContent(output)}
                </React.Fragment>
              ))}
          </div>

          <div className={styles['prompt-contextual-menu-action-group']}>
            <ActionButton
              size={size}
              disabled={openedPanel === StudioPanel.Firefly}
              onClick={() => setOpenedPanel(StudioPanel.Firefly)}>
              <Icon slot="icon">
                <AiGenerateIcon />
              </Icon>
              {t('studio:firefly:prompt:openGeneratePanel')}
            </ActionButton>
            <div className="position-relative">
              <Button
                size={size}
                variant="accent"
                data-tracking-event="studio:firefly:generate"
                onClick={handleGenerateImages}
                disabled={isGenerateButtonDisabled()}>
                {imageGenerationStatus !== 'idle' ? (
                  <div className={styles['generate-btn-pending']}>
                    <ProgressCircle indeterminate size="s" />
                  </div>
                ) : (
                  <>
                    <Icon slot="icon">
                      <AiGenerateIcon />
                    </Icon>
                    {t('studio:firefly:prompt:generate')}
                  </>
                )}
              </Button>
              {flags['base-tf-firefly-commercialization-flow'] && (
                <FireflyPaidExhaustableNotification />
              )}
            </div>
          </div>
        </div>
      </div>

      <Toast
        className={cn(styles['toast'], styles['denied-prompt-words-toast'], {
          [styles['toast-adjusted-firefly-popover']]: showFireflyPopover
        })}
        timeout={8000}
        open={visibleToast === 'warning'}
        close={() => {
          setVisibleToast('none')
        }}>
        <div className={styles['denied-prompt-words-toast-content']}>
          <img src="/firefly_error.webp" />
          <div className={styles['denied-prompt-words-toast-content-msg']}>
            <h4>{t('studio:firefly:prompt:denied:header')}</h4>
            <p>{t('studio:firefly:prompt:denied:body')}</p>
            <Button
              size="s"
              staticColor="white"
              variant="secondary"
              treatment="outline"
              href={t('studio:firefly:prompt:denied:limitationsUrl')}
              target="_blank">
              {t('studio:firefly:prompt:denied:learnMore')}
            </Button>
          </div>
        </div>
      </Toast>

      <Toast
        className={cn(styles['toast'], {
          [styles['toast-adjusted-firefly-popover']]: showFireflyPopover
        })}
        timeout={2000}
        open={visibleToast === 'error'}
        close={() => {
          setVisibleToast('none')
        }}
        variant="negative">
        {t('studio:firefly:errorGeneratingImage')}
      </Toast>
    </>
  )
}

export default FireflyContextualMenu
