import { all, select, takeEvery } from 'redux-saga/effects'
import Context from '@store/middleware/context'
import {
  sendEvent,
  setAdobeAnalyticsInitialized
} from '@concerns/monitoring/store/monitoringSlice'
import { PayloadType, RootState } from '@store/store'
import { setLocalUser } from '@store/slices/authSlice'
import { PayloadAction } from '@reduxjs/toolkit'

function* sendUserIdentifiers() {
  const { isAdobeAnalyticsInitialized } = yield select(
    (state: RootState) => state.monitoring
  )
  const { localUser } = yield select((state: RootState) => state.auth)

  if (localUser) {
    if (isAdobeAnalyticsInitialized) {
      Context.Analytics?.sendEvent({
        xdm: {
          _3di: {
            member_guid: localUser.adobeUserId,
            service_user_guid: localUser.uuid
          },
          identityMap: {
            member_guid: [{ id: localUser.adobeUserId }],
            service_user_guid: [{ id: localUser.uuid }]
          }
        }
      })
    }

    try {
      if (window) {
        // REFACTOR GLOBAL FUNCTION
        FULLSTORY('setIdentity', {
          uid: localUser.uuid,
          properties: {
            displayName: localUser.adobeUserDisplayName,
            email: localUser.adobeUserEmail,
            adobeUserId: localUser.adobeUserId
          }
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
}

function* handleSendEvent({
  payload
}: PayloadAction<PayloadType<typeof sendEvent>>) {
  if (!Context.LDClient?.variation('base-pf-analytics-pixel-tracking')) {
    return
  }

  try {
    switch (payload) {
      case 'discover:appBar:createProject':
        yield window?.fbq?.('track', 'StartTrial', {
          value: '0.00',
          currency: 'USD',
          predicted_ltv: '0.00'
        })
        yield window?.gtag?.('event', 'conversion', {
          send_to: `${process.env.NEXT_PUBLIC_CLIENT_ANALYTICS_GOOGLE_TAG_MANAGER_ID}/${process.env.NEXT_PUBLIC_CLIENT_ANALYTICS_GOOGLE_TAG_MANAGER_ACTION_DISCOVER_CREATE_PROJECT_ID}`
        })
        yield window?.ttq?.track('ClickButton')
        break
      case 'landing:hero:getStarted':
        yield window?.fbq?.('track', 'ViewContent')
        yield window?.gtag?.('event', 'conversion', {
          send_to: `${process.env.NEXT_PUBLIC_CLIENT_ANALYTICS_GOOGLE_TAG_MANAGER_ID}/${process.env.NEXT_PUBLIC_CLIENT_ANALYTICS_GOOGLE_TAG_MANAGER_ACTION_LANDING_GET_STARTED_ID}`
        })
        yield window?.ttq?.track('ViewContent')
        break
      case 'landing:hero:seeHowItWorks':
        yield window?.fbq?.('track', 'ViewContent')
        yield window?.gtag?.('event', 'conversion', {
          send_to: `${process.env.NEXT_PUBLIC_CLIENT_ANALYTICS_GOOGLE_TAG_MANAGER_ID}/${process.env.NEXT_PUBLIC_CLIENT_ANALYTICS_GOOGLE_TAG_MANAGER_ACTION_LANDING_SEE_HOW_IT_WORKS_ID}`
        })
        yield window?.ttq?.track('ViewContent')
        break
      default:
        break
    }
  } catch (error) {
    console.error('pixel tracker event error', error)
  }
}

export default function* monitoringSaga() {
  yield all([
    takeEvery(
      [setAdobeAnalyticsInitialized.type, setLocalUser.type],
      sendUserIdentifiers
    ),
    takeEvery(sendEvent.type, handleSendEvent)
  ])
}
