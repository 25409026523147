import { defaultPropertyPanelId } from '@services/engine/engine'
import {
  EngineCommitChange,
  EngineMode,
  fontWeightMap,
  PropertyPanelPath,
  PropertyPanelSetStatus,
  ScenePrimType,
  VariableColorModeType,
  VariableColorModeValues,
  Vec3d
} from '@services/engine/types'
import { degToRad } from '@services/engine/utils'
import Context from '@store/middleware/context'
import { PropertyPayload } from '@store/slices/sceneSlice'
import { RootState } from '@store/store'
import { select } from 'redux-saga/effects'

export default function* setSceneProperty(payload: PropertyPayload) {
  if (!Context.Engine) return

  // These functions use the old document model, and hence the old undo/redo api

  if (payload.key === 'playbackEnabled') {
    yield Context.Engine.toggleScenePlayback()
  }

  if (payload.key === 'groupOutlineEnabled') {
    yield Context.Engine.toggleGroupOutline()
  }

  if (payload.key === 'groupExpanded') {
    yield Context.Engine.setModelPose(payload.value)
  }

  if (payload.key === 'primitiveScaleCorners') {
    yield Context.Engine.setPrimitiveScaleCorners(payload.value)
  }

  if (payload.key === 'showAvatars') {
    yield Context.Engine.toggleUsersAvatarVisibility()
  }

  if (payload.key === 'materialKeepStylesInSync') {
    yield Context.Engine.keepMaterialStylesInSynch(payload.value)
  }

  if (payload.key === 'snappingEnabled') {
    yield Context.Engine.setSnapping(payload.value)
  }

  const primitiveType: ScenePrimType = yield select(
    (state: RootState) => state.scene.primitiveType
  )

  /*
  ----------------- Primitive values -----------------
  ---------------------------------------------------
  ---------------------------------------------------
  */

  const { key, value, commit } = payload

  if (key === 'materialColor') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.pbrColor',
      hexColorToEngineColor(value),
      commit
    )
  }

  if (payload.key === 'materialRoughness') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.pbrRoughness',
      value,
      commit
    )
  }

  if (payload.key === 'materialMetalness') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.pbrMetalness',
      value,
      commit
    )
  }

  if (payload.key === 'materialReflective') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.pbrIsReflective',
      Boolean(value), // Some parts of the app (for example, userLibrarySaga will send this value as an int rather than a boolean). Cast to boolean here just to be safe. This should be sorted out at some point
      commit
    )
  }

  if (payload.key === 'blendType') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.blendMode',
      value,
      commit
    )
  }

  if (payload.key === 'blendAmount') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.blendShape',
      value,
      commit
    )
  }

  if (
    payload.key === 'transformPositionX' ||
    payload.key === 'transformPositionY' ||
    payload.key === 'transformPositionZ'
  ) {
    const { transformPositionX, transformPositionY, transformPositionZ } =
      yield select((state: RootState) => state.scene)

    const newPosition: Vec3d = {
      x: transformPositionX,
      y: transformPositionY,
      z: transformPositionZ
    }

    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.position',
      newPosition,
      commit
    )
  }

  if (
    payload.key === 'transformRotationX' ||
    payload.key === 'transformRotationY' ||
    payload.key === 'transformRotationZ'
  ) {
    const { transformRotationX, transformRotationY, transformRotationZ } =
      yield select((state: RootState) => state.scene)

    // c++ side needs radians
    const newRotation: Vec3d = {
      x: degToRad(transformRotationX),
      y: degToRad(transformRotationY),
      z: degToRad(transformRotationZ)
    }

    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.rotation',
      newRotation,
      commit
    )
  }

  if (payload.key === 'transformScaleX') {
    if (
      primitiveType === ScenePrimType.SOFT_BOX ||
      primitiveType === ScenePrimType.TRIANGLE
    ) {
      yield setPropertyPanelValueWithDefaultId(
        'treeNode.size.width',
        value,
        commit
      )
    }

    if (primitiveType === ScenePrimType.STAR) {
      yield setPropertyPanelValueWithDefaultId('treeNode.radius', value, commit)
    }

    if (primitiveType == ScenePrimType.HORSESHOE) {
      yield setPropertyPanelValueWithDefaultId('treeNode.angle', value, commit)
    }

    if (primitiveType === ScenePrimType.EGG) {
      yield setPropertyPanelValueWithDefaultId(
        'treeNode.radiusA',
        value,
        commit
      )
    }
  }

  if (payload.key === 'transformScaleY') {
    if (
      primitiveType === ScenePrimType.SOFT_BOX ||
      primitiveType === ScenePrimType.STAR ||
      primitiveType === ScenePrimType.TRIANGLE ||
      primitiveType === ScenePrimType.TEXT
    ) {
      yield setPropertyPanelValueWithDefaultId(
        'treeNode.dimension.extrudeAmount',
        (value as number) / 2,
        commit
      )
    }

    if (primitiveType === ScenePrimType.HORSESHOE) {
      yield setPropertyPanelValueWithDefaultId(
        'treeNode.dimension.extrudeAmount',
        value,
        commit
      )
    }
  }

  if (payload.key === 'transformScaleZ') {
    if (primitiveType === ScenePrimType.SOFT_BOX) {
      yield setPropertyPanelValueWithDefaultId(
        'treeNode.size.height',
        value,
        commit
      )
    }

    if (primitiveType === ScenePrimType.HORSESHOE) {
      yield setPropertyPanelValueWithDefaultId('treeNode.radius', value, commit)
    }

    if (primitiveType === ScenePrimType.TRIANGLE) {
      yield setPropertyPanelValueWithDefaultId(
        'treeNode.size.height',
        (value as number) * 2,
        commit
      )
    }

    if (primitiveType === ScenePrimType.EGG) {
      yield setPropertyPanelValueWithDefaultId(
        'treeNode.radiusB',
        (value as number) / 2,
        commit
      )
    }
  }

  if (payload.key === 'repeatType') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.repeatMode',
      value,
      commit
    )
  }

  if (payload.key === 'repeatX') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.repeatX',
      value,
      commit
    )
  }

  if (payload.key === 'repeatY') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.repeatY',
      value,
      commit
    )
  }

  if (payload.key === 'repeatZ') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.repeatZ',
      value,
      commit
    )
  }

  if (payload.key === 'repeatDistanceX') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.distanceX',
      value,
      commit
    )
  }

  if (payload.key === 'repeatDistanceY') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.distanceY',
      value,
      commit
    )
  }

  if (payload.key === 'repeatDistanceZ') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.distanceZ',
      value,
      commit
    )
  }

  if (payload.key === 'repeatAngleDirection') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.circularCopyAxis',
      value,
      commit
    )
  }

  if (payload.key === 'repeatAngle') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.circularCopyCount',
      value,
      commit
    )
  }

  if (payload.key === 'repeatDistance') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.circularCopyDistance',
      value,
      commit
    )
  }

  // An enum with values of 0 and 1 are used for this, so we cast to boolean here. Change this in other parts of the app at some point.
  if (payload.key === 'symmetryX') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.alongX',
      Boolean(value),
      commit
    )
  }

  if (payload.key === 'symmetryY') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.alongY',
      Boolean(value),
      commit
    )
  }

  if (payload.key === 'symmetryZ') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.alongZ',
      Boolean(value),
      commit
    )
  }

  if (payload.key === 'primitiveHole') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.thickness',
      value,
      commit
    )
  }

  if (payload.key === 'primitiveRadial0') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.cornerRounding0',
      value,
      commit
    )
  }

  if (payload.key === 'primitiveRadial1') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.cornerRounding1',
      value,
      commit
    )
  }

  if (payload.key === 'primitiveRadial2') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.cornerRounding2',
      value,
      commit
    )
  }

  if (payload.key === 'primitiveRadial3') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.cornerRounding3',
      value,
      commit
    )
  }

  if (payload.key === 'primitiveStarPoints') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.sideCount',
      value,
      commit
    )
  }

  if (payload.key === 'primitiveStarAngle') {
    yield setPropertyPanelValueWithDefaultId('treeNode.angle', value, commit)
  }

  if (payload.key === 'primitiveStarCorners') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.roundingRadius',
      value,
      commit
    )
  }

  if (payload.key === 'primitiveHorseshoeAngle') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.angle',
      degToRad(value as number),
      commit
    )
  }

  if (payload.key === 'primitiveHorseshoeRadius') {
    yield setPropertyPanelValueWithDefaultId('treeNode.radius', value, commit)
  }

  if (payload.key === 'primitiveHorseshoeWidth') {
    yield setPropertyPanelValueWithDefaultId('treeNode.width', value, commit)
  }

  if (payload.key === 'primitiveHorseshoeLength') {
    yield setPropertyPanelValueWithDefaultId('treeNode.length', value, commit)
  }

  if (payload.key === 'primitiveHorseshoeThickness') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.thickness',
      value,
      commit
    )
  }

  if (payload.key === 'primitiveHorseshoeCorner') {
    yield setPropertyPanelValueWithDefaultId('treeNode.corners', value, commit)
  }

  if (payload.key === 'triangleVertex') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.dartOffset',
      value,
      commit
    )
  }

  if (payload.key === 'primitiveDimensionType') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.dimension.mode',
      value,
      commit
    )
  }

  if (payload.key === 'primitiveCornerOne') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.dimension.bottomFillet',
      value,
      commit
    )
  }

  if (payload.key === 'primitiveCornerTwo') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.dimension.topFillet',
      value,
      commit
    )
  }

  if (payload.key === 'primitiveShell') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.onion',
      value,
      commit
    )
  }

  if (payload.key === 'primitiveInflate') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.standard.round',
      value,
      commit
    )
  }

  if (payload.key === 'primitiveDistance') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.dimension.distance',
      value,
      commit
    )
  }

  if (payload.key === 'primitiveRotation') {
    yield setPropertyPanelValueWithDefaultId(
      'treeNode.dimension.rotation',
      degToRad(value as number),
      commit
    )
  }

  if (payload.key === 'materialEColorTop') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.exprColorTop',
      hexColorToEngineColor(value as string),
      commit
    )
  }

  if (payload.key === 'materialEColorLig') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.exprColorLight',
      hexColorToEngineColor(value as string),
      commit
    )
  }

  if (payload.key === 'materialEColorSha') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.exprColorDark',
      hexColorToEngineColor(value as string),
      commit
    )
  }

  if (payload.key === 'materialESpecularSize') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.exprSpecularSize',
      value,
      commit
    )
  }

  if (payload.key === 'materialESpecularIntensity') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.exprSpecularIntensity',
      value,
      commit
    )
  }

  if (payload.key === 'materialIColor') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.illustrativeColor',
      hexColorToEngineColor(value as string),
      commit
    )
  }

  if (payload.key === 'materialIEmissiveColor') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.illustrativeEmissiveColor',
      hexColorToEngineColor(value as string),
      commit
    )
  }

  if (payload.key === 'materialIStrokeSize') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.illustrativeStrokeSize',
      value,
      commit
    )
  }

  if (payload.key === 'materialIHighlightIntensity') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.illustrativeSpecularIntensity',
      value,
      commit
    )
  }

  if (payload.key === 'materialIStrokeIntensity') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.illustrativeNormalIntensity',
      value,
      commit
    )
  }

  if (payload.key === 'materialIColorVarIntensity') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.illustrativeColorVariation',
      value,
      commit
    )
  }

  if (payload.key === 'materialIScaleX') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.illustrativeScaleX',
      value,
      commit
    )
  }

  if (payload.key === 'materialIScaleY') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.illustrativeScaleY',
      value,
      commit
    )
  }

  if (payload.key === 'materialIAngle') {
    yield setPropertyPanelValueWithDefaultId(
      'shapeMaterial.illustrativeAngle',
      value,
      commit
    )
  }

  if (payload.key === 'variableColorMode') {
    const enumIndex = VariableColorModeValues.indexOf(
      value as VariableColorModeType
    )
    if (enumIndex === -1) return

    const styleMode = yield select((state: RootState) => state.scene.mode)
    if (styleMode === EngineMode.OUTLINE || styleMode === EngineMode.PIXEL) {
      yield setPropertyPanelValueWithDefaultId(
        'shapeMaterial.exprMaterialMode',
        enumIndex,
        commit
      )
    } else {
      yield setPropertyPanelValueWithDefaultId(
        'shapeMaterial.illustrativeMaterialMode',
        enumIndex,
        commit
      )
    }
  }

  /*
  ----------------- Global values -----------------
  ---------------------------------------------------
  ---------------------------------------------------
  */

  if (payload.key === 'frameEnabled') {
    yield setPropertyPanelValueWithDefaultId('frame.enabled', value, commit)
  }

  if (payload.key === 'frameType') {
    yield setPropertyPanelValueWithDefaultId('frame.frameType', value, commit)
  }

  if (payload.key === 'frameSize') {
    yield setPropertyPanelValueWithDefaultId(
      'frame.size',
      {
        x: payload.value.w ?? 0,
        y: payload.value.h ?? 0
      },
      commit
    )
  }

  if (payload.key === 'frameOpacity') {
    yield setPropertyPanelValueWithDefaultId('frame.opacity', value, commit)
  }

  if (payload.key === 'backgroundType') {
    yield setPropertyPanelValueWithDefaultId(
      'background.gradientMode',
      value,
      commit
    )
  }

  if (payload.key === 'backgroundColorA') {
    yield setPropertyPanelValueWithDefaultId(
      'background.colorA',
      hexColorToEngineColor(value as string),
      commit
    )
  }

  if (payload.key === 'backgroundColorB') {
    yield setPropertyPanelValueWithDefaultId(
      'background.colorB',
      hexColorToEngineColor(value as string),
      commit
    )
  }

  if (payload.key === 'floorEnabled') {
    yield setPropertyPanelValueWithDefaultId('floor.enabled', value, commit)
  }

  if (payload.key === 'floorHeight') {
    yield setPropertyPanelValueWithDefaultId('floor.height', value, commit)
  }

  if (payload.key === 'cameraType') {
    yield setPropertyPanelValueWithDefaultId('cameraLens.type', value, commit)
  }

  if (payload.key === 'cameraSize') {
    yield setPropertyPanelValueWithDefaultId(
      'cameraLens.orthoSize',
      value,
      commit
    )
  }

  if (payload.key === 'cameraDistortion') {
    yield setPropertyPanelValueWithDefaultId(
      'cameraLens.distortionAmount',
      value,
      commit
    )
  }

  if (payload.key === 'cameraFocalLength') {
    yield setPropertyPanelValueWithDefaultId(
      'cameraLens.perspFocalLength',
      value,
      commit
    )
  }

  if (payload.key === 'cameraFocalPlane') {
    yield setPropertyPanelValueWithDefaultId(
      'cameraLens.dofFocalPlane',
      value,
      commit
    )
  }

  if (payload.key === 'cameraAperture') {
    yield setPropertyPanelValueWithDefaultId(
      'cameraLens.dofAperture',
      value,
      commit
    )
  }

  if (payload.key === 'cameraDistortion') {
    yield setPropertyPanelValueWithDefaultId(
      'cameraLens.distortionAmount',
      value,
      commit
    )
  }

  if (payload.key === 'mode') {
    // Subtract 1 from value because the engine returns a value that is 1 less than what it is on the JS side (this enum in the engine starts at 0, whereas on the JS side it starts at 1)
    setPropertyPanelValueWithDefaultId('style.style', payload.value - 1, commit)
  }

  if (payload.key === 'modeExpressiveOutline') {
    setPropertyPanelValueWithDefaultId('style.exprOutline', value, commit)
  }

  if (payload.key === 'modePixelSize') {
    setPropertyPanelValueWithDefaultId(
      'style.pixelArtPixelCount',
      value,
      commit
    )
  }

  if (payload.key === 'modeOutlineColor') {
    setPropertyPanelValueWithDefaultId(
      'style.exprColor',
      hexColorToEngineColor(value as string),
      commit
    )
  }

  if (payload.key === 'pixelOutlineEnabled') {
    setPropertyPanelValueWithDefaultId(
      'style.pixelArtOutlineThickness',
      // On the c++ side, outline thickness is an int, so cast this
      // to a number so that outline thickness is either 1 or 0
      Number(value),
      commit
    )
  }

  if (payload.key === 'modeIllustrativeLightTexture') {
    yield setPropertyPanelValueWithDefaultId(
      'style.illusLightTexture',
      value,
      commit
    )
  }

  if (payload.key === 'modeIllustrativeShadowTexture') {
    yield setPropertyPanelValueWithDefaultId(
      'style.illusShadowTexture',
      value,
      commit
    )
  }

  if (payload.key === 'modeIllustrativeGlobalStrokeSize') {
    yield setPropertyPanelValueWithDefaultId(
      'style.illusGlobalStrokeSize',
      value,
      commit
    )
  }

  if (payload.key === 'modeIllustrativeFilterStrength') {
    yield setPropertyPanelValueWithDefaultId('style.illusFilterStrength', value)
  }

  if (payload.key === 'modeIllustrativeHighlightIntensity') {
    yield setPropertyPanelValueWithDefaultId('style.illusLightIntensity', value)
  }

  if (payload.key === 'modeIllustrativeAmbientOcclusionIntensity') {
    yield setPropertyPanelValueWithDefaultId('style.illusAOIntensity', value)
  }

  if (payload.key === 'modeIllustrativeEdgeBlendStrength') {
    yield setPropertyPanelValueWithDefaultId('style.illusEdgeStrength', value)
  }

  if (payload.key === 'modeIllustrativeOutlineEnabled') {
    yield setPropertyPanelValueWithDefaultId('style.illusOutlineEnabled', value)
  }

  if (payload.key === 'modeIllustrativeOutlineTolerance') {
    yield setPropertyPanelValueWithDefaultId(
      'style.illusOutlineTolerance',
      value
    )
  }

  if (payload.key === 'modeIllustrativeOutlineColor') {
    yield setPropertyPanelValueWithDefaultId(
      'style.illusOutlineColor',
      hexColorToEngineColor(value as string)
    )
  }

  if (payload.key === 'modeIllustrativeHighlightColor') {
    yield setPropertyPanelValueWithDefaultId(
      'style.illusHighlightColor',
      hexColorToEngineColor(value as string)
    )
  }

  if (payload.key === 'modeIllustrativeShadowColor') {
    yield setPropertyPanelValueWithDefaultId(
      'style.illusShadowColor',
      hexColorToEngineColor(value as string)
    )
  }

  if (payload.key === 'modeIllustrativeSkyColor') {
    yield setPropertyPanelValueWithDefaultId(
      'style.illusSkyColor',
      hexColorToEngineColor(value as string)
    )
  }

  if (payload.key === 'modeIllustrativeBounceColor') {
    yield setPropertyPanelValueWithDefaultId(
      'style.illusBounceColor',
      hexColorToEngineColor(value as string)
    )
  }

  if (payload.key === 'elementName') {
    yield setPropertyPanelValueWithDefaultId('treeNode.standard.name', value)
  }

  if (payload.key === 'textFont') {
    yield setPropertyPanelValueWithDefaultId('treeNode.font', value)
  }

  if (payload.key === 'textLetterSpacing') {
    yield setPropertyPanelValueWithDefaultId('treeNode.letterSpacing', value)
  }

  if (payload.key == 'textLeadingType') {
    yield setPropertyPanelValueWithDefaultId('treeNode.leadingType', value)
  }

  if (payload.key === 'textLineHeight') {
    yield setPropertyPanelValueWithDefaultId('treeNode.lineHeight', value)
  }

  if (payload.key === 'textSize') {
    yield setPropertyPanelValueWithDefaultId('treeNode.size', value)
  }

  if (payload.key === 'textCopy') {
    yield setPropertyPanelValueWithDefaultId('treeNode.text', value)
  }

  if (payload.key === 'drawMaterial') {
    yield setPropertyPanelValueWithDefaultId('treeNode.drawMaterial', value)
  }

  if (payload.key === 'lightOcclusionDistance') {
    setPropertyPanelValueWithDefaultId('lightOcclusion.distance', value, commit)
  }

  if (payload.key === 'lightAngle1') {
    const lightAngle1InRad: number = degToRad(value as number)
    const lightAngle2InRad: number = degToRad(
      yield select((state: RootState) => state.scene.lightAngle2)
    )

    setPropertyPanelValueWithDefaultId(
      'directionalLight.direction',
      calculateDirectionalLightFromAngles(lightAngle1InRad, lightAngle2InRad),
      commit
    )
  }

  if (payload.key === 'lightAngle2') {
    const lightAngle1InRad: number = degToRad(
      yield select((state: RootState) => state.scene.lightAngle1)
    )

    const lightAngle2InRad: number = degToRad(value as number)

    setPropertyPanelValueWithDefaultId(
      'directionalLight.direction',
      calculateDirectionalLightFromAngles(lightAngle1InRad, lightAngle2InRad),
      commit
    )
  }
}

// Angle must be in radians
function calculateDirectionalLightFromAngles(
  angle1InRad: number,
  angle2InRad: number
) {
  const direction: Vec3d = {
    x: Math.cos(angle1InRad) * Math.sin(angle2InRad),
    y: Math.cos(angle2InRad),
    z: Math.sin(angle1InRad) * Math.sin(angle2InRad)
  }

  return direction
}

function hexColorToEngineColor(hex: string): Vec3d {
  if (!Context.Engine)
    throw new Error(
      'Engine must be intialized before calling hexColorToEngineColor'
    )

  const color: [number, number, number] =
    Context.Engine.hexColorToEngineColor(hex)

  return {
    x: color[0],
    y: color[1],
    z: color[2]
  }
}

function setPropertyPanelValueWithDefaultId(
  path: PropertyPanelPath,
  val: any,
  commit?: EngineCommitChange
): PropertyPanelSetStatus | undefined {
  if (!Context.Engine) return
  return Context.Engine.setPropertyPanelValue(
    path,
    val,
    defaultPropertyPanelId,
    commit
  )
}
