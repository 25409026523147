import {
  BindingsContainer,
  getWasmModule,
  NumberPropertyBindings,
  Vec2Bindings
} from './bindings'
import { Muxer, ArrayBufferTarget } from 'mp4-muxer'

import { useProperty } from './property'

import { Validator } from '@components/propertiesPanel/VideoFormNumberField'
import { FrameDimensions } from '@components/share/VideoFrameSize'
import { UseTranslationsHookResult } from '@concerns/i18n/types'

declare class NeoVideoFrame {
  width(): number
  height(): number
  isKey(): boolean
  timeStampInMicroSeconds(): number

  pixels(): Uint8Array
}
interface VideoEncodingSessionImpl {
  encodeQueueSize(): number
  addVideoFrame(frame: NeoVideoFrame): boolean

  cancel(): void
  finish(): void

  delete(): void
}

export type CodecType = 'avc' | 'hevc' | 'vp9' | 'av1'

export declare class VideoEncodingParameters {
  public delete(): void

  public codecType: CodecType
  public width: number // > 0
  public height: number // > 0
  public quality: number /* float between 0 and 1 */
  public shouldUpscale: boolean
  public framesPerSecond: number /* 5-60 */
  public fileName: string
}

export function makeVideoEncodingParameters(): VideoEncodingParameters {
  const module = getWasmModule()
  return new module['VideoEncodingParameters']()
}

function videoCodecStringFromCodecType(type: CodecType): string | undefined {
  switch (type) {
    case 'avc': {
      // See https://developer.apple.com/library/archive/documentation/NetworkingInternet/Conceptual/StreamingMediaGuide/FrequentlyAskedQuestions/FrequentlyAskedQuestions.html
      // for codec strings of AVC
      //return 'avc1.42001f'
      return 'avc1.640029' // H.264 High Profile level 4.1
    }
    case 'hevc': {
      //console.assert(false, 'todo: understand codex format')
      //return 'hev1.*'
      return undefined
    }

    case 'av1': {
      //console.assert(false, 'todo: understand codex format')
      //return 'avc1.*'
      return undefined
    }

    case 'vp9': {
      return 'vp09.00.10.08' // VP9, Profile 00, level 10, bit depth 8 (later fields defaulted)
      //
      // vp09.PP.LL.BB
      //
      // PP = Profile
      //   00	= 8 bit/sample	4:2:0
      //   01	= 8 bit	4:2:2, 4:4:4
      //   02	= 10 or 12 bit	4:2:0
      //   03	= 10 or 12 bit	4:2:2, 4:4:4
      //
      // LL = Level
      //   10 = 1.0
      //   11 = 1.1
      //   20 = 2.0
      //   ...
      //   62 = 6.2
      //
      // BB = Bit Depth
      //    08 = 8 bits
      //    10 = 10 bits
      //    12 = 12 bits
    }
  }
}

function maxFrameDimensionsFromCodecType(type: CodecType): FrameDimensions {
  switch (type) {
    case 'avc': {
      // See https://developer.apple.com/library/archive/documentation/NetworkingInternet/Conceptual/StreamingMediaGuide/FrequentlyAskedQuestions/FrequentlyAskedQuestions.html
      // for codec strings of AVC
      // 'avc1.640029' // H.264 High Profile level 4.1
      return { width: 1920, height: 1080 }
    }
    case 'vp9': {
      return { width: 3840, height: 2160 }
    }
    default:
      break
  }

  return { width: 0, height: 0 }
}

function computeBitRate(
  fps: number,
  width: number,
  height: number,
  quality: number
): number {
  // https://riverside.fm/blog/video-bitrate
  // https://zidivo.com/blog/video-bitrate-guide/#:~:text=Video%20bitrate%20formula%20(bps)%20%3D,your%20video%20is)%20x%200.07.
  const dynamicFactor = 4
  const b = width * height * fps * dynamicFactor * 0.07

  var qualityFactor = quality
  if (qualityFactor >= 1) {
    qualityFactor = 1.5
  } else if (qualityFactor < 0.3) {
    qualityFactor = 0.3
  }

  const bitrate = b * qualityFactor
  return bitrate
}

class JSVideoEncodingSessionImpl implements VideoEncodingSessionImpl {
  public constructor() {}

  configure(params: VideoEncodingParameters): boolean {
    this._fileName = params.fileName

    const width = params.width
    const height = params.height

    const codec = videoCodecStringFromCodecType(params.codecType)
    if (!codec) {
      return false
    }

    const quality = params.quality
    const latencyMode = quality >= 0.5 ? 'quality' : 'realtime'

    const fps = params.framesPerSecond
    const bitrate = computeBitRate(fps, width, height, quality)

    const videoEncoderConfig: any = {
      width: width,
      height: height,
      framerate: fps,
      bitrate: bitrate,
      latencyMode: latencyMode,
      codec: codec
    }

    var muxerVideoConfig: any = {
      width: width,
      height: height
    }

    // see https://developer.mozilla.org/en-US/docs/Web/Media/Formats/codecs_parameter#codec_options_by_container
    // https://www.w3.org/TR/webcodecs-codec-registry/#video-codec-registry
    // for details on VideoEncoder codec configuration
    const codecType = params.codecType
    switch (codecType) {
      case 'avc': {
        muxerVideoConfig.codec = 'avc'
        break
      }

      case 'hevc': {
        muxerVideoConfig.codec = 'hevc'
        break
      }

      case 'av1': {
        muxerVideoConfig.codec = 'av1'
        break
      }

      case 'vp9': {
        muxerVideoConfig.codec = 'vp9'
        break
      }
    }

    this._muxer = new Muxer({
      target: new ArrayBufferTarget(),
      video: muxerVideoConfig,
      fastStart: 'in-memory'
    })

    const muxer = this._muxer

    this._videoEncoder = new VideoEncoder({
      output: (chunk, meta) => muxer.addVideoChunk(chunk, meta),
      error: e => console.error(e)
    })

    try {
      this._videoEncoder.configure(videoEncoderConfig)
      console.log('VideoEncoder configuration succeeded')
    } catch (error) {
      console.log('VideoEncoder configuration failed: ' + error)
      return false
    }

    return true
  }

  encodeQueueSize(): number {
    if (!this._videoEncoder) {
      return 0
    }

    return this._videoEncoder.encodeQueueSize
  }

  addVideoFrame(frame: NeoVideoFrame): boolean {
    if (!this._videoEncoder) {
      return false
    }

    const width = frame.width()
    const height = frame.height()
    const isKey = frame.isKey()

    const pixels = frame.pixels()

    const type = isKey ? 'key' : 'delta'
    const timestamp = frame.timeStampInMicroSeconds()

    console.assert(pixels.length == width * height * 4, 'buffer size mismatch')

    console.log('Adding video frame time=<%f> key=<%s>', timestamp, type)

    let init: VideoFrameBufferInit = {
      codedWidth: width,
      codedHeight: height,
      format: 'RGBX',
      timestamp: timestamp,
      duration: 0
    }

    let videoFrame = new VideoFrame(pixels, init)

    try {
      this._videoEncoder?.encode(videoFrame, {
        keyFrame: isKey
      })
      videoFrame.close()
    } catch (error) {
      console.log('Encoding Frame failed: ' + error)
      return false
    }

    return true
  }

  cancel(): void {
    this._muxer = null
    this._videoEncoder?.close()
    this._videoEncoder = null
  }

  finish(): void {
    if (!this._muxer) {
      return
    }

    const muxer = this._muxer
    const fileName = this._fileName

    const videoEncoder = this._videoEncoder

    videoEncoder.flush().then(() => {
      console.log('Video ended')
      muxer.finalize()
      const { buffer } = muxer.target // Buffer contains final MP4 file

      videoEncoder.close()

      const mimeType = 'video/mp4'
      const blob = new Blob([buffer], { type: mimeType })

      const link = document.createElement('a')
      link.style.display = 'none'
      document.body.appendChild(link)
      link.href = URL.createObjectURL(blob)
      link.download = fileName + '.mp4'
      link.click()
    })
  }

  delete(): void {}

  private _muxer: Muxer<ArrayBufferTarget> | null = null
  private _videoEncoder: any = null
  private _fileName: string = 'video'
}

interface VideoEncoderImpl {
  codecType(): CodecType

  maxFrameDimensions(): FrameDimensions
  makeVideoEncodingSession(
    params: VideoEncodingParameters
  ): VideoEncodingSessionImpl | null
}

class JSVideoEncoderImpl implements VideoEncoderImpl {
  private _codecType: CodecType

  constructor(codecType: CodecType) {
    this._codecType = codecType
  }

  codecType(): CodecType {
    return this._codecType
  }

  maxFrameDimensions(): FrameDimensions {
    return maxFrameDimensionsFromCodecType(this._codecType)
  }

  makeVideoEncodingSession(
    params: VideoEncodingParameters
  ): VideoEncodingSessionImpl | null {
    if (params.codecType != this._codecType) {
      return null
    }

    const session = new JSVideoEncodingSessionImpl()
    if (!session.configure(params)) {
      return null
    }

    return session
  }
}

export interface VideoAnimationParameters {
  duration: number

  delete(): void
}

export declare class TurnTableVideoAnimationParameters
  implements VideoAnimationParameters
{
  public delete(): void
  public duration: number

  // >= 1 (integer)
  public numberOfTurns: number
}

export declare class BoomerangVideoAnimationParameters
  implements VideoAnimationParameters
{
  public delete(): void
  public duration: number

  // angle between 45 and 180 (float)
  public horizontalAngleAmplitude: number

  // > 1 (integer)
  public numberOfBackAndForth: number
}

export declare class LightingVideoAnimationParameters
  implements VideoAnimationParameters
{
  public delete(): void
  public duration: number

  // >= 1 (integer)
  public numberOfTurns: number
}

export function makeTurnTableVideoAnimationParameters(): TurnTableVideoAnimationParameters {
  const module = getWasmModule()
  return new module['TurnTableVideoAnimationParameters']()
}

export function makeBoomerangVideoAnimationParameters(): BoomerangVideoAnimationParameters {
  const module = getWasmModule()
  return new module['BoomerangVideoAnimationParameters']()
}

export function makeLightingVideoAnimationParameters(): LightingVideoAnimationParameters {
  const module = getWasmModule()
  return new module['LightingVideoAnimationParameters']()
}

declare class MediaIOControllerBindings {
  canRecordVideos(codec: CodecType): boolean
  cancelCurrentVideoEncodingOrPreview(): boolean
  getVideoRecordingStateProperty(): NumberPropertyBindings
  getVideoRecordingProgressProperty(): NumberPropertyBindings
  addVideoEncoder(encoder: VideoEncoderImpl): void
  maxFrameDimensions(codecType: CodecType): Vec2Bindings
  previewVideoEncoding(animParams: VideoAnimationParameters): boolean
  startVideoEncoding(
    encodingParams: VideoEncodingParameters,
    animParams: VideoAnimationParameters,
    pauseImmediatelyAtStart: boolean
  ): boolean
  startCurrentVideoEncodingOrPreview(): boolean
}

const mediaCaptureControllerBindingsContainer =
  new BindingsContainer<MediaIOControllerBindings>(
    'MediaIOControllerBindings',
    (bindings: MediaIOControllerBindings) => {
      // make sure WebCodecs API are supported in this browser
      if (!window.VideoEncoder) {
        console.log('WebCodecs API not supported')
        return
      }

      const codecTypes: Array<CodecType> = ['av1', 'avc', 'hevc', 'vp9']
      for (const codec of codecTypes) {
        const codecString = videoCodecStringFromCodecType(codec)
        if (!codecString) {
          continue
        }

        const videoEncoderConfig: VideoEncoderConfig = {
          width: 128,
          height: 128,
          codec: codecString
        }
        VideoEncoder.isConfigSupported(videoEncoderConfig).then(
          (support: VideoEncoderSupport) => {
            if (support.supported) {
              bindings.addVideoEncoder(new JSVideoEncoderImpl(codec))
            }
          }
        )
      }
    }
  )

export enum VideoRecordingState {
  idle = 0,

  waitingForStart = 1,
  recording = 2,
  previewing = 3,

  finished = 4,
  failed = 5,
  cancelled = 6
}

function computeMaxFrameDimensions(codecType: CodecType): FrameDimensions {
  const container = mediaCaptureControllerBindingsContainer.get()
  if (!container) {
    return { width: 0, height: 0 }
  }

  const dimAsVec2 = container.maxFrameDimensions(codecType)
  const dim: FrameDimensions = {
    width: dimAsVec2.x(),
    height: dimAsVec2.y()
  }
  dimAsVec2.delete()

  return dim
}
export interface IMediaIO {
  canRecordVideos: (codec: CodecType) => boolean
  cancelCurrentVideoEncodingOrPreview: () => boolean
  prepareMediaIO(): void
  maxFrameDimensions(codecType: CodecType): FrameDimensions
  adjustFrameDimensions(
    codecType: CodecType,
    dimensions: FrameDimensions
  ): boolean // return true if some adjustment occurred
  previewVideoEncoding: (animParams: VideoAnimationParameters) => boolean
  startVideoEncoding: (
    encodingParams: VideoEncodingParameters,
    animParams: VideoAnimationParameters,
    runImmediately: boolean
  ) => boolean
  startCurrentVideoEncodingOrPreview: () => boolean
  getFrameDimensionValidators: (
    t: UseTranslationsHookResult,
    width: number,
    height: number,
    maxPixels: number,
    name: 'Width' | 'Height'
  ) => Validator[]
  getDurationValidators(t: UseTranslationsHookResult, val: number): Validator[]
  getRotationValidators(t: UseTranslationsHookResult, val: number): Validator[]
  getBackAndForthValidators(
    t: UseTranslationsHookResult,
    val: number
  ): Validator[]
  getAngleValidators(t: UseTranslationsHookResult, val: number): Validator[]
  getMaxPixels(): number
}

export const MediaIO: IMediaIO = {
  canRecordVideos: (type: CodecType): boolean => {
    const container = mediaCaptureControllerBindingsContainer.get()
    if (!container) {
      return false
    }

    return container.canRecordVideos(type)
  },

  prepareMediaIO: () => {
    // we should just warm up the bindings container
    // to make sure JS Video Encoder is added on time before usage
    mediaCaptureControllerBindingsContainer.get()
  },

  maxFrameDimensions(codecType: CodecType): FrameDimensions {
    return computeMaxFrameDimensions(codecType)
  },

  adjustFrameDimensions(
    codecType: CodecType,
    dimensions: FrameDimensions
  ): boolean {
    // Multiple of 2
    dimensions.width = Math.max(dimensions.width & ~1, 2)
    dimensions.height = Math.max(dimensions.height & ~1, 2)

    const maxDimensions = computeMaxFrameDimensions(codecType)

    // none of the dimensions exceeds maximum value
    const widthRatio = dimensions.width / maxDimensions.width
    const heightRatio = dimensions.height / maxDimensions.height
    const ratio = Math.max(widthRatio, heightRatio)
    if (ratio > 1) {
      // and if so, scale down the dimensions respecting the incoming aspect ratio
      dimensions.width = Math.floor(dimensions.width / ratio)
      dimensions.height = Math.floor(dimensions.height / ratio)

      return true
    } else {
      return false
    }
  },

  previewVideoEncoding: (animParams: VideoAnimationParameters): boolean => {
    const container = mediaCaptureControllerBindingsContainer.get()
    if (!container) {
      return false
    }

    return container.previewVideoEncoding(animParams)
  },

  startVideoEncoding: (
    encodingParams: VideoEncodingParameters,
    animParams: VideoAnimationParameters,
    runImmediately: boolean
  ): boolean => {
    const container = mediaCaptureControllerBindingsContainer.get()
    if (!container) {
      return false
    }

    return container.startVideoEncoding(
      encodingParams,
      animParams,
      runImmediately
    )
  },

  startCurrentVideoEncodingOrPreview: (): boolean => {
    const container = mediaCaptureControllerBindingsContainer.get()
    if (!container) {
      return false
    }

    return container.startCurrentVideoEncodingOrPreview()
  },

  cancelCurrentVideoEncodingOrPreview: (): boolean => {
    const container = mediaCaptureControllerBindingsContainer.get()
    if (!container) {
      return false
    }

    console.log('cancelling encoding')

    return container.cancelCurrentVideoEncodingOrPreview()
  },

  getFrameDimensionValidators(
    t: UseTranslationsHookResult,
    width: number,
    height: number,
    maxPixels: number,
    name: 'Width' | 'Height'
  ): Validator[] {
    const dimensionToValidateAgainst: number = name === 'Width' ? width : height

    return [
      () => ({
        isValid: typeof dimensionToValidateAgainst === 'number',
        error: t('studio:downloadMenu:mp4:dimensionMustBeNumber', {
          dimension: name
        })
      }),
      () => ({
        isValid: !isNaN(dimensionToValidateAgainst),
        error: t('studio:downloadMenu:mp4:dimensionMustBeNumber', {
          dimension: name
        })
      }),
      () => ({
        isValid: dimensionToValidateAgainst >= 16,
        error: t('studio:downloadMenu:mp4:dimensionMinSize', {
          dimension: name,
          min: 16
        })
      }),
      () => ({
        isValid: dimensionToValidateAgainst <= 32766,
        error: t('studio:downloadMenu:mp4:dimensionMaxSize', {
          dimension: name,
          max: 32766
        })
      }),
      () => ({
        isValid: dimensionToValidateAgainst % 2 === 0,
        error: t('studio:downloadMenu:mp4:durationMustBeNumber', {
          dimension: name
        })
      }),
      () => ({
        isValid: width * height <= maxPixels,
        error: t('studio:downloadMenu:mp4:maxPixelsExceeded', {
          dimension: `${width} x ${height}`,
          maxPixels: parseFloat(maxPixels.toString()).toLocaleString('en')
        })
      })
    ]
  },

  getDurationValidators(
    t: UseTranslationsHookResult,
    val: number
  ): Validator[] {
    return [
      () => ({
        isValid: typeof val === 'number',
        error: t('studio:downloadMenu:mp4:durationMustBeNumber')
      }),
      () => ({
        isValid: !isNaN(val),
        error: t('studio:downloadMenu:mp4:durationMustBeNumber')
      }),
      () => ({
        isValid: val > 0,
        error: t('studio:downloadMenu:mp4:durationGreaterThanZero')
      })
    ]
  },
  getRotationValidators(
    t: UseTranslationsHookResult,
    val: number
  ): Validator[] {
    return [
      () => ({
        isValid: typeof val === 'number',
        error: t('studio:downloadMenu:mp4:rotationsMustBeNumber')
      }),
      () => ({
        isValid: !isNaN(val),
        error: t('studio:downloadMenu:mp4:rotationsMustBeNumber')
      }),
      () => ({
        isValid: val >= 1,
        error: t('studio:downloadMenu:mp4:atLeastOneRotation')
      }),
      () => ({
        isValid: val - Math.trunc(val) === 0,
        error: t('studio:downloadMenu:mp4:rotationWholeNumber')
      })
    ]
  },
  getBackAndForthValidators(
    t: UseTranslationsHookResult,
    val: number
  ): Validator[] {
    return [
      () => ({
        isValid: typeof val === 'number',
        error: t('studio:downloadMenu:mp4:loopsMustBeNumber')
      }),
      () => ({
        isValid: !isNaN(val),
        error: t('studio:downloadMenu:mp4:loopsMustBeNumber')
      }),
      () => ({
        isValid: val >= 1,
        error: t('studio:downloadMenu:mp4:atLeastOneLoop')
      }),
      () => ({
        isValid: val - Math.trunc(val) === 0,
        error: t('studio:downloadMenu:mp4:loopWholeNumber')
      })
    ]
  },
  getAngleValidators(t: UseTranslationsHookResult, val: number): Validator[] {
    return [
      () => ({
        isValid: typeof val === 'number',
        error: t('studio:downloadMenu:mp4:angleMustBeNumber')
      }),
      () => ({
        isValid: !isNaN(val),
        error: t('studio:downloadMenu:mp4:angleMustBeNumber')
      }),
      () => ({
        isValid: val >= 45,
        error: t('studio:downloadMenu:mp4:angleMinSize')
      })
    ]
  },
  getMaxPixels(): number {
    // equal to 1920 x 1080
    return 2073600
  }
}

export function useVideoRecordingState() {
  const prop = useProperty<VideoRecordingState>(() => {
    return mediaCaptureControllerBindingsContainer
      .get()
      ?.getVideoRecordingStateProperty()
  })

  return prop ?? VideoRecordingState.idle
}

export function useVideoRecordingProgress() {
  const prop = useProperty<number>(() => {
    return mediaCaptureControllerBindingsContainer
      .get()
      ?.getVideoRecordingProgressProperty()
  })

  return prop ?? 0
}
