import React, { FC, useCallback } from 'react'
import { Switch } from 'ui'
import styles from '@styles/components/SVGExportForm.module.scss'
import ShareExportFooter from './ShareExportFooter'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { EngineMode } from '@services/engine/types'
import Typography from '@components/base/Typography/Typography'
import FrameSize from '@components/propertiesPanel/FrameSize'
import {
  useMediumMinimumSizePreference,
  useProjectState
} from '@hooks/useProject'
import { useTranslations } from 'use-intl'

interface Props {
  splitFaceEnabled: boolean
  setSplitFaceEnabled: (splitFaceEnabled: boolean) => void
}

const SVGExportForm: FC<Props> = ({
  splitFaceEnabled,
  setSplitFaceEnabled
}) => {
  const t = useTranslations()
  const frameEnabled = useSceneState('frameEnabled')
  const frameSizeLocked = useSceneState('frameSizeLocked')
  const frameUnit = useSceneState('frameUnit')
  const mode = useSceneState('mode')
  const frameSize = useSceneState('frameSize')
  const sizePreference = useProjectState('sizePreference')

  const minSize = useMediumMinimumSizePreference()

  const { exportCapture, setPropertyState: _setPropertyState } =
    useSceneActions()

  const setPropertyState = useCallback(_setPropertyState, [])

  const exportDisabled =
    mode === EngineMode.NORMAL || mode === EngineMode.ILLUSTRATIVE

  function handleExport() {
    exportCapture({ format: 'svg', splitFaceEnabled, workflow: 'download' })
  }

  function handleSplitFaceChange() {
    setSplitFaceEnabled(!splitFaceEnabled)
  }

  return (
    <>
      {exportDisabled ? (
        <div className={styles['header-disabled']}>
          {t('studio:downloadMenu:svg:disabled')}
        </div>
      ) : (
        <>
          <Switch
            size={minSize}
            checked={splitFaceEnabled}
            onclick={handleSplitFaceChange}>
            {t('studio:downloadMenu:svg:splitFaces')}
          </Switch>

          <div className="flex flex-col gap-s">
            <Typography variant="h4">
              {t('studio:downloadMenu:svg:size')}
            </Typography>
            <FrameSize
              sizePreference={sizePreference}
              focusCanvasOnInputChange={false}
              frameEnabled={frameEnabled}
              frameSizeLocked={frameSizeLocked}
              frameSize={frameSize}
              frameUnit={frameUnit}
              setPropertyState={setPropertyState}
            />
          </div>
        </>
      )}
      <ShareExportFooter
        exportDisabled={exportDisabled}
        onExportClick={handleExport}
        buttonText={t('studio:downloadMenu:downloadButton')}
      />
    </>
  )
}

export default SVGExportForm
