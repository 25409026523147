import React from 'react'
import { Link } from 'ui'
import styles from '@styles/components/FireflyUnavailableNotificationBar.module.scss'
import AlertIcon from '/public/s2_icon_alert_triangle.svg'
import { useTranslations } from 'use-intl'

export function FireflyUnavailableNotificationBar() {
  const t = useTranslations()
  return (
    <div className={styles['container']}>
      <AlertIcon />
      <p>
        {t.rich('studio:firefly:notification:unavailable', {
          hyperlinked: chunks => (
            <Link
              variant="secondary"
              href="https://helpx.adobe.com/enterprise/kb/contact-administrator.html?x-product=Helpx%2F1.0.0&x-product-location=Search%3AForums%3Alink%2F3.6.3"
              target="_blank">
              {chunks}
            </Link>
          )
        })}
      </p>
    </div>
  )
}
