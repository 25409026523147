import { type TQWeb, type TQFont } from '@coretech/typequestweb'
import {
  AppEntitlement,
  Entitlement,
  ErrorCode,
  OperationMode,
  StatusCode,
  TQError
} from './types'
import { Deferred } from 'utils/Deferred'

export default class TypeQuestWeb {
  /**
   * The underlying TQWeb instance.
   */
  private tqWeb: TQWeb | null = null

  /**
   * The TQWeb initialization promise deferred object.
   */
  private deferredLoading = new Deferred<void>()

  /**
   * Entitlement of the user, which is either FULL or TRIAL.
   */
  private userEntitlement: Entitlement = Entitlement.ANONYMOUS

  private _isLoaded = false

  /**
   * Getter for checking if the service is still loading, without having to await on the deferred's promise.
   */
  get isLoaded() {
    return this._isLoaded
  }

  constructor() {
    this.load = this.load.bind(this)
    this.initialize = this.initialize.bind(this)
    this.runningPromise = this.runningPromise.bind(this)
    this.handleStatusUpdateCallback = this.handleStatusUpdateCallback.bind(this)
    this.getUserEntitlement = this.getUserEntitlement.bind(this)
    this.getFont = this.getFont.bind(this)
  }

  async runningPromise(): Promise<void> {
    await this.deferredLoading.promise
    return
  }

  /**
   * Initializes the underlying TQWeb instance.
   *
   * @returns A promise that will resolve once TQWeb loaded and receives a StatusCode.DONE.
   */
  public async load(userId: string, userToken: string, clientId: string) {
    if (this._isLoaded) {
      return this.runningPromise()
    }

    if (!this._isLoaded) {
      this._isLoaded = true
      await this.initialize(userId, userToken, clientId)
    }
    return this.deferredLoading.promise
  }

  /**
   * Initializes the underlying TQWeb instance.
   *
   * @returns A promise that will resolve once TQWeb loaded and receives a StatusCode.DONE.
   */
  private async initialize(
    userId: string,
    userToken: string,
    clientId: string
  ): Promise<void> {
    const { TQWeb, TQInitParams, tqtypes } = await import(
      '@coretech/typequestweb'
    )

    const authParams = new tqtypes.AuthParams(clientId, userId, userToken)
    authParams.isStaging =
      process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT !== 'prd'

    const initParams = new TQInitParams(authParams)
    initParams.appEntitlement = AppEntitlement.PAID // TODO update to get user entitlement from api
    initParams.fontsUpdateCallback = _ => true
    initParams.statusUpdateCallback = this.handleStatusUpdateCallback

    this.tqWeb = new TQWeb(initParams)
    await this.tqWeb.start()
    this.deferredLoading.resolve()
  }

  private handleStatusUpdateCallback(status: StatusCode, error: TQError) {
    if (status === StatusCode.DONE) {
      this.getUserEntitlement()
    }

    if (error.errCode) {
      const message = `TypeQuestWeb initialization failed with error code: ${
        ErrorCode[error.errCode]
      }, message: ${error.errMsg}!`
      console.error(message)
      this.deferredLoading?.reject(message)
    }

    return true
  }

  private async getUserEntitlement() {
    if (!this.tqWeb) return

    try {
      const entitlement = await this.tqWeb.getUserEntitlement()
      if (entitlement) this.userEntitlement = entitlement
    } catch (error) {
      new Error(`Error retrieving user entitlement: ${error}`)
    }
  }

  public async getFont(fontId: string): Promise<TQFont | undefined> {
    await this.runningPromise()

    if (!this.tqWeb) return

    const promises = await this.tqWeb.findFontsAsync(
      new Set([
        {
          fontId
        }
      ]),
      OperationMode.DATA
    )

    for (const result of promises) {
      const ret = await result[1]
      return ret.font
    }
  }
}
