import styles from '@styles/components/Legal.module.scss'
import { PropsWithChildren, ReactNode } from 'react'
import cn from 'classnames'
import { useTranslations } from 'use-intl'
import { UseTranslationsHookResult } from '@concerns/i18n/types'

export const getCopyright = (t: UseTranslationsHookResult) => {
  const currYear = new Date().getFullYear()
  if (currYear === 2024) {
    return t('legal:copyright:static')
  } else {
    return t('legal:copyright:dynamic', { currentYear: currYear.toString() })
  }
}

export function getLegalLinks(
  t: UseTranslationsHookResult,
  className?: string
): {
  link: ReactNode
}[] {
  return [
    {
      link: t.rich('link:privacyPolicy:text', {
        hyperlinked: chunks => (
          <a
            target="_blank"
            className={className}
            href={t('link:privacyPolicy:url')}>
            {chunks}
          </a>
        )
      })
    },
    {
      link: t.rich('link:termsOfUse:text', {
        hyperlinked: chunks => (
          <a
            target="_blank"
            className={className}
            href={t('link:termsOfUse:url')}>
            {chunks}
          </a>
        )
      })
    },
    {
      link: t.rich('link:personalInformation:text', {
        hyperlinked: chunks => (
          <a
            target="_blank"
            className={className}
            href={t('link:personalInformation:url')}>
            {chunks}
          </a>
        )
      })
    },
    {
      link: t.rich('link:communityGuidelines:text', {
        hyperlinked: chunks => (
          <a
            target="_blank"
            className={className}
            href={t('link:communityGuidelines:url')}>
            {chunks}
          </a>
        )
      })
    }
  ]
}

type Props = {
  direction: 'row' | 'column'
}

export function Legal({ direction }: Props) {
  const t = useTranslations()
  const legalLinks = getLegalLinks(t)

  return (
    <ul
      className={cn(
        direction === 'row' ? styles['links'] : styles['links__column']
      )}>
      <ListItem direction={direction}>{getCopyright(t)}</ListItem>
      <ListItem direction={direction}>{t('legal:rightsReserved')}</ListItem>
      {legalLinks.map(({ link }, i) => (
        <ListItem key={`legal-${i}`} direction={direction}>
          {link}
        </ListItem>
      ))}
    </ul>
  )
}

type ListItemProps = Props

function ListItem({ children, direction }: PropsWithChildren<ListItemProps>) {
  return (
    <li
      className={cn(
        direction === 'row' ? styles['link'] : styles['link__column']
      )}>
      {children}
    </li>
  )
}
