import React, { useEffect } from 'react'
import Script from 'next/script'
import { useRouter } from 'next/router'

export function MetaPixelTrackerScript() {
  const router = useRouter()

  useEffect(() => {
    if (!window) return

    window.fbq?.('track', 'PageView')

    const handleRouteChange = () => {
      window?.fbq?.('track', 'PageView')
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <Script
      id="meta-pixel-tracker-init"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');

          fbq('init', '${process.env.NEXT_PUBLIC_CLIENT_ANALYTICS_META_ID}');
        `
      }}
    />
  )
}
