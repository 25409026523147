import React, { FC } from 'react'
import { PanelKeys, StudioPanel } from '@store/slices/projectSlice'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ActionButton, Icon, OverlayTrigger, Tooltip } from 'ui'
import { SpIconPeopleGroup, SpIconPlayCircle } from 'workflow-icons'
import ObjectPropertiesIcon from '/public/s2_icon_properties.svg'
import ViewsIcon from '/public/s2_icon_viewport.svg'
import MeetIcon from '/public/s2_icon_movie_camera.svg'
import ChatIcon from '/public/s2_icon_comment.svg'
import FireflyIcon from '/public/s2_icon_ai_generate.svg'
import CommunityIcon from '/public/s2_icon_community.svg'
import AssetIcon from '/public/s2_icon_asset.svg'
import LayersIcon from '/public/s2_icon_layers.svg'
import StyleIcon from '/public/s2_icon_filters.svg'
import {
  useMediumMinimumSizePreference,
  useProjectActions,
  useProjectState
} from '@hooks/useProject'
import Rail from '@components/rail/Rail'
import CursorOverlay from '@components/cursorOverlay/CursorOverlay'
import { useIsEmptyScene } from '@hooks/useScene'
import { useFirefly } from '@hooks/useFirefly'
import { ClassNameProps } from 'types/reactProps'
import { useStudioEnvironment } from '@hooks/useStudioEnvironment'
import { useTranslations } from 'use-intl'

type PanelTriggerType = {
  value: PanelKeys
  DefaultIcon?: any
  SpIcon?: typeof SpIconPeopleGroup
  stringKey: string
  featureFlagKey?: string
  renderInSceneToImageStudio?: boolean
}
const PanelTriggers: PanelTriggerType[] = [
  {
    value: StudioPanel.Layers,
    stringKey: 'studio:tooltips:layersPanel',
    DefaultIcon: LayersIcon,
    featureFlagKey: 'base-pf-ui-layers-panel'
  },
  {
    value: StudioPanel.Properties,
    DefaultIcon: ObjectPropertiesIcon,
    renderInSceneToImageStudio: true,
    stringKey: 'studio:tooltips:propertiesPanel'
  },
  {
    value: StudioPanel.Library,
    stringKey: 'studio:tooltips:libraryPanel',
    DefaultIcon: AssetIcon,
    featureFlagKey: 'base-pf-ui-user-library'
  },
  {
    value: StudioPanel.Style,
    stringKey: 'studio:tooltips:styles',
    DefaultIcon: StyleIcon,
    featureFlagKey: 'base-pf-ui-styles-panel'
  },
  {
    value: StudioPanel.Firefly,
    stringKey: 'studio:tooltips:generatePanel',
    DefaultIcon: FireflyIcon,
    featureFlagKey: 'base-pf-ui-firefly',
    renderInSceneToImageStudio: true
  },
  {
    value: StudioPanel.Views,
    stringKey: 'studio:tooltips:views',
    DefaultIcon: ViewsIcon,
    featureFlagKey: 'base-pf-ui-views'
  },
  {
    value: StudioPanel.Meet,
    stringKey: 'studio:tooltips:meetPanel',
    DefaultIcon: MeetIcon,
    featureFlagKey: 'base-pf-ui-chime'
  },
  {
    value: StudioPanel.Chat,
    stringKey: 'studio:tooltips:chatPanel',
    DefaultIcon: ChatIcon,
    featureFlagKey: 'base-pf-ui-chat'
  },
  {
    value: StudioPanel.Player,
    stringKey: 'studio:tooltips:playerPanel',
    SpIcon: SpIconPlayCircle,
    featureFlagKey: 'base-pf-ui-player'
  },
  {
    value: StudioPanel.Community,
    stringKey: 'studio:tooltips:communityPanel',
    DefaultIcon: CommunityIcon,
    featureFlagKey: 'base-pf-ui-community'
  }
]

const RightRail: FC<ClassNameProps> = ({ className }) => {
  const t = useTranslations()
  const flags = useFlags()

  const isEmptyScene = useIsEmptyScene()

  const { setShowEmptySceneDialog, isSceneRealisticOrVectorArt } = useFirefly()

  const openedPanel = useProjectState('openedPanel')

  const size = useMediumMinimumSizePreference()
  const { setOpenedPanel, setShowFireflyPopover } = useProjectActions()

  const { environment, isDefaultEnvironment, isSceneToImageEnvironment } =
    useStudioEnvironment()

  const getPanelTriggers = (): PanelTriggerType[] => {
    if (isDefaultEnvironment) {
      return PanelTriggers.filter(trigger => {
        if (trigger.featureFlagKey) {
          return flags[trigger.featureFlagKey]
        }

        return true
      })
    } else if (isSceneToImageEnvironment) {
      return PanelTriggers.filter(trigger => trigger.renderInSceneToImageStudio)
    }

    return []
  }

  const panelTriggers = environment ? getPanelTriggers() : []

  function togglePanelVisibility(panel: PanelKeys, visibility: boolean) {
    if (panel === StudioPanel.Firefly) {
      if (visibility && isEmptyScene) {
        setShowEmptySceneDialog(true)
        return
      }
      if (visibility) {
        setShowFireflyPopover(true)
      }
    }

    setOpenedPanel(openedPanel === panel ? null : panel)
  }

  return (
    <Rail className={className}>
      {panelTriggers.map(
        ({ DefaultIcon, SpIcon, stringKey, value, featureFlagKey }) => {
          const isSelected = openedPanel === value
          if (
            featureFlagKey === 'base-pf-ui-firefly' &&
            !isSceneRealisticOrVectorArt
          )
            return

          if (isSceneToImageEnvironment && value === 'chat') return

          return (
            <OverlayTrigger key={value} placement="left">
              <ActionButton
                size={size}
                slot="trigger"
                quiet
                value={value}
                selected={isSelected}
                onClick={() => togglePanelVisibility(value, !isSelected)}>
                {SpIcon ? (
                  <SpIcon slot="icon" />
                ) : (
                  <Icon slot="icon">
                    <DefaultIcon />
                  </Icon>
                )}
              </ActionButton>
              <Tooltip slot="hover-content">{t(stringKey)}</Tooltip>
            </OverlayTrigger>
          )
        }
      )}
      <CursorOverlay />
    </Rail>
  )
}

export default RightRail
