import React from 'react'
import { useTranslations } from 'use-intl'
import { Toast } from 'ui'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import { useClearToast } from '@hooks/useClearToast'
import { MaxImportSVGFileSize } from '@constants/appConstants'

export function SVGImportErrorToast() {
  const t = useTranslations()
  const isSVGImportErrorToastOpen = useProjectState('isSVGImportErrorToastOpen')
  const { setIsSVGImportErrorToastOpen } = useProjectActions()

  useClearToast(isSVGImportErrorToastOpen, setIsSVGImportErrorToastOpen)

  return (
    <Toast
      open={isSVGImportErrorToastOpen}
      close={() => setIsSVGImportErrorToastOpen(false)}
      variant="negative"
      className="absolute-align-horizontal"
      style={{
        top: 45,
        zIndex: isSVGImportErrorToastOpen ? 999999 : -1
      }}>
      {t('studio:svg:import:toastError', {
        maxFileSize: MaxImportSVGFileSize / 1024 // Divide by 1024 because MaxImportSVGFileSize is in bytes and we want to deliver the message in kilobytes
      })}
    </Toast>
  )
}
