import { useProjectState, useProjectActions } from '@hooks/useProject'
import React, { useEffect } from 'react'
import { Button, Toast } from 'ui'
import { useTranslations } from 'use-intl'

const ProjectSharedToast = () => {
  const t = useTranslations()
  const isSharedProjectToastOpen = useProjectState('isSharedProjectToastOpen')
  const { setIsSharedProjectToastOpen, toggleProjectPublicStatus } =
    useProjectActions()

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined

    if (isSharedProjectToastOpen) {
      timeout = setTimeout(() => {
        setIsSharedProjectToastOpen(false)
      }, 6000)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [isSharedProjectToastOpen])

  function handleUndo() {
    toggleProjectPublicStatus()
    setIsSharedProjectToastOpen(false)
  }

  return (
    <Toast
      className="absolute-align-horizontal"
      style={{
        top: 60,
        zIndex: 999999
      }}
      variant="positive"
      open={isSharedProjectToastOpen}
      close={() => setIsSharedProjectToastOpen(false)}>
      {t.rich('studio:shareProject:confirmation', {
        bolded: chunks => <strong>{chunks}</strong>
      })}
      <Button
        style={{ marginLeft: 20, marginInlineEnd: 0 }}
        slot="action"
        onClick={handleUndo}
        variant="secondary"
        treatment="outline"
        staticColor="white">
        {t('studio:shareProject:undo')}
      </Button>
    </Toast>
  )
}

export default ProjectSharedToast
