import React from 'react'
import Script from 'next/script'

export function GoogleTagManagerScript() {
  return (
    <>
      <Script
        id="gtag-lib"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_CLIENT_ANALYTICS_GOOGLE_TAG_MANAGER_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${process.env.NEXT_PUBLIC_CLIENT_ANALYTICS_GOOGLE_TAG_MANAGER_ID}');
          `
        }}
      />
    </>
  )
}
