import { EngineMode } from '@services/engine/types'
import { useProjectState } from './useProject'
import { useSceneState } from './useScene'

export function useStudioLoadingSpinner() {
  const engineState = useSceneState('engineState')
  const mode = useSceneState('mode')
  const isLoading = useProjectState('isLoading')
  const illustrativeTexturesLoaded = useSceneState('illustrativeTexturesLoaded')

  const showStudioLoadingSpinner: boolean =
    (isLoading && engineState !== 'ERROR') ||
    (mode === EngineMode.ILLUSTRATIVE && !illustrativeTexturesLoaded)

  return { showStudioLoadingSpinner }
}
