import React from 'react'
import { Icon, Link, OverlayTrigger, Tooltip } from 'ui'
import styles from '@styles/components/FireflyGenerativeCreditsInfo.module.scss'
import GenerativeCreditsIcon from '/public/s2_icon_generative_credits_outline.svg'
import { useAccessProfileState } from '@hooks/useAccessProfile'
import { useTranslations } from 'use-intl'

function FireflyGenerativeCreditsInfo() {
  const t = useTranslations()

  const fireflyFulfillableItemCreditModel = useAccessProfileState(
    'fireflyFulfillableItemCreditModel'
  )

  if (fireflyFulfillableItemCreditModel === 'UNAVAILABLE') {
    return null
  }
  return (
    <div className={styles['container']}>
      <OverlayTrigger placement="top">
        <div slot="trigger">
          <Icon className="icon-color-content-neutral-default">
            <GenerativeCreditsIcon />
          </Icon>
        </div>
        <Tooltip slot="hover-content" className={styles['tooltip']}>
          {t.rich(
            fireflyFulfillableItemCreditModel === 'FREE-EXHAUSTABLE'
              ? 'studio:firefly:creditsInfoTooltip:free-user'
              : 'studio:firefly:creditsInfoTooltip:paid-exhaustable',
            {
              hyperlinked: chunks => (
                <Link
                  variant="secondary"
                  href="https://helpx.adobe.com/firefly/get-set-up/learn-the-basics/generative-credits-overview.html"
                  target="_blank">
                  {chunks}
                </Link>
              )
            }
          )}
        </Tooltip>
      </OverlayTrigger>
    </div>
  )
}

export default FireflyGenerativeCreditsInfo
