import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CameraFromTo,
  Cartesian,
  EngineBackground,
  EngineBlendType,
  EngineCamera,
  EngineCommitChange,
  EngineExportCapturePayload,
  EngineCapturePayload,
  EngineMode,
  EnginePrimitive,
  EngineRepeatAngleDirection,
  EngineRepeatType,
  EngineSelectedSceneNode,
  EngineSelectParentChild,
  EngineSelectSibling,
  EngineStackReorderingOpts,
  EngineUndoRedo,
  FrameType,
  GroupExpanded,
  MaterialType,
  PrimitiveDimensionType,
  PrimitiveSymmetryChecked,
  SceneNavigatorElement,
  ScenePrimType,
  SelectedObjectUI,
  PropertyList,
  Snapshot,
  VariableColorModeType
} from '@services/engine/types'

import {
  VideoAnimationParameters,
  VideoEncodingParameters
} from '@services/engine/MediaIO'

// not-ready: initial state of the export status and is used to detect if the export dialog has not been opened yet. Status will change to 'idle' when export dialog is opened and will not revert back to 'not-ready' until engine unmounts
type ExportStatus = 'not-ready' | 'idle' | 'exporting' | 'completed'

type ExportWorkflow = 'download' | 'send-to-adobe-illustrator' | 'save'

export type ExportCapturePayload = EngineExportCapturePayload & {
  workflow: ExportWorkflow
}

export type CaptureStatus = {
  format:
    | EngineExportCapturePayload['format']
    | EngineCapturePayload['format']
    | null
  status: ExportStatus
  workflow?: ExportWorkflow
}

export type EngineState =
  | 'UNINITIALIZED'
  | 'INITIALIZE'
  | 'INITIALIZED'
  | 'INITIALIZED_AND_DOCUMENT_LOADED'
  | 'UNMOUNT'
  | 'UNMOUNTED'
  | 'ERROR'

export interface SceneState {
  // TODO move to engineSlice
  engineState: EngineState
  autoFocusEnabled: boolean
  snappingEnabled: boolean
  address: string
  backgroundColorA: string
  backgroundColorB: string
  backgroundType: EngineBackground
  floorEnabled: boolean
  floorHeight: number
  blendAmount: number
  blendType: EngineBlendType
  cameraFocalLength: number
  cameraSize: number
  cameraType: EngineCamera
  cameraFocalPlane: number
  cameraAperture: number
  cameraDistortion: number
  captureStatus: CaptureStatus
  drawMaterial: boolean
  frameEnabled: boolean
  frameOpacity: number
  frameSizeLocked: boolean
  frameType: FrameType
  frameSize: { w: number; h: number }
  frameUnit: 'pixels' | 'inches'
  framePosition: { top: number; left: number }
  groupExpanded: GroupExpanded
  lightAngle1: number
  lightAngle2: number
  lightOcclusionDistance: number
  materialColor: string
  materialEColorLig: string
  materialEColorTop: string
  materialEColorSha: string
  materialESpecularSize: number
  materialESpecularIntensity: number
  materialKeepStylesInSync: boolean
  materialMetalness: number
  materialReflective: boolean
  materialRoughness: number
  materialIColor: string
  materialIEmissiveColor: string
  materialIStrokeSize: number
  materialIHighlightIntensity: number
  materialIStrokeIntensity: number
  materialIColorVarIntensity: number
  materialIScaleX: number
  materialIScaleY: number
  materialIAngle: number
  materialType: MaterialType
  mode: EngineMode
  modeExpressiveOutline: number
  modePixelSize: number
  modeOutlineColor: string
  modeIllustrativeLightTexture: number
  modeIllustrativeShadowTexture: number
  modeIllustrativeGlobalStrokeSize: number
  modeIllustrativeFilterStrength: number
  modeIllustrativeHighlightIntensity: number
  modeIllustrativeAmbientOcclusionIntensity: number
  modeIllustrativeEdgeBlendStrength: number
  modeIllustrativeOutlineEnabled: boolean
  modeIllustrativeOutlineTolerance: number
  modeIllustrativeHighlightColor: string
  modeIllustrativeShadowColor: string
  modeIllustrativeSkyColor: string
  modeIllustrativeBounceColor: string
  modeIllustrativeOutlineColor: string
  nodeDepth: number
  groupOutlineEnabled: boolean
  editShapeEnabled: boolean
  pixelFilterEnabled: boolean
  pixelOutlineEnabled: boolean
  playbackEnabled: boolean
  primitiveCornerOne: number
  primitiveCornerTwo: number
  primitiveDimensionType: PrimitiveDimensionType
  primitiveDistance: number
  primitiveHole: number
  primitiveHorseshoeAngle: number
  primitiveHorseshoeCorner: number
  primitiveHorseshoeLength: number
  primitiveHorseshoeRadius: number
  primitiveHorseshoeThickness: number
  primitiveHorseshoeWidth: number
  primitiveRadial0: number
  primitiveRadial1: number
  primitiveRadial2: number
  primitiveRadial3: number
  primitiveRotation: number
  primitiveInflate: number
  primitiveScaleCorners: boolean
  primitiveShell: number
  primitiveStarAngle: number
  primitiveStarCorners: number
  primitiveStarPoints: number
  repeatAngle: number | null
  repeatAngleDirection: EngineRepeatAngleDirection
  repeatDistance: number
  repeatDistanceX: number
  repeatDistanceY: number
  repeatDistanceZ: number
  repeatType: EngineRepeatType
  repeatX: number | null
  repeatY: number | null
  repeatZ: number | null
  elementName: string
  elementParentName: string
  primitiveType: ScenePrimType | null
  selectedSceneNode: EngineSelectedSceneNode
  showAvatars: boolean
  symmetryX: PrimitiveSymmetryChecked
  symmetryY: PrimitiveSymmetryChecked
  symmetryZ: PrimitiveSymmetryChecked
  transformPositionX: number
  transformPositionY: number
  transformPositionZ: number
  transformRotationX: number
  transformRotationY: number
  transformRotationZ: number
  transformScaleX: number
  transformScaleY: number
  transformScaleZ: number
  elements: SceneNavigatorElement[]
  videoEncodingParameters: VideoEncodingParameters | null
  videoAnimationParameters: VideoAnimationParameters | null
  canvasAnimationStartedForRecording: boolean
  showVideoExportDialog: boolean
  illustrativeTexturesLoaded: boolean
  presignedImageUrlData: { projectUuid: string; url: string }
  selectedObjectUI: SelectedObjectUI
  propertyList: PropertyList
  snapshots: Snapshot[]
  variableColorMode: VariableColorModeType
  triangleVertex: number
  selectedFontPostScriptName: string
  textFont: string
  textLetterSpacing: number
  textLineHeight: number
  textLeadingType: boolean
  textSize: number
  textWeight: string
  textCopy: string
}

type KeyValue<
  T extends { [key: string]: any },
  U extends keyof T
> = U extends any
  ? { key: U; value: T[U]; commit?: EngineCommitChange; address?: string }
  : never

export type PropertyPayload = KeyValue<SceneState, keyof SceneState>

export const initialState: SceneState = {
  selectedFontPostScriptName: '',
  engineState: 'UNINITIALIZED',
  address: '',
  backgroundColorA: '#e66465',
  backgroundColorB: '#e66465',
  backgroundType: EngineBackground.SOLID,
  floorEnabled: false,
  floorHeight: 0,
  blendAmount: 0,
  blendType: EngineBlendType.ADD,
  cameraFocalLength: 50,
  cameraSize: 2,
  cameraType: EngineCamera.PERSPECTIVE,
  cameraFocalPlane: 0,
  cameraAperture: 0,
  cameraDistortion: 0,
  captureStatus: { status: 'not-ready', format: null },
  drawMaterial: true,
  autoFocusEnabled: true,
  snappingEnabled: true,
  frameEnabled: false,
  frameOpacity: 25,
  frameSizeLocked: false,
  frameType: 0,
  frameSize: { w: 0, h: 0 },
  frameUnit: 'pixels',
  framePosition: { top: 0, left: 0 },
  groupExpanded: GroupExpanded.NORMAL,
  lightAngle1: 180,
  lightAngle2: 45,
  lightOcclusionDistance: 0.07,
  materialColor: '#e66465',
  materialEColorLig: '#e66465',
  materialEColorTop: '#e66465',
  materialEColorSha: '#e66465',
  materialIColor: '#e66465',
  materialIEmissiveColor: '#e66465',
  materialIStrokeSize: 0.5,
  materialIHighlightIntensity: 0.5,
  materialIStrokeIntensity: 0.5,
  materialIColorVarIntensity: 0.5,
  materialIScaleX: 1,
  materialIScaleY: 1,
  materialIAngle: 0,
  materialESpecularSize: 0,
  materialESpecularIntensity: 0,
  materialKeepStylesInSync: false,
  materialMetalness: 0,
  materialReflective: false,
  materialRoughness: 0,
  materialType: MaterialType.P,
  mode: EngineMode.NORMAL,
  modeExpressiveOutline: 2,
  modePixelSize: 16,
  modeOutlineColor: '#000',
  modeIllustrativeLightTexture: 0,
  modeIllustrativeShadowTexture: 0,
  modeIllustrativeGlobalStrokeSize: 20,
  modeIllustrativeFilterStrength: 50,
  modeIllustrativeHighlightIntensity: 30,
  modeIllustrativeAmbientOcclusionIntensity: 50,
  modeIllustrativeEdgeBlendStrength: 20,
  modeIllustrativeOutlineEnabled: false,
  modeIllustrativeOutlineTolerance: 50,
  modeIllustrativeHighlightColor: '#808080',
  modeIllustrativeShadowColor: '#3C3C3C',
  modeIllustrativeSkyColor: '#000000',
  modeIllustrativeBounceColor: '#1e1e1e',
  modeIllustrativeOutlineColor: '#000000',
  nodeDepth: 0,
  pixelFilterEnabled: false,
  pixelOutlineEnabled: false,
  playbackEnabled: false,
  groupOutlineEnabled: false,
  editShapeEnabled: false,
  primitiveCornerOne: 0,
  primitiveCornerTwo: 0,
  primitiveDimensionType: PrimitiveDimensionType.EXTRUDE,
  primitiveDistance: 0,
  primitiveHole: 0,
  primitiveHorseshoeAngle: 0.5,
  primitiveHorseshoeCorner: 0.5,
  primitiveHorseshoeLength: 0.5,
  primitiveHorseshoeRadius: 0.5,
  primitiveHorseshoeThickness: 0.5,
  primitiveHorseshoeWidth: 0.5,
  primitiveRadial0: 0,
  primitiveRadial1: 0,
  primitiveRadial2: 0,
  primitiveRadial3: 0,
  primitiveRotation: 0,
  primitiveInflate: 0,
  primitiveScaleCorners: false,
  primitiveShell: 0,
  primitiveStarAngle: 0,
  primitiveStarCorners: 0,
  primitiveStarPoints: 0,
  repeatAngle: null,
  repeatAngleDirection: EngineRepeatAngleDirection.Y,
  repeatDistance: 0,
  repeatDistanceX: 0,
  repeatDistanceY: 0,
  repeatDistanceZ: 0,
  repeatType: EngineRepeatType.NONE,
  repeatX: null,
  repeatY: null,
  repeatZ: null,
  elementName: '',
  elementParentName: '',
  primitiveType: null,
  selectedSceneNode: EngineSelectedSceneNode.NONE,
  showAvatars: true,
  symmetryX: PrimitiveSymmetryChecked.UNCHECKED,
  symmetryY: PrimitiveSymmetryChecked.UNCHECKED,
  symmetryZ: PrimitiveSymmetryChecked.UNCHECKED,
  transformPositionX: 0,
  transformPositionY: 0,
  transformPositionZ: 0,
  transformRotationX: 0,
  transformRotationY: 0,
  transformRotationZ: 0,
  transformScaleX: 1,
  transformScaleY: 1,
  transformScaleZ: 1,
  elements: [],
  videoEncodingParameters: null,
  videoAnimationParameters: null,
  canvasAnimationStartedForRecording: false,
  showVideoExportDialog: false,
  illustrativeTexturesLoaded: false,
  presignedImageUrlData: { projectUuid: '', url: '' },
  selectedObjectUI: {
    status: 0,
    editButtonGizmoPosition: {
      centerX: 0,
      centerY: 0,
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0
    }
  },
  snapshots: [],
  propertyList: {},
  variableColorMode: 'Shaded',
  triangleVertex: 0,
  textFont: '',
  textLetterSpacing: 24,
  textLineHeight: 24,
  textLeadingType: 0, // Roman (see NeoElement.h)
  textSize: 0.5,
  textCopy: ''
}

export const scene = createSlice({
  name: 'scene',
  initialState,
  reducers: {
    setEngineState: (
      state,
      { payload }: PayloadAction<SceneState['engineState']>
    ) => {
      state.engineState = payload
    },
    addPrimitive: (state, { payload }: PayloadAction<EnginePrimitive>) => {},
    deletePrimitive: () => {},
    duplicatePrimitive: () => {},
    setPropertyState: (state, { payload }: PayloadAction<PropertyPayload>) => {
      state[payload.key] = payload.value
    },
    setPixelFilterEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.pixelFilterEnabled = payload
    },
    setPixelOutlineEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.pixelOutlineEnabled = payload
    },
    setIllustrativeOutlineEnabled: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.modeIllustrativeOutlineEnabled = payload
    },
    // No saga listens for this. This is by design. This is used to update the store without triggering the middleware.
    setPropertiesState: (
      state,
      { payload }: PayloadAction<Partial<SceneState>>
    ) => {
      return { ...state, ...payload }
    },
    undoRedo: (state, { payload }: PayloadAction<EngineUndoRedo>) => {},
    selectElementParentChild: (
      state,
      { payload }: PayloadAction<EngineSelectParentChild>
    ) => {},
    selectElementSibling: (
      state,
      { payload }: PayloadAction<EngineSelectSibling>
    ) => {},
    reorderStackElements: (
      state,
      { payload }: PayloadAction<EngineStackReorderingOpts>
    ) => {},
    loadEngine: () => {},
    loadDocument: (state, { payload }: PayloadAction<string>) => {},
    engineLoaded: () => {},
    transferMaterials: () => {},
    copyMaterials: () => {},
    pasteMaterials: () => {},
    setAutoFocusEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.autoFocusEnabled = payload
    },
    exportCapture: (
      state,
      { payload }: PayloadAction<ExportCapturePayload>
    ) => {},
    selectElement: (
      state,
      {
        payload
      }: PayloadAction<{
        uuid: SceneNavigatorElement['uuid']
        multiSelect?: boolean
      }>
    ) => {},
    unselectElement: (
      _,
      { payload }: PayloadAction<{ uuid: SceneNavigatorElement['uuid'] }>
    ) => {},
    recenterCamera: () => {},
    cameraReset: () => {},
    setCameraFromTo(state, action: PayloadAction<CameraFromTo>) {},
    setCameraRadius(state, action: PayloadAction<number>) {},
    setCameraPhi(state, action: PayloadAction<number>) {},
    zoomSelection(state, action: PayloadAction<number>) {},
    setCameraOrbitPhi(state, action: PayloadAction<number>) {},
    setCameraOrbitTheta(state, action: PayloadAction<number>) {},
    setCameraDirection(state, action: PayloadAction<Cartesian>) {},
    setCameraType(state, action: PayloadAction<number>) {},
    startVideoRecording(state, action: PayloadAction<void>) {},
    cancelVideoRecording(state, action: PayloadAction<void>) {},
    videoRecordingComplete(state, action: PayloadAction<void>) {},
    setCaptureStatus(
      state,
      { payload }: PayloadAction<SceneState['captureStatus']>
    ) {
      state.captureStatus = payload
    },
    togglePrimitiveVisibility(_, action: PayloadAction<{ uuid: string }>) {},

    setSelectedObjectUI(state, { payload }: PayloadAction<SelectedObjectUI>) {
      state.selectedObjectUI = payload
    },
    setSnapshots(state, { payload }: PayloadAction<SceneState['snapshots']>) {
      state.snapshots = payload
    },
    addSnapShot(_, { payload }: PayloadAction<Snapshot>) {},
    applySnapshot(_, { payload }: PayloadAction<{ index: number }>) {},
    overwriteSnapshot(_, { payload }: PayloadAction<{ index: number }>) {},
    renameSnapShot(_, { payload }: PayloadAction<Snapshot>) {},
    removeSnapshot(_, { payload }: PayloadAction<{ index: number }>) {},
    changeFont(_, { payload }: PayloadAction<{ fontId: string }>) {},
    setSelectedFontPostScriptName(
      state,
      { payload }: PayloadAction<SceneState['selectedFontPostScriptName']>
    ) {
      state.selectedFontPostScriptName = payload
    },
    importSVG: (_, { payload }: PayloadAction<string>) => {}
  }
})

export const {
  addPrimitive,
  copyMaterials,
  deletePrimitive,
  duplicatePrimitive,
  engineLoaded,
  loadDocument,
  loadEngine,
  selectElementParentChild,
  selectElementSibling,
  setEngineState,
  setPropertiesState,
  setPropertyState,
  undoRedo,
  pasteMaterials,
  transferMaterials,
  setPixelFilterEnabled,
  setPixelOutlineEnabled,
  setIllustrativeOutlineEnabled,
  setAutoFocusEnabled,
  exportCapture,
  selectElement,
  unselectElement,
  recenterCamera,
  cameraReset,
  setCameraFromTo,
  setCameraRadius,
  setCameraPhi,
  zoomSelection,
  setCameraType,
  setCameraOrbitPhi,
  setCameraOrbitTheta,
  setCameraDirection,
  setSelectedObjectUI,
  reorderStackElements,
  setCaptureStatus,
  togglePrimitiveVisibility,
  startVideoRecording,
  cancelVideoRecording,
  videoRecordingComplete,
  setSnapshots,
  addSnapShot,
  applySnapshot,
  renameSnapShot,
  overwriteSnapshot,
  removeSnapshot,
  changeFont,
  setSelectedFontPostScriptName,
  importSVG
} = scene.actions

export default scene.reducer
