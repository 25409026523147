import React from 'react'
import { Button, Dialog, DialogBase } from 'ui'
import { useProject } from '@hooks/useProject'
import { useTranslations } from 'use-intl'

const RefreshDialog = () => {
  const t = useTranslations()
  const { showRefreshDialog, setShowRefreshModal } = useProject()

  if (!showRefreshDialog) {
    return null
  }

  return (
    <DialogBase underlay open>
      <Dialog size="s">
        <h2 slot="heading">{t('studio:connection:disconnected')}</h2>
        <p>{t('studio:connection:disconnected:msg')}</p>
        <Button
          slot="button"
          onClick={() => setShowRefreshModal(false)}
          variant="primary">
          {t('studio:connection:disconnected:ok')}
        </Button>
      </Dialog>
    </DialogBase>
  )
}

export default RefreshDialog
