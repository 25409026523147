export const AppName = 'Project Neo'
export const LeiaProductId = 'Project Neo'
export const CommunityGuidelinesFilename = 'community-guidelines-2024-09-18.pdf'
export const RedirectFromQueryParam = 'redirect-from'
export const RedirectFromFirefly = 'firefly'
export const DefaultStudioEnvironment = 'default'
export const SceneToImageStudioEnvironment = 'scene-to-image'
export const StudioEnvironmentQueryParam = 'studio-environment'
export const SceneToImageStudioPathname = `/studio/scene-to-image`
export const FavIconUrl = '/favicon.ico'
export const SceneToImageFavIconUrl = '/firefly/module/logo/firefly-logo.svg'
export const MaxImportSVGFileSize = 102400 // This is in bytes - make sure this works with the stack size set in emscripten config