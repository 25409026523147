import React, { FC } from 'react'
import { Button, Icon } from 'ui'
import DiscordLogoIcon from '/public/discord-mark-blue.svg'
import { useTranslations } from 'use-intl'

interface props {
  disabled?: boolean
}

const JoinDiscordButton: FC<props> = ({ disabled }) => {
  const t = useTranslations()
  const iconStyle = {
    fill: disabled ? 'grey' : '#5865F2'
  }

  return (
    <Button
      variant="secondary"
      treatment="fill"
      href={t('link:discord')}
      target="_blank"
      disabled={disabled}
      style={{
        whiteSpace: 'nowrap'
      }}>
      <Icon slot="icon">
        <DiscordLogoIcon style={iconStyle} />
      </Icon>
      {t('link:discord:join')}
    </Button>
  )
}

export default JoinDiscordButton
