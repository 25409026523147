import React from 'react'
import { ActionMenu, Icon, MenuItem, OverlayTrigger, Tooltip } from 'ui'
import BetaAppIcon from '/public/s2_icon_beta_app.svg'
import CommentTextIcon from '/public/s2_icon_comment_text.svg'
import DiscordIcon from '/public/icon_discord.svg'
import { SpIconAnnotate } from 'workflow-icons'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import { useTranslations } from 'use-intl'
import { useFlags } from 'launchdarkly-react-client-sdk'

const Feedback = () => {
  const t = useTranslations()
  const flags = useFlags()
  const size = useMediumMinimumSizePreference()

  function onMenuClick({ target: { value } }: any) {
    switch (value) {
      case 'feedback-suggestions-and-feature-requests':
        window.open(t('studio:feedbackMenu:feedback:link'), '_blank')
        break
      case 'join-discord':
        window.open(t('studio:feedbackMenu:discord:link'), '_blank')
        break
      case 'release-notes':
        window.open(t('studio:feedbackMenu:releaseNotes:link'), '_blank')
        break
      default:
        return
    }
  }

  return (
    <OverlayTrigger placement="bottom" offset={10}>
      <ActionMenu
        size={size}
        slot="trigger"
        placement="bottom-end"
        quiet
        onchange={onMenuClick}>
        <Icon slot="icon">
          <BetaAppIcon />
        </Icon>
        <MenuItem value="feedback-suggestions-and-feature-requests">
          <Icon slot="icon">
            <CommentTextIcon />
          </Icon>
          {t('studio:feedbackMenu:feedback')}
        </MenuItem>
        {flags['base-tf-ui-discord-link'] && (
          <MenuItem value="join-discord">
            <Icon slot="icon">
              <DiscordIcon />
            </Icon>
            {t('studio:feedbackMenu:discord')}
          </MenuItem>
        )}
        <MenuItem value="release-notes">
          <SpIconAnnotate slot="icon" />
          {t('studio:feedbackMenu:releaseNotes')}
        </MenuItem>
      </ActionMenu>
      <Tooltip slot="hover-content">{t('studio:tooltips:feedback')}</Tooltip>
    </OverlayTrigger>
  )
}

export default Feedback
