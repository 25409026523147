import { useEffect } from 'react'

const EnvApplicationIDMap = {
  dev: '1835055551',
  prd: '1835056853',
  preview: '1835057083',
  stg: '1835056850'
} as const

const getConfig = (
  env: NonNullable<typeof process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT>
) => {
  const id = env === 'local' ? '' : EnvApplicationIDMap[env]

  return {
    init: {
      spa: {
        enabled: false,
        autoStart: false
      },
      soft_nav: {
        enabled: true
      },
      session_replay: {
        enabled: true,
        block_selector: '',
        mask_text_selector: '*',
        sampling_rate: 10.0,
        error_sampling_rate: 100.0,
        mask_all_inputs: true,
        collect_fonts: true,
        inline_images: false,
        inline_stylesheet: true,
        mask_input_options: {}
      },
      distributed_tracing: {
        enabled: true,
        exclude_newrelic_header: true
      },
      privacy: {
        cookies_enabled: true
      },
      ajax: {
        deny_list: ['bam.nr-data.net']
      }
    },
    loader_config: {
      accountID: '4380618',
      trustKey: '1322840',
      agentID: id,
      licenseKey: 'NRJS-86d874eb41de08db301',
      applicationID: id
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: 'NRJS-86d874eb41de08db301',
      applicationID: id,
      sa: 1
    }
  }
}

export const useInitNewRelic = () => {
  useEffect(() => {
    if (
      // Temporarily enabling in preview environment to test
      // process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT !== 'preview' &&
      window
    ) {
      initialize()
    }
  }, [])

  async function initialize() {
    if (!process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT) return

    const { BrowserAgent } = await import(
      '@newrelic/browser-agent/loaders/browser-agent'
    )

    const config = getConfig(process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT)

    new BrowserAgent(config)
  }
}
