import React, { useContext } from 'react'
import { LocaleContext } from './LocaleProvider'
import { MenuItem, Picker } from 'ui'
import { useTranslations } from 'use-intl'
import { LocaleString } from '@concerns/i18n/types'
import {
  LocaleStringDisplayNames,
  SupportedLocales
} from '@concerns/i18n/constants'

const LocaleSwitcher = () => {
  const { activeLocale, changeApplicationLocale } = useContext(LocaleContext)
  const t = useTranslations()

  const handleTabChange = (event: any) => {
    const selectedLocale: LocaleString = event.target?.value
    changeApplicationLocale(selectedLocale)
  }

  return (
    <Picker
      id="locale-switcher"
      size="m"
      label={LocaleStringDisplayNames[activeLocale]}
      value={activeLocale}
      onchange={handleTabChange}>
      {SupportedLocales.map((localeString: LocaleString) => (
        <MenuItem
          selected={localeString === activeLocale}
          key={localeString}
          value={localeString}>
          {LocaleStringDisplayNames[localeString]}
        </MenuItem>
      ))}
    </Picker>
  )
}
export default LocaleSwitcher
