import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TrackingEvent } from '@constants/analytics'

export interface MonitoringState {
  isAdobeAnalyticsInitialized: boolean
}

const initialState: MonitoringState = {
  isAdobeAnalyticsInitialized: false
}

export const monitoring = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    setAdobeAnalyticsInitialized: state => {
      state.isAdobeAnalyticsInitialized = true
    },
    sendEvent: (_, { payload }: PayloadAction<TrackingEvent>) => {}
  }
})

export const { setAdobeAnalyticsInitialized, sendEvent } = monitoring.actions

export default monitoring.reducer
