import { AdobeIMS as _AdobeIMS } from '@identity/imslib'
import { IEnvironment } from '@identity/imslib/adobe-id/IEnvironment'
import { IAdobeIdData } from '@identity/imslib/adobe-id/IAdobeIdData'
import { RootStore } from '@store/store'
import { dispatchAuthStrategy } from '@store/slices/authSlice'
import { ITokenInformation } from '@identity/imslib/adobe-id/custom-types/CustomTypes'
import {
  defaultAuthStrategy,
  sceneToImageAuthStrategy
} from '@store/middleware/auth/authSaga'
import {
  RedirectFromFirefly,
  RedirectFromQueryParam
} from '@constants/appConstants'

export const startIMSAuthentication = async () => {
  // This runs before any other authentication flow, which is necessary to make sure
  // that the user has a fresh access token (if previously authenticated and if necessary)
  if (doesAccessTokenExist()) await AdobeIMS.refreshToken()

  const didRedirectFromFirefly =
    new URLSearchParams(window.location.search).get(RedirectFromQueryParam) ===
    RedirectFromFirefly

  didRedirectFromFirefly
    ? RootStore.dispatch(dispatchAuthStrategy(sceneToImageAuthStrategy))
    : RootStore.dispatch(dispatchAuthStrategy(defaultAuthStrategy))
}

const SCOPE =
  'AdobeID,openid,pps.read,pps.search,firefly_api,account_cluster.read,read_organizations,tk_platform,tk_platform_sync'
const adobeIdData: IAdobeIdData = {
  client_id: process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID!,
  redirect_uri: process.env.NEXT_PUBLIC_ADOBE_IMS_REDIRECT_PATH,
  scope: SCOPE,
  locale: 'en_US',
  environment:
    process.env.NEXT_PUBLIC_ADOBE_IMS_ENV == 'prd'
      ? IEnvironment.PROD
      : IEnvironment.STAGE,
  useLocalStorage: false,
  autoValidateToken: true,
  onAccessToken: null,
  onReauthAccessToken: null,
  onError: startIMSAuthentication,
  onAccessTokenHasExpired: startIMSAuthentication,
  onReady: startIMSAuthentication,
  api_parameters: {
    authorize: {
      dctx_id: process.env.NEXT_PUBLIC_ADOBE_IMS_CONTEXT_ID
    }
  }
}

type AdobeIMSType = _AdobeIMS & {
  getAccessTokenAsync: () => Promise<ITokenInformation | null | void>
  isAccessTokenValid: () => boolean
}

const AdobeIMS: AdobeIMSType = Object.assign(
  new _AdobeIMS({ ...adobeIdData }),
  { getAccessTokenAsync, isAccessTokenValid }
)

// Will also attempt to refresh the access token if the  access token does not exist, because the IMS instance will
// delete the access token from session storage once it's expired. The existence of the ims_sid cookie acts as credentials
// to allow the user to receive the new access token. So if this method was called and there was no access token
// in session storage, the user would not receive an access token unless they had previously authenticated and thus received
// an ims_sid cookie.
async function refreshAccessTokenIfExpired(): Promise<void> {
  if (!isAccessTokenValid()) {
    try {
      await AdobeIMS.refreshToken()
    } catch (err) {
      startIMSAuthentication()
    }
  }
}

function doesAccessTokenExist(): boolean {
  return Boolean(AdobeIMS.getAccessToken())
}

function isAccessTokenValid(): boolean {
  const tokenInfo: ITokenInformation | null = AdobeIMS.getAccessToken()

  // Consider the access token expired if its expiration date is some time in the past or if
  // getAccessToken returns null
  return Boolean(
    tokenInfo &&
    tokenInfo.token &&
    tokenInfo.expire &&
    new Date() < new Date(tokenInfo.expire)
  )
}

// This function will get a new access token if the current one is expired, which is an asynchronous operation.
// If it is not expired, it will return the current access token.

// It differs from getAccessToken in that getAccessToken will trigger the onError callback defined in adobeIdData object.
// We've defined that callback to redirect the user to the login page.
async function getAccessTokenAsync(): Promise<ITokenInformation | null | void> {
  await refreshAccessTokenIfExpired()
  const token: ITokenInformation | null = AdobeIMS.getAccessToken()

  if (!token) {
    await startIMSAuthentication()
    return
  }

  return token
}

export default AdobeIMS
