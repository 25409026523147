import React, { createContext, useEffect, useState } from 'react'
import { IntlProvider } from 'use-intl'
import {
  LocaleContextValue,
  LocaleMessages,
  LocaleString
} from '@concerns/i18n/types'
import {
  getUserLocalePreference,
  importLocaleMessagesFromFile,
  persistUserLocalePreference,
  setHtmlLang
} from '@concerns/i18n/browser'
import {
  DefaultApplicationLocale,
  DefaultLocaleContextValue
} from '@concerns/i18n/constants'
import Context from '@store/middleware/context'

export const LocaleContext = createContext<LocaleContextValue>(
  DefaultLocaleContextValue
)

export const LocaleProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [activeLocale, setActiveLocale] = useState<LocaleString>(
    DefaultApplicationLocale
  )
  const [localeMessages, setLocaleMessages] = useState<
    LocaleMessages | undefined
  >(undefined)

  const changeApplicationLocale = async (
    userSelectedLocale: LocaleString
  ): Promise<void> => {
    persistUserLocalePreference(userSelectedLocale)
    await loadAndSetLocaleMessages(userSelectedLocale)
    if (window.UniversalNav) {
      await Context.UniversalNav.changeLocale(userSelectedLocale)
    }
  }

  const loadAndSetLocaleMessages = async (locale: LocaleString) => {
    try {
      setHtmlLang(locale)
      const json = await importLocaleMessagesFromFile(locale)
      setActiveLocale(locale)
      setLocaleMessages(json)
    } catch {
      console.warn(
        `Error loading locale messages for ${locale}. Falling back to en-us.`
      )
      setHtmlLang(DefaultApplicationLocale)
      const json = await importLocaleMessagesFromFile(DefaultApplicationLocale)
      setActiveLocale(DefaultApplicationLocale)
      setLocaleMessages(json)
    }
  }

  useEffect(() => {
    loadAndSetLocaleMessages(getUserLocalePreference())
  }, [])

  return !localeMessages || !activeLocale ? null : (
    <LocaleContext.Provider
      value={{ activeLocale, localeMessages, changeApplicationLocale }}>
      <IntlProvider
        messages={localeMessages}
        locale={activeLocale}
        timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        now={new Date()}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  )
}
