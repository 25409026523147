import React, { FC } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Icon, ActionMenu, MenuItem, MenuDivider, Switch, Theme } from 'ui'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { EngineSelectedSceneNode } from '@services/engine/types'
import ViewPortIcon from '/public/s2_icon_viewport.svg'
import { useCamera } from '@services/engine/useCamera'
import { ClassNameProps } from 'types/reactProps'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import { useTranslations } from 'use-intl'
import { getCmdModifier } from '@services/engine/utils'

const CameraFrameMenu: FC<ClassNameProps> = ({ className }) => {
  const t = useTranslations()
  const flags = useFlags()

  const size = useMediumMinimumSizePreference()

  const canvasAnimationStartedForRecording = useSceneState(
    'canvasAnimationStartedForRecording'
  )

  const selectedSceneNode = useSceneState('selectedSceneNode')

  const { recenterCamera } = useSceneActions()

  const { frameAll, frameSelection, autoFocusEnabled, cameraOrbitSnapping } =
    useCamera()
  const [isAutoFocusEnabled, setAutoFocusEnabled] = autoFocusEnabled()
  const autoFocusCurrentlyEnabled = isAutoFocusEnabled() ?? false

  const isFrameObjectMenuDisabled =
    selectedSceneNode === EngineSelectedSceneNode.PROJECT ||
    selectedSceneNode === EngineSelectedSceneNode.NONE ||
    canvasAnimationStartedForRecording

  if (canvasAnimationStartedForRecording) return null

  const modifier = getCmdModifier()

  return (
    <Theme color="light">
      <ActionMenu size={size} quiet className={className} placement="bottom">
        <Icon slot="icon">
          <ViewPortIcon />
        </Icon>
        <MenuItem onClick={frameAll}>
          {t('scene:camera:frameScene')}
          <kbd slot="value">
            {t(`scene:camera:frameSceneKeybind:${modifier}`)}
          </kbd>
        </MenuItem>

        <MenuItem onClick={frameSelection} disabled={isFrameObjectMenuDisabled}>
          {t('scene:camera:frameSelection')}
          <kbd slot="value">
            {t(`scene:camera:frameSelectionKeybind:${modifier}`)}
          </kbd>
        </MenuItem>

        {flags['base-tf-ui-camera-recenter'] && (
          <MenuItem onClick={() => recenterCamera()}>
            {t('scene:camera:recenter')}
          </MenuItem>
        )}
        <MenuDivider size="s" />
        {flags['base-tf-fx-camera-autofocus'] && (
          <MenuItem
            className="menu-item-with-input-value"
            onClick={() => setAutoFocusEnabled(!autoFocusCurrentlyEnabled)}>
            {t('scene:camera:autoFrame')}
            <Switch
              slot="value"
              size={size}
              style={{ pointerEvents: 'none' }} // Allow menu item to control click behavior when toggling switch
              checked={autoFocusCurrentlyEnabled}
            />
          </MenuItem>
        )}
      </ActionMenu>
    </Theme>
  )
}

export default CameraFrameMenu
